<template>
    <div
        class="flex flex-col sm:flex-row sm:items-center justify-between border-b border-gray-100 last:border-b-0 w-full"
    >
        <div class="flex flex-col sm:flex-row items-center">
            <div class="p-6 w-full sm:w-128">
                <h2 class="font-medium leading-6 truncate">
                    {{ assignment.name }}
                </h2>
                <div class="text-sm leading-6">
                    Assigned by:
                    <span
                        v-text="
                            $options.methods.optional(
                                assignment.blueprint.owner,
                                'name'
                            )
                        "
                    />
                </div>
            </div>
        </div>
        <div class="flex flex-col sm:flex-row sm:items-center">
            <div class="flex sm:justify-start pl-6 w-full sm:w-42">
                <div
                    class="text-sm uppercase leading-6 font-semibold sm:hidden"
                >
                    Due Date:
                </div>
                <div
                    class="text-sm leading-6"
                    v-text="
                        $filters.shortDateTime(
                            assignment.end_date,
                            user.timezone
                        )
                    "
                />
            </div>
            <div
                class="flex sm:justify-center px-6 sm:py-6 space-x-2 w-full sm:w-20"
            >
                <div
                    class="text-sm uppercase leading-6 font-semibold sm:hidden"
                >
                    Questions:
                </div>
                <div
                    class="text-sm leading-6"
                    v-text="
                        $options.methods.optional(
                            assignment.blueprint,
                            'questions_total'
                        )
                    "
                />
            </div>
            <div
                class="flex sm:justify-center px-6 sm:py-6 space-x-2 w-full sm:w-36"
            >
                <div
                    class="text-sm uppercase leading-6 font-semibold sm:hidden"
                >
                    Timer:
                </div>
                <div
                    class="text-sm leading-6"
                    v-text="assignment.blueprint.time_total"
                />
            </div>
            <div
                class="flex sm:items-center sm:justify-end p-6 sm:pl-0 space-x-6 w-full sm:w-48"
            >
                <form action="/prep/exams" method="post">
                    <input type="hidden" name="_token" :value="token" />
                    <input type="hidden" name="type" value="assigned" />
                    <input
                        type="hidden"
                        name="assignment_id"
                        :value="assignment.uuid"
                    />
                    <button
                        class="bg-gradient-to-tr from-indigo-100 to-pink-100 hover:from-pink-100 hover:to-indigo-100 hover:bg-transparent border-none hover:border-none small button"
                    >
                        Take Assignment
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import axios from "axios";
import { mapState } from "vuex";

export default {
    props: {
        assignment: {
            type: Object,
            default: () => {},
        },
    },
    data(): any {
        return {
            assigneeModalVisible: false,
            deleteModalVisible: false,
            formStatus: "ready",
            published: false,
        };
    },
    computed: {
        ...mapState(["token", "user"]),
        assigneeLabel() {
            return this.assignment.assignables.length === 1
                ? "assignee"
                : "assignees";
        },
        canDelete() {
            return this.assignment.owner == "learner";
        },
    },
    methods: {
        archive() {
            axios
                .post(
                    "/api/prep/assignments/" + this.assignment.uuid + "/archive"
                )
                .then(() => window.location.reload());
        },
        confirmDelete() {
            axios
                .delete("/prep/exams/blueprints/" + this.assignment.uuid)
                .then((response) => {
                    window.location.reload();
                });
        },
        restore() {
            axios
                .post(
                    "/api/prep/assignments/" + this.assignment.uuid + "/restore"
                )
                .then(() => window.location.reload());
        },
        showDelete() {
            this.deleteModalVisible = true;
        },
        togglePublished() {
            //
        },
    },
    mounted() {
        this.published = this.assignment.assignment_published == "1";
    },
    watch: {
        published() {
            this.togglePublished();
        },
    },
};
</script>
