<template>
    <div>
        <div v-if="(exams?.length ?? 0) > 0" class="flex flex-col">
            <div class="flex items-center justify-end text-center px-4 w-full">
                <div class="flex items-center justify-center w-42">
                    <div class="text-sm uppercase font-medium text-gray-600">
                        Categories
                    </div>
                </div>
                <div class="flex items-center justify-center w-24">
                    <div class="text-sm uppercase font-medium text-gray-600">
                        <SvgIcon icon="question" size="sm" weight="medium" />
                    </div>
                </div>
                <template v-if="complete">
                    <div class="flex items-center justify-center w-24">
                        <div
                            class="text-sm uppercase font-medium text-gray-600"
                        >
                            Score
                        </div>
                    </div>
                    <div class="flex items-center justify-center w-32">
                        <div
                            class="text-sm uppercase font-medium text-gray-600"
                        >
                            Time
                        </div>
                    </div>
                </template>
                <template v-if="!complete">
                    <div class="flex items-center justify-center w-48">
                        <div
                            class="text-sm uppercase font-medium text-gray-600"
                        >
                            Progress
                        </div>
                    </div>
                    <div class="w-16">&nbsp;</div>
                </template>
            </div>
            <div class="rounded-md shadow mt-4 w-full">
                <PrepExamCollectionResource
                    v-for="exam in exams"
                    :exam="exam"
                    :key="exam.uuid"
                />
            </div>
        </div>
        <placeholder v-else>
            <div class="text-gray-500 text-3xl font-light mt-8">
                No exams found
            </div>
        </placeholder>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        complete: Boolean,
        exams: Array as PropType<any[]>,
    },
});
</script>
