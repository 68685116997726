<template>
    <BillingSection title="Current Subscription">
        <div>
            <div v-if="isFloodGate">
                <CurrentSubscriptionWidget :subscriptions="subscriptions">
                </CurrentSubscriptionWidget>
            </div>
            <div v-if="!isFloodGate" v-for="sub in subscriptions">
                <div class="flex flex-row justify-between">
                    <div class="flex flex-col space-y-1">
                        <div
                            v-if="sub.cancel_at_period_end"
                            class="flex justify-start"
                        >
                            <Pill>Canceled</Pill>
                        </div>
                        <div
                            v-if="sub.status !== 'active'"
                            class="flex justify-start"
                        >
                            <Pill
                                background-color="red-100"
                                text-color="red-700"
                                >Payment Failed
                            </Pill>
                        </div>
                        <div class="font-bold text-lg">
                            {{ sub.title }}
                        </div>
                        <div class="flex-grow my-1"></div>
                        <div class="text-lg">
                            ${{ formatPrice(sub.price) }} per month
                        </div>
                        <div
                            class="text-small tracking-tight"
                            v-if="!sub.cancel_at_period_end"
                        >
                            Your plan renews on
                            {{ formatDate(sub.current_period_end) }}
                        </div>
                        <div
                            class="text-small tracking-tight"
                            v-if="sub.cancel_at_period_end"
                        >
                            Your plan will be canceled on
                            {{ formatDate(sub.current_period_end) }}
                        </div>
                        <div
                            class="flex flex-row"
                            v-if="sub.status !== 'active'"
                        >
                            <div class="card-desc card-details text-gray-400">
                                {{
                                    capitalizeFirstLetter(
                                        sub.latest_invoice.charge
                                            .payment_method_details.card.brand
                                    )
                                }}
                                ••••
                                {{
                                    sub.latest_invoice.charge
                                        .payment_method_details.card.last4
                                }}
                            </div>
                        </div>
                        <div
                            class="text-small tracking-tight text-red-700"
                            v-if="sub.status !== 'active'"
                        >
                            Your latest payment has failed. Update your payment
                            method to continue this plan.
                        </div>
                    </div>
                    <div class="flex flex-col space-y-2 text-center">
                        <a
                            class="bg-yellow-400 rounded text-black p-4 m-4"
                            href="/account/checkout/upgrade-plan"
                        >
                            Upgrade
                        </a>
                        <a
                            class="button main"
                            v-if="sub.status !== 'active'"
                            :href="
                                '/account/billing/update-subscription-payment/' +
                                sub.stripe_subscription_id
                            "
                        >
                            Update Payment Method
                        </a>
                        <a
                            :href="`/account/billing/subscription/${sub.stripe_subscription_id}/cancel`"
                            v-if="!sub.cancel_at_period_end"
                        >
                            Cancel Subscription
                        </a>
                        <a
                            class="button button-primary"
                            :href="`/account/billing/subscription/${sub.stripe_subscription_id}/resume`"
                            v-if="sub.cancel_at_period_end"
                        >
                            Resume Subscription
                        </a>
                    </div>
                </div>
            </div>
            <div v-if="subscriptions.length === 0 && initComplete">
                You do not have any active subscriptions. To purchase
                subscriptions visit our
                <a
                    href="https://cloverlearning.com/products"
                    class="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                    >Product Page</a
                >.
            </div>
        </div>
    </BillingSection>
</template>

<script lang="ts" setup>
import { getSubscriptions } from "@/api/billing";
import BillingSection from "./BillingSection.vue";
import { onMounted, ref, watch } from "vue";
import { useUserStore } from "@/stores/userStore";
import CurrentSubscriptionWidget from "../CurrentSubscriptionWidget.vue";
import {
    capitalizeFirstLetter,
    formatDate as _formatDate,
    formatPrice,
} from "../../../utils";
import { storeToRefs } from "pinia";

const props = defineProps<{
    customer: any;
}>();

const userStore = useUserStore();
const { subscriptions } = storeToRefs(userStore);
const initComplete = ref(false);

const isFloodGate = ref(
    ((import.meta as any).env.VITE_FEATURE_FLOODGATE as string) === "true"
);

const formatDate = (date: any) => {
    return _formatDate(date, userStore.user.user.timezone);
};

async function _getSubscriptions() {
    subscriptions.value = await getSubscriptions(userStore.user.user.uuid);
}

onMounted(async () => {
    await userStore.getUsersSubscriptions();
    initComplete.value = true;
});

watch(userStore.user, async (val, old) => {
    if (!val) return;
    await _getSubscriptions();
    initComplete.value = true;
});
</script>
<style scoped>
.card-desc {
    flex-basis: 280px;
}

.card-details {
    display: flex;
    align-items: center;
}
</style>
