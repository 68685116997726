<template>
    <div>
        <div class="flex justify-end mb-6">
            <select-dropdown v-model="statusFilter" name="statusFilter">
                <option v-for="s in courseStatuses" :value="s">
                    {{ s }}
                </option>
            </select-dropdown>
        </div>
        <div class="rounded-md shadow divide-y">
            <div
                class="flex items-center bg-gray-50 border-b-2 border-gray-200 text-xs text-gray-600 uppercase font-semibold leading-none rounded-t-md pt-6 pb-5"
            >
                <div class="flex items-center justify-between px-6 w-1/2">
                    Name
                </div>
                <div class="flex items-center justify-between px-6 w-1/2">
                    Slug
                </div>
            </div>
            <div>
                <div
                    v-for="c in courses"
                    class="flex items-center bg-white text-sm even:bg-gray-50 last:rounded-b-md"
                >
                    <div class="p-6 w-1/2">
                        <router-link
                            class="cta"
                            :title="c.title"
                            :to="{ name: 'course', params: { uuid: c.uuid } }"
                        >
                            {{ c.title }}
                        </router-link>
                    </div>
                    <div class="font-mono p-6 w-1/2">
                        {{ c.slug }}
                    </div>
                </div>
            </div>
            <Pagination
                :model-value="pagination.currentPage"
                :total="pagination.total"
                :perPage="pagination.perPage"
                @update:modelValue="getCourses"
            />
        </div>
    </div>
</template>
<script lang="ts">
import axios from "axios";
import Pagination from "../../../_elements/Pagination.vue";
import { mapMutations } from "vuex";
import { defineComponent } from "vue";

export default defineComponent({
    components: { Pagination },
    data(): any {
        return {
            courses: [],
            path: window.location.pathname + "/",
            pagination: {
                perPage: 1,
                total: 0,
                currentPage: 1,
            },
            courseStatuses: ["Published", "Archived", "Draft"],
            statusFilter: "Published",
        };
    },
    methods: {
        getCourses(pageNumber) {
            axios
                .post(`/api/admin/content/learn/courses`, {
                    page: pageNumber,
                    status: this.statusFilter,
                })
                .then((response) => {
                    this.courses = response.data.data;
                    this.pagination.perPage = response.data.per_page;
                    this.pagination.total = response.data.total;
                    this.pagination.currentPage = response.data.current_page;
                });
        },
        ...mapMutations(["hideBladePage"]),
    },
    watch: {
        statusFilter() {
            this.getCourses(this.pagination.currentPage);
        },
    },
    mounted() {
        this.hideBladePage();
        this.getCourses(1);
    },
});
</script>
