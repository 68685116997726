<template>
    <div>
        <FullPageLoadingIndicator />
        <Modal />
        <Toaster />
        <SiteRibbon />
        <SidebarUser v-if="!admin" :theme="theme" />
        <SidebarAdmin v-if="admin" />
        <BladeContent :show-if-blade="true">
            <TitleBar
                :action="action"
                :actionPath="actionPath"
                :app="app"
                :icon="icon"
                :moduleTitle="moduleTitle"
                :resourceTitle="resourceTitle"
                :searchable="searchable"
                :searchAddition="searchAddition"
                :showGlobalSearch="showGlobalSearch"
                :searchClear="searchClear"
                :section="section"
                :sectionPath="sectionPath"
                :showBilling="showBilling"
                :impersonating="impersonating"
            />
        </BladeContent>
        <BladeContent :show-if-blade="false">
            <router-view name="titleBar"></router-view>
        </BladeContent>

        <section class="flex flex-col content pt-17 sm:pt-20 sm:pl-24 h-screen">
            <slot name="banner" />
            <div
                class="flex-grow py-4 sm:py-8"
                :class="{
                    'px-8': wide,
                    container: !wide,
                }"
            >
                <PageSearch
                    v-if="searchable?.length && pageSearch"
                    class="sm:hidden pt-2 border-b border-gray-200 mb-6 pb-6"
                    v-bind="{
                        app,
                    }"
                />
                <PageTitle
                    v-if="app !== 'Home'"
                    class="flex justify-center sm:hidden border-b border-gray-200 mb-6 pb-6"
                    v-bind="{
                        action,
                        actionPath,
                        app,
                        icon,
                        section,
                        sectionPath,
                    }"
                />
                <main id="main">
                    <slot />
                </main>
            </div>
            <slot name="footer" />
        </section>
    </div>
    <!-- <WelcomeModal /> -->
    <!-- <SuccessModal /> -->
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BladeContent from "../BladeContent.vue";
import { mapState } from "vuex";
import SkipToContent from "../_elements/SkipToContent.vue";
import Modal from "../_elements/modals/Modal.vue";
import Toaster from "../_elements/Toaster.vue";
import SiteRibbon from "../_elements/SiteRibbon.vue";
import SidebarUser from "./SidebarUser.vue";
import SidebarAdmin from "./SidebarAdmin.vue";
import TitleBar from "./TitleBar.vue";
import PageSearch from "../_elements/PageSearch.vue";
import PageTitle from "../_elements/PageTitle.vue";
import FullPageLoadingIndicator from "@/components/ui/loaders/FullPageLoadingIndicator.vue";
import WelcomeModal from "../Dashboard/WelcomeModal.vue";
import StyledModal from "../_elements/modals/StyledModal.vue";
import SuccessModal from "../Dashboard/SuccessModal.vue";
import FloodgateModal from "../Dashboard/FloodgateModal.vue";

export default defineComponent({
    components: {
        FullPageLoadingIndicator,
        PageTitle,
        PageSearch,
        TitleBar,
        SidebarAdmin,
        SidebarUser,
        SiteRibbon,
        Toaster,
        BladeContent,
        SkipToContent,
        Modal,
        WelcomeModal,
        FloodgateModal,
        StyledModal,
        SuccessModal,
    },
    props: {
        action: String,
        actionPath: String,
        active: String,
        admin: Boolean,
        app: String,
        icon: String,
        learnerMode: Boolean,
        moduleTitle: String,
        resourceTitle: String,
        searchable: Array,
        searchAddition: String,
        searchClear: Boolean,
        section: String,
        sectionPath: String,
        showGlobalSearch: {
            type: Boolean,
            default: false,
        },
        showBilling: Boolean,
        theme: String,
        user: Object as PropType<any>,
        permissions: Object,
        settings: Object,
        websitePath: String,
        wide: Boolean,
        impersonating: Boolean,
        features: Object,
    },
    computed: {
        ...mapState(["pageSearch"]),
    },
    created() {
        this.user.permissions = this.permissions;
        this.user.settings = this.settings;
        this.user.learnerMode = this.learnerMode;
        // @ts-ignore
        this.$store.commit("registerState", {
            activePath: this.active,
            token: (document.querySelector('meta[name="token"]') as any)
                .content,
            user: this.user,
            websitePath: this.websitePath,
        });
    },
});
</script>
