import axios from "axios";
import { createUrlParams } from "../utils";

export function getUserGroup() {
    return axios.get(`/api/groups?extend=learners,tree`).then((response) => {
        return response.data;
    });
}

export function getUsersGroups(partner, group) {
    let urlParams = createUrlParams({ partner, group });
    return axios.get(`/api/groups`, { params: urlParams }).then((response) => {
        return response.data;
    });
}

export function getGroupLearners(uuid) {
    return axios.get(`/api/groups/${uuid}/learners`).then((response) => {
        return response.data;
    });
}
