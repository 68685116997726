<script setup lang="ts">
import {
    provide,
    defineProps,
    defineEmits,
    onMounted,
    ref,
    withDefaults,
    reactive,
    onUnmounted,
} from "vue";
import {
    QuestionContext,
    QuestionContextApi,
    QuestionOption,
} from "./QuestionContext";

const selected = ref<string[]>([]);

const props = withDefaults(
    defineProps<{
        modelValue: string[];
        multiSelect?: boolean;
    }>(),
    {
        multiSelect: false,
    }
);

const emits = defineEmits<{
    (e: "update:modelValue", value: string | string[]): void;
}>();

const options: QuestionOption[] = [];

const api: QuestionContextApi = {
    registerOption(option: QuestionOption) {
        options.push(option);
    },
    unregisterOption(id: string) {
        const index = options.findIndex((option) => option.id === id);
        if (index !== -1) {
            options.splice(index, 1);
        }
    },
    unSelectAll() {
        options.forEach((option) => {
            option.selected.value = false;
        });
    },

    toggleById(id: string) {
        if (!props.multiSelect) {
            this.unSelectAll();
        }
        const option = options.find((option) => option.id === id);
        if (option) {
            option.selected.value = !option.selected.value;
        }
        // get selected options
        let selectedValues = options
            .filter((option) => option.selected.value)
            .map((option) => option.value);
        emits("update:modelValue", selectedValues ?? []);
    },
};

provide(QuestionContext, api);

onMounted(() => {
    if (Array.isArray(props.modelValue)) {
        selected.value = props.modelValue;
    } else {
        selected.value = [props.modelValue];
    }
});
</script>
<template>
    <slot></slot>
</template>
