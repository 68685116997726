<template>
    <div class="bg-white shadow rounded relative z-0" v-if="course">
        <div class="flex items-center justify-between border-b p-6 h-[5.5rem]">
            <h1 class="text-2xl font-semibold leading-none">
                {{ course.title }}
            </h1>
            <h1 class="text-2xl font-semibold leading-none">
                {{ course.slug }}
            </h1>
        </div>
        <div class="flex flex-row p-2 justify-between items-center">
            <!-- tailwind button-->
            <div class="space-x-4">
                <router-link
                    :to="`/content/learn/courses/${uuid}/edit`"
                    class="bg-blue-500 p-1 text-white rounded"
                >
                    Edit Course
                </router-link>
                <router-link
                    :to="`/content/learn/courses/${uuid}/add-module`"
                    class="bg-blue-500 p-1 text-white rounded"
                >
                    Add module
                </router-link>
            </div>
            <div class="flex flex-row items-center space-x-2">
                <div class="text-xs flex flex-row items-center">
                    Id: {{ course.id }} <CopyAction :value="course.id" />
                </div>
                <div class="text-xs flex flex-row items-center">
                    Uuid: {{ course.uuid }} <CopyAction :value="course.uuid" />
                </div>
            </div>
        </div>
        <div class="rounded-md shadow divide-y" v-if="modules.length > 0">
            <div
                class="flex items-center bg-gray-50 border-b-2 border-gray-200 text-xs text-gray-600 uppercase font-semibold leading-none rounded-t-md pt-6 pb-5"
            >
                <div class="flex items-center justify-between px-6 w-1/2">
                    Module Name
                </div>
                <div class="flex items-center justify-between px-6 w-1/2">
                    Slug
                </div>
            </div>
            <div>
                <div
                    v-for="m in modules"
                    class="flex items-center bg-white text-sm even:bg-gray-50 last:rounded-b-md"
                >
                    <div class="p-6 w-1/2">
                        <router-link
                            class="cta"
                            :title="m.title"
                            :to="{
                                name: 'module_lessons',
                                params: { uuid: m.uuid },
                            }"
                        >
                            {{ m.title }}
                        </router-link>
                    </div>
                    <div class="font-mono p-6 w-1/2">
                        {{ m.slug }}
                    </div>
                </div>
            </div>
            <Pagination
                :model-value="pagination.currentPage"
                :total="pagination.total"
                :perPage="pagination.perPage"
                @update:modelValue="updateModulePage"
            />
        </div>
        <div
            v-else
            class="flex items-center bg-white text-sm even:bg-gray-50 last:rounded-b-md p-6 justify-center"
        >
            This course has 0 modules
        </div>
    </div>
</template>
<script lang="ts">
import axios from "axios";
import Pagination from "../../../_elements/Pagination.vue";
import { defineComponent } from "vue";
import CopyAction from "../../../_elements/CopyAction.vue";

export default defineComponent({
    components: { CopyAction, Pagination },
    data(): any {
        return {
            course: null,
            modules: [],
            path: window.location.pathname + "/",
            pagination: {
                perPage: 10,
                total: 0,
                currentPage: 1,
            },
        };
    },
    computed: {
        uuid(): string {
            return this.$route.params.uuid;
        },
    },
    methods: {
        getCourse(uuid) {
            console.log("get courses");
            axios
                .get(`/api/admin/content/learn/courses/${uuid}`)
                .then((response) => {
                    this.course = response.data;
                    this.pagination.total = this.course.modules.length;
                    this.updateModulePage(1);
                });
        },
        updateModulePage(pageNumber) {
            this.modules = this.course.modules.slice(
                (pageNumber - 1) * this.pagination.perPage,
                pageNumber * this.pagination.perPage
            );
        },
    },
    mounted() {
        this.getCourse(this.uuid);
    },
});
</script>
