<template>
    <div
        class="py-10 md:py-20 px-8 w-full flex flex-col justify-center items-center bg-gray-200 rounded"
    >
        <div class="text-center prose">
            <div
                class="bg-white h-26 w-26 rounded-full flex items-center justify-center mx-auto"
            >
                <LockClosedIcon class="h-full w-auto text-slate-900 p-6" />
            </div>
            <h3 class="text-2xl md:text-3xl font-bold mb-4 mt-6">
                Course Assessment Locked
            </h3>
            <p class="mb-6">
                {{ descriptionText }}
            </p>
            <Link :href="href" intent="cta" :round="false">{{ ctaText }}</Link>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { LockClosedIcon } from "@heroicons/vue/20/solid";
import Link from "@/components/ui/ButtonLinks/Link.vue";
import { useUserStore } from "@/stores/userStore";
import { storeToRefs } from "pinia";

const userStore = useUserStore();
const { userObj } = storeToRefs(userStore);

const props = defineProps<{
    hasAccess: boolean;
    inTrial: boolean;
    plan: string;
}>();

const href = computed(() => {
    if (props.inTrial) {
        return "/account/checkout/payment-details?frequency=3m&plan=professional";
    }
    return `/account/checkout/upgrade-plan?frequency=${userObj.value?.subscription_frequency}`;
});

const ctaText = computed(() => {
    if (props.inTrial) {
        return "Purchase Professional Plan to Unlock";
    }
    return "Upgrade My Plan to Professional";
});

const descriptionText = computed(() => {
    if (props.inTrial) {
        return "Purchase to the Professional Plan to access the assessment and earn Continuing Education Credits!";
    }
    return "Upgrade to the Professional Plan to access the assessment and earn Continuing Education Credits!";
});
</script>
