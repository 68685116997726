<template>
    <div class="select inline-flex items-center relative" :class="[size]">
        <div v-if="variant === 'status'" class="flex text-gray-600 variant">
            <div class="status" :class="selected"></div>
        </div>
        <div v-if="variant === 'access'" class="flex text-gray-600 variant">
            <SvgIcon
                icon="unlock"
                size="sm"
                weight="medium"
                v-if="selected === 'open'"
            />
            <SvgIcon
                icon="lock"
                size="sm"
                weight="medium"
                v-if="selected === 'reserved'"
            />
        </div>
        <select
            v-model="selected"
            :required="required"
            :name="name"
            class="form-select block appearance-none w-full text-gray-800 leading-5 h-12 focus-visible:ring focus-visible:ring-blue-300 focus-visible:ring-opacity-50 active:ring active:ring-blue-300"
            :class="[
                themeClasses[theme],
                {
                    'p-2 pr-7': size === 'sm',
                    'p-3 pr-9': size === 'md',
                    'p-4 pr-12': size === 'lg',
                    'pl-9': variant === 'status',
                    'pl-11': variant === 'access',
                },
            ]"
        >
            <slot />
        </select>
        <div
            class="pointer-events-none absolute top-0 right-0 bottom-0 flex items-center text-gray-800"
            :class="{
                'p-2': size === 'sm',
                'p-3': size === 'md',
                'p-4': size === 'lg',
            }"
        >
            <SvgIcon icon="dropdown" size="xs" weight="extra-bold" />
        </div>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        name: {
            type: String,
            default: null,
        },
        required: {
            type: Boolean,
            default: true,
        },
        theme: {
            type: String,
            default: "float",
        },
        size: {
            type: String,
            default: "md",
        },
        modelValue: {
            type: [String, Number],
            default: "",
            required: true,
        },
        variant: {
            type: String,
            default: "",
        },
    },
    data(): any {
        return {
            selected: "",
            themeClasses: {
                blank: "bg-transparent border-none",
                flat: "bg-white border border-gray-200 rounded-lg",
                float: "bg-white rounded-lg shadow",
                muted: "bg-gray-50 border border-gray-100 rounded-lg",
            },
        };
    },
    mounted() {
        this.selected = this.modelValue;
    },
    watch: {
        selected(value) {
            this.$emit("update:modelValue", value);
        },
        modelValue(value) {
            this.selected = value;
        },
    },
};
</script>

<style scoped>
select {
    background-image: none;
}

.variant {
    @apply pointer-events-none absolute top-0 left-0 bottom-0 flex items-center px-4;
}
</style>
