import { provide, InjectionKey, inject, Ref, UnwrapRef } from "vue";

export type QuestionOption = {
    id: string;
    value: string;
    selected: Ref<boolean>;
    active: Ref<boolean>;
    element: Ref<HTMLElement | null>;
};

export type QuestionContextApi = {
    registerOption(option: QuestionOption);
    unSelectAll();
    toggleById(id: string);
    unregisterOption(id: string);
};

export const QuestionContext = Symbol(
    "QuestionContext"
) as InjectionKey<QuestionContextApi>;

export function useQuestionContext(component: string) {
    let context = inject(QuestionContext, null);

    if (context === null) {
        let err = new Error(
            `<${component} /> is missing a parent <RadioGroup /> component.`
        );
        if (Error.captureStackTrace)
            Error.captureStackTrace(err, useQuestionContext);
        throw err;
    }

    return context;
}
