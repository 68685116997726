<template>
    <div
        class="fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center z-top"
        v-show="active"
    >
        <transition
            enter-active-class="transition ease-out duration-200"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition ease-in duration-200"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div class="fixed inset-0 transition-opacity" v-if="active">
                <div class="absolute inset-0 bg-gray-900 opacity-75"></div>
            </div>
        </transition>
        <transition
            enter-active-class="transition ease-out duration-200"
            enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to-class="opacity-100 translate-y-0 sm:scale-100"
            leave-active-class="transition ease-in duration-200"
            leave-class="opacity-100 translate-y-0 sm:scale-100"
            leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
            <div
                class="bg-white rounded px-4 pt-5 pb-4 overflow-hidden shadow-md transform transition-all sm:max-w-lg sm:w-full sm:p-6"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
                v-if="active"
                v-click-away="close"
            >
                <div class="sm:flex sm:items-start">
                    <div
                        class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
                    >
                        <SvgIcon class="text-red-600" icon="warning" />
                    </div>
                    <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3
                            class="text-lg leading-6 font-medium text-gray-900"
                            id="modal-headline"
                        >
                            Delete Confirmation
                        </h3>
                        <p class="text-sm leading-5 text-gray-500">
                            {{ message }}
                        </p>
                    </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
                    <span class="flex w-full sm:w-auto">
                        <button
                            type="button"
                            class="inline-flex justify-center w-full rounded-lg border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus-visible:ring focus-visible:ring-red-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                            @click="confirm"
                        >
                            Delete
                        </button>
                    </span>
                    <span
                        class="mt-3 flex w-full rounded sm:mt-0 sm:ml-3 sm:w-auto"
                    >
                        <button
                            type="button"
                            class="inline-flex justify-center w-full rounded-lg border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus-visible:ring transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                            @click="close"
                        >
                            Cancel
                        </button>
                    </span>
                </div>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
import SvgIcon from "../SvgIcon.vue";

export default {
    components: { SvgIcon },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        message: {
            type: String,
            default: "Are you sure you want to delete this item?",
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
        confirm() {
            this.$emit("confirm");
        },
    },
};
</script>
