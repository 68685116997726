<script setup lang="ts">
import StyledModal from "@/components/_elements/modals/StyledModal.vue";
import CloverFullLogo from "../ui/images/CloverFullLogo.vue";
import { DialogTitle } from "@headlessui/vue";

const localKey = "clover.floodgate-modal";
// show if not set
const show = localStorage.getItem(localKey) !== "false";

const onClose = () => {
    localStorage.setItem(localKey, "false");
};
</script>

<template>
    <StyledModal :start-open="show" @on-close="onClose">
        <template v-slot:panel="slotProps">
            <CloverFullLogo />
            <div class="text-left">
                <p class="text-base text-gray-700">
                    With continuous improvement and innovation at the core of
                    who we are, we are thrilled to announce a significant update
                    to our current product lines, RadTechBootCamp and
                    CTtechBootCamp. This update is intended to transform how we
                    sell and distribute our products and services to our diverse
                    and growing customer base.
                </p>
                <div class="bg-blue-100 text-gray-900 px-4 py-1">
                    <p class="text-base">
                        <strong class="text-inherit"
                            >Beginning May 9th, 2023</strong
                        >, we'll be replacing the RadTechBootCamp and
                        CTtechBootCamp products with two new, comprehensive
                        plans: <em>Student and Professional</em>.
                    </p>
                </div>
                <p class="text-base text-gray-700">
                    For those with active RadTechBootCamp or CTtechBootCamp
                    access, beginning May 9th, 2023, you will automatically
                    receive full access to all available platform courses and
                    exam prep question banks at no extra cost and without any
                    changes to your subscriptions, year access, or access code
                    length.
                </p>
                <div class="flex mt-8 gap-2 items-center justify-end">
                    <button
                        class="button rounded-md bg-gray-100 shadow-none border-none hover:bg-gray-200"
                        @click="slotProps.events?.closeModal()"
                    >
                        Close
                    </button>
                    <a
                        href="https://help.cloverlearning.com/hc/en-us/articles/15081225813403-Student-and-Professional-Plans-Launch-Article"
                        class="button no-underline bg-blue-700 text-white rounded-md border-none hover:bg-blue-800 hover:text-white"
                        target="_blank"
                        >Learn More</a
                    >
                </div>
            </div>
        </template>
    </StyledModal>
</template>
