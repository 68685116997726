<template>
    <div
        class="flex items-center justify-between border-b border-gray-100 last:border-b-0 first:rounded-t-md last:rounded-b-md w-full"
        :class="{
            'bg-yellow-100': highlighted,
        }"
    >
        <div class="flex items-center">
            <div class="p-6 w-96">
                <h2 class="font-medium leading-6 truncate">
                    {{ exam.name }}
                </h2>
                <div
                    class="text-xs text-gray-500"
                    v-text="$filters.dateTime(exam.created_at, user.timezone)"
                />
            </div>
            <div class="flex justify-center p-6 w-36">
                <div class="text-sm">
                    {{ exam.bank_label }}
                </div>
            </div>
            <div class="flex items-center justify-center p-6 w-24">
                <div
                    v-if="exam.access == 'reserved'"
                    v-tippy
                    content="Reserved"
                >
                    <SvgIcon class="text-gray-900" icon="lock" size="base" />
                </div>
                <div v-if="exam.access == 'open'" v-tippy content="Open">
                    <SvgIcon class="text-gray-400" icon="unlock" size="base" />
                </div>
            </div>
            <div class="p-6 w-48">
                <PrepCategoryCircles
                    class="justify-start"
                    :categories="exam.categoryList"
                    options="stacked"
                />
            </div>
            <div class="flex justify-center p-6 w-24">
                {{ exam.questions_total }}
            </div>
            <div class="flex justify-center p-6 w-36">
                <div
                    v-if="exam.timed"
                    class="ml-2 text-sm text-gray-700 leading-6"
                    v-text="exam.time_total"
                />
            </div>
        </div>
        <div class="flex items-center">
            <div
                class="flex items-center justify-center p-6 pl-0 space-x-6 w-14"
            >
                <ActionMenu>
                    <ActionMenuAction
                        v-if="exam.status != 'archived'"
                        type="link"
                        label="Create assignment"
                        icon="assignment"
                        iconColor="text-green-200"
                        :action="'/prep/assignments/new?exam=' + exam.uuid"
                    />
                    <ActionMenuAction
                        v-if="exam.access == 'open'"
                        type="link"
                        label="Review exam"
                        icon="number-list"
                        iconColor="text-white"
                        :action="'/prep/exams/review/' + exam.uuid"
                    />
                    <ActionMenuAction
                        v-if="showShareableLink"
                        type="link"
                        label="Shareable link"
                        icon="link"
                        iconColor="text-yellow-200"
                        :action="'/prep/exams/shared/' + exam.uuid"
                    />
                    <ActionMenuAction
                        type="link"
                        label="View reporting"
                        icon="pulse"
                        iconColor="text-pink-200"
                        :action="pulseLink"
                    />
                    <ActionMenuAction
                        v-if="exam.status != 'archived'"
                        type="button"
                        label="Archive exam"
                        icon="archive"
                        iconColor="text-blue-200"
                        :action="archive"
                    />
                    <ActionMenuAction
                        v-if="exam.status == 'archived'"
                        type="button"
                        label="Restore exam"
                        icon="restore"
                        iconColor="text-blue-200"
                        :action="restore"
                    />
                    <ActionMenuAction
                        v-if="exam.deletable"
                        type="button"
                        label="Delete exam"
                        icon="remove"
                        iconColor="text-red-200"
                        :action="showDelete"
                    />
                </ActionMenu>
                <ConfirmDeleteModal
                    v-if="exam.deletable"
                    :active="deleteModalVisible"
                    @close="deleteModalVisible = false"
                    @confirm="confirmDelete"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import axios from "axios";
import { mapState } from "vuex";

export default {
    props: {
        exam: {
            type: Object,
            default: () => {},
        },
        highlighted: Boolean,
    },
    data(): any {
        return {
            deleteModalVisible: false,
            formStatus: "ready",
            published: false,
        };
    },
    computed: {
        ...mapState(["user"]),
        canDelete() {
            return this.exam.owner == "learner";
        },
        pulseLink() {
            return "/pulse/explore/prep" + "?exam=" + this.exam.uuid;
        },
        isAdmin() {
            return this.user.role == "admin";
        },
        showShareableLink() {
            let validRoles = [
                "admin",
                "group-admin",
                "group-owner",
                "partner-admin",
                "partner-owner",
            ];
            return (
                this.exam.status != "archived" &&
                (this.isAdmin || validRoles.includes(this.user?.role))
            );
        },
        subject() {
            return (
                this.user.partner_id ||
                this.user.group_id ||
                this.user.account_id ||
                this.user.cohort_id
            );
        },
    },
    methods: {
        archive() {
            axios
                .post("/api/prep/exam-library/" + this.exam.uuid + "/archive")
                .then(() => window.location.reload());
        },
        confirmDelete() {
            axios
                .delete("/api/prep/exam-library/" + this.exam.uuid)
                .then((response) => {
                    window.location.reload();
                });
        },
        restore() {
            axios
                .post("/api/prep/exam-library/" + this.exam.uuid + "/restore")
                .then(() => window.location.reload());
        },
        showDelete() {
            this.deleteModalVisible = true;
        },
    },
    mounted() {
        this.published = this.exam.assignment_published == "1";
    },
};
</script>
