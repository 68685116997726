<template>
    <div :class="`relative`">
        <TransitionRoot
            appear
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-100 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
            :show="nextSteps != null && showOverlay"
        >
            <LessonVideoOverlay @rewatch="rewatch" :next-steps="nextSteps" />
        </TransitionRoot>
        <div
            id="wistia-player"
            :class="`wistia_embed wistia_async_${id} shadow aspect-w-16 aspect-h-9 relative`"
        >
            <img
                class="w-full h-full object-contain"
                :src="`https://fast.wistia.com/embed/medias/${id}/swatch`"
                style="
                    filter: blur(5px);
                    height: 100%;
                    object-fit: contain;
                    width: 100%;
                "
                alt=""
                aria-hidden="true"
                onload="this.parentNode.style.opacity = 1;"
            />
        </div>
    </div>
</template>
<script lang="ts">
import axios from "axios";
import {
    logVideoView,
    updateVideoView,
    videoStats,
} from "../../api/pulse-requests";
import { defineComponent } from "vue";
import { getNextSteps } from "../../api/lessons";
import LessonVideoOverlay from "./LessonVideoOverlay.vue";
import { TransitionChild, TransitionRoot } from "@headlessui/vue";

export default defineComponent({
    name: "WistiaVideo",
    components: {
        TransitionChild,
        TransitionRoot,
        LessonVideoOverlay: LessonVideoOverlay as any,
    },
    props: {
        id: {
            required: true,
        },

        instanceId: {
            type: String,
        },

        startTime: {
            default: null,
            required: false,
        },

        userEmail: {
            default: null,
            required: false,
        },

        stats: {
            type: Boolean,
            default: true,
            required: false,
        },

        thumbnail: String,
    },
    methods: {
        trackWistiaPlaysInHeap(video: any, percent: number) {
            try {
                let reportingObject = {
                    nameOfVideo: video.name(),
                    percentageReached: percent,
                    duration: video.duration(),
                };
                window.heap?.track("Lesson Video Watched", reportingObject);
            } catch (e) {
                console.log(e);
            }
        },
        rewatch() {
            this.showOverlay = false;
            this.video.time(0);
            this.video.play();
        },
        getNextStepsDetails() {
            // get the path from the url
            let lessonPath = window.location.pathname;
            // remove /learn/courses from the beginning and any trailing and leading slashes
            lessonPath = lessonPath
                .replace(/^\/learn\/courses\//, "")
                .replace(/^\/|\/$/g, "");

            getNextSteps(lessonPath).then((nextSteps) => {
                this.nextSteps = nextSteps;
            });
        },
        async sendVideoView() {
            if (this.uniqueSeconds > 10) {
                if (this.activityId !== null) {
                    await updateVideoView(
                        this.instanceId,
                        this.activityId,
                        this.uniqueSeconds,
                        this.totalSeconds,
                        this.percentWatched
                    );
                } else {
                    const log = await logVideoView(
                        this.instanceId,
                        this.uniqueSeconds,
                        this.totalSeconds,
                        this.percentWatched
                    );
                    this.activityId = log.activity_id;
                }
            }
        },
        async sendStatsUpdate() {
            let percentageWatched = Math.floor(this.percentWatched * 100);

            videoStats(this.instanceId, {
                percent_watched: percentageWatched,
                seconds_watched: this.secondsBuffer,
            }).then();

            this.secondsBuffer = 0;
        },
    },

    data(): any {
        return {
            showOverlay: false,
            lastSecond: null,
            secondsBuffer: 0,
            uniqueSeconds: 0,
            totalSeconds: 0,
            percentWatched: 0,
            activityId: null,
            video: null,
            nextSteps: null,
        };
    },

    created() {
        window._wq = window._wq || [];
        const instanceId = this.instanceId;

        async function sendVideoLog() {}

        document.addEventListener("visibilitychange", this.sendVideoView);
        document.addEventListener("visibilitychange", this.sendStatsUpdate);

        let configuration = {
            id: this.id,

            // See the docs for more info: https://wistia.com/doc/embed-options
            options: {
                email: this.userEmail,
                playbar: true,
                resumable: true,
                seo: false,
                videoFoam: true,
                wmode: "transparent",
                stillUrl: this.thumbnail,
            },

            // See all available events and methods at https://wistia.com/doc/player-api.
            onReady: (video) => {
                this.video = video;
                /* Define time markers in number of seconds */
                let quarterPlayed = Math.floor(video.duration() / 4),
                    halfPlayed = Math.floor(video.duration() / 2),
                    threeQuartersPlayed = quarterPlayed * 3;

                if (this.stats) {
                    video.bind("play", () => {
                        this.trackWistiaPlaysInHeap(video, 0);
                        this.lastSecond = parseInt(video.time());
                        axios.put(
                            `/api/learn/lessons/${instanceId}/video-statistics`,
                            {
                                view_count: "increment",
                            }
                        );
                        return video.unbind;
                    });

                    // video.bind('percentwatchedchanged', percent => {
                    //     let percentageWatched = Math.floor(percent * 100);
                    //
                    //     if (percentageWatched !== 0 && percentageWatched % 1 === 0) {
                    //         axios.put(`/api/learn/lessons/${instanceId}/video-statistics`, {
                    //             percent_watched: percentageWatched,
                    //         });
                    //     }
                    // });

                    video.bind("secondchange", (currentSecond) => {
                        if (currentSecond === quarterPlayed) {
                            this.trackWistiaPlaysInHeap(video, 0.25);
                        }
                        if (currentSecond === halfPlayed) {
                            this.trackWistiaPlaysInHeap(video, 0.5);
                        }
                        if (currentSecond === threeQuartersPlayed) {
                            this.trackWistiaPlaysInHeap(video, 0.75);
                        }

                        let offset = currentSecond - this.lastSecond;

                        this.uniqueSeconds = video.secondsWatched();
                        this.totalSeconds++;
                        this.percentWatched = video.percentWatched();

                        // If the offset is not 1 then that means
                        // the user scrubbed forward or backward
                        if (offset !== 1) {
                            this.lastSecond = currentSecond;
                            return;
                        }

                        this.secondsBuffer++;
                        this.lastSecond = currentSecond;

                        if (this.secondsBuffer >= 30) {
                            axios.put(
                                `/api/learn/lessons/${instanceId}/video-statistics`,
                                {
                                    seconds_watched: this.secondsBuffer,
                                    percent_watched: Math.floor(
                                        video.percentWatched() * 100
                                    ),
                                }
                            );
                            this.secondsBuffer = 0;
                            this.sendVideoView();
                        }
                    });

                    video.bind("pause", () => {
                        let percentageWatched = Math.floor(
                            video.percentWatched() * 100
                        );

                        if (
                            percentageWatched !== 0 &&
                            percentageWatched % 1 === 0
                        ) {
                            axios.put(
                                `/api/learn/lessons/${instanceId}/video-statistics`,
                                {
                                    percent_watched: percentageWatched,
                                }
                            );
                        }
                        axios.post(`/api/learn/lessons/${instanceId}/watched`, {
                            seconds_watched: this.secondsBuffer + 1,
                        });

                        this.secondsBuffer = 0;
                        axios.put(
                            `/api/learn/lessons/${instanceId}/video-statistics`,
                            {
                                paused: true,
                            }
                        );
                    });

                    video.bind("end", () => {
                        this.trackWistiaPlaysInHeap(video, 1);
                        this.showOverlay = true;
                        let percentageWatched = Math.floor(
                            video.percentWatched() * 100
                        );

                        if (
                            percentageWatched !== 0 &&
                            percentageWatched % 1 === 0
                        ) {
                            axios.put(
                                `/api/learn/lessons/${instanceId}/video-statistics`,
                                {
                                    percent_watched: percentageWatched,
                                }
                            );
                        }
                        axios.post(`/api/learn/lessons/${instanceId}/watched`, {
                            seconds_watched: this.secondsBuffer + 1,
                        });

                        this.secondsBuffer = 0;
                        this.sendVideoView();
                    });
                }
            },
        };

        let startTime = parseInt(this.startTime);

        if (!isNaN(startTime)) {
            // @ts-ignore
            configuration.options.time = startTime;
        }

        window._wq.push(configuration);
    },

    mounted() {
        let jsonP = document.createElement("script");
        jsonP.setAttribute(
            "src",
            "https://fast.wistia.com/embed/medias/" + this.id + ".jsonp"
        );
        document.head.appendChild(jsonP);

        let wistia = document.createElement("script");
        wistia.setAttribute(
            "src",
            "https://fast.wistia.com/assets/external/E-v1.js"
        );
        document.head.appendChild(wistia);

        this.getNextStepsDetails();
    },

    render() {
        return this.$slots.default;
    },

    unmounted() {
        this.sendVideoView();
        document.removeEventListener("visibilitychange", this.sendVideoView);
    },
});
</script>

<style>
#wistia-player#wistia-player#wistia-player {
    .w-bottom-bar-lower > div:first-child > div:first-child {
        background: rgba(30, 41, 59, 0) !important;
    }

    .w-bottom-bar-lower > div:first-child > div:first-child + div {
        background: none !important;
    }

    .w-bottom-bar-lower > div:first-child > div:first-child + div:before {
        background: linear-gradient(
            to top,
            rgba(30, 41, 59, 0.5),
            rgba(30, 41, 59, 0)
        ) !important;
        content: "";
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 60px;
    }

    .w-dialog,
    .w-slider-wrapper {
        background: rgba(30, 41, 59, 0.6) !important;
        border-radius: 8px !important;
    }

    .w-dialog {
        bottom: 4px !important;
    }

    .w-bottom-bar .w-vulcan-v2-button {
        border-radius: 8px !important;

        & :hover {
            background-color: rgba(255, 255, 255, 0) !important;
            color: hsl(218, 33%, 80%) !important;

            svg {
                fill: currentColor !important;

                path,
                polyline,
                rect {
                    stroke: currentColor !important;
                }
            }
        }
    }

    .w-big-play-button > div:first-child {
        background: rgba(30, 41, 59, 0.4) !important;
    }

    .w-big-play-button > div:first-child + div {
        background: rgba(30, 41, 59, 0.6) !important;
    }

    .w-bpb-wrapper {
        border-radius: 1rem;
        overflow: hidden;
    }
}
</style>
