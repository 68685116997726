<script setup lang="ts">
import { defineProps, ref } from "vue";

const props = defineProps<{ moduleTitle?: string; description?: string }>();

const opened = ref(false);
</script>
<template>
    <div class="flex flex-col space-y-4">
        <div>
            <button
                class="cta flex items-center text-sm space-x-2"
                @click="opened = !opened"
            >
                <span>View description</span>
                <svg-icon
                    v-if="!opened"
                    icon="chevron-down"
                    size="xs"
                    weight="extra-bold"
                ></svg-icon>
                <svg-icon
                    v-if="opened"
                    icon="chevron-up"
                    size="xs"
                    weight="extra-bold"
                ></svg-icon>
            </button>
        </div>
        <div v-show="opened" class="space-y-2">
            <h2 class="text-sm font-bold" v-if="props.moduleTitle">
                Module: {{ props.moduleTitle }}
            </h2>
            <div>
                {{ props.description }}
            </div>
        </div>
    </div>
</template>
