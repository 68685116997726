<template>
    <div
        class="relative inline-flex items-center border text-sm font-medium rounded-full p-1 mr-2 mb-2 last:mr-0 last:mb-0 min-w-48"
        :class="theme.badge"
    >
        <div
            v-if="showBank"
            class="absolute top-0 right-0 rounded-full -mr-1 -mt-1 h-4 w-4"
            v-tippy
            :content="category.bank"
            :class="bankTheme"
        />
        <div class="bg-white rounded-full shadow-inner p-1">
            <div
                v-tippy
                :content="category.root_category"
                class="flex items-center justify-center font-bold text-xs tracking-wider rounded-full w-8 h-8"
                :class="theme.label"
            >
                {{ label }}
            </div>
        </div>
        <div class="ml-1 mr-4">
            {{ categoryName }}
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        category: Object as PropType<any>,
        primary: Boolean,
        showBank: Boolean,
    },
    computed: {
        bankTheme() {
            switch (this.category?.bank_slug) {
                case "ct-anatomy-and-pathology":
                case "ct-image-production":
                case "ct-imaging-procedures":
                case "ct-patient-care":
                case "ct-radiation-safety":
                    return "bg-cyan-600";
                    break;
                case "fundamentals-of-digital-radiography":
                case "introduction-to-radiography-patient-care":
                case "radiography-image-evaluation-and-quality-control":
                case "radiography-image-production":
                case "radiography-positioning-and-anatomy":
                case "radmathbootcamp":
                case "x-ray-production-and-safety":
                    return "bg-orange-600";
                    break;
                case "lmrt":
                    return "bg-green-600";
                    break;
                case "radiography":
                    return "bg-blue-600";
                    break;
                case "ct":
                    return "bg-red-600";
                    break;
            }
        },
        categoryName() {
            return this.primary
                ? this.category?.primary_category
                : this.category?.category;
        },
        label() {
            switch (this.category?.root_category) {
                case "Patient Care":
                    return "PC";
                    break;
                case "Safety":
                    return "Sa";
                    break;
                case "Image Production":
                    return "IP";
                    break;
                case "Procedures":
                    return "Pr";
                    break;
                default:
                    return this.category?.root_category.charAt(0);
                    break;
            }
        },
        theme() {
            switch (this.category?.root_category) {
                case "Patient Care":
                    return {
                        badge: "bg-indigo-50 border-indigo-100 text-indigo-700",
                        label: "bg-indigo-600 text-white",
                    };
                    break;
                case "Safety":
                    return {
                        badge: "bg-cyan-50 border-cyan-100 text-cyan-700",
                        label: "bg-cyan-600 text-white",
                    };
                    break;
                case "Image Production":
                    return {
                        badge: "bg-pink-50 border-pink-100 text-pink-700",
                        label: "bg-pink-600 text-white",
                    };
                    break;
                case "Procedures":
                    return {
                        badge: "bg-orange-50 border-orange-100 text-orange-700",
                        label: "bg-orange-600 text-white",
                    };
                    break;
                default:
                    return {
                        badge: "bg-gray-50 border-gray-100 text-gray-700",
                        label: "bg-gray-600 text-white",
                    };
            }
        },
    },
});
</script>
