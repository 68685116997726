<template>
    <div class="relative w-full">
        <div class="h-0 pb-full">
            <ChartDonut :chartData="chartData" :options="options" />
        </div>
        <div
            class="absolute top-[4px] left-0 flex items-center justify-center w-full h-full"
        >
            <div
                class="flex items-center justify-center font-extralight leading-none tracking-tight cursor-default"
                :class="{
                    'text-4xl': !mini,
                    'text-sm font-medium': mini,
                }"
                :style="'color: ' + colors[name].dark"
            >
                <div v-if="percentage" v-text="percentage" />
                <template v-else>
                    <SvgIcon v-if="!mini" icon="forbidden" size="base" />
                    <SvgIcon
                        v-if="mini"
                        icon="forbidden"
                        size="sm"
                        weight="medium"
                    />
                </template>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
// TODO TEST
// @ts-ignore
import tailwind from "../../../../../tailwind.json";

export default {
    props: {
        correct: Number,
        mini: Boolean,
        name: {
            type: String,
            default: "Patient Care",
        },
        total: Number,
    },
    data(): any {
        return {
            options: {
                cutout: "74%",
                elements: {
                    arc: {
                        hoverBorderColor: "#fff",
                    },
                },
                legend: {
                    display: false,
                },
                tooltips: {
                    enabled: false,
                },
            },
        };
    },
    computed: {
        chartData() {
            return {
                datasets: [
                    {
                        data: [
                            this.correct,
                            this.total ? this.total - this.correct : 1,
                        ],
                        backgroundColor: [
                            this.colors[this.name].dark,
                            this.colors[this.name].light,
                        ],
                        hoverBackgroundColor: [
                            this.colors[this.name].dark,
                            this.colors[this.name].light,
                        ],
                    },
                ],
            };
        },
        colors() {
            return {
                "Patient Care": {
                    light: tailwind.colors.indigo["100"],
                    dark: tailwind.colors.indigo["500"],
                },
                Safety: {
                    light: tailwind.colors.cyan["100"],
                    dark: tailwind.colors.cyan["500"],
                },
                "Image Production": {
                    light: tailwind.colors.pink["100"],
                    dark: tailwind.colors.pink["500"],
                },
                Procedures: {
                    light: tailwind.colors.orange["100"],
                    dark: tailwind.colors.orange["500"],
                },
            };
        },
        percentage() {
            if (!this.total) {
                return null;
            }
            return Math.round((this.correct / this.total) * 100) + "%";
        },
        ratio() {
            return this.correct + "/" + this.total;
        },
    },
};
</script>
