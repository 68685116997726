<template>
    <div>
        <SidebarBase theme="suit">
            <template #primary>
                <SidebarNavItem
                    label="Home"
                    :naked="true"
                    path="/"
                    icon="home"
                />
                <SidebarNavParent
                    label="Content"
                    icon="content-structure"
                    path="/admin/content"
                >
                    <SidebarNavChild
                        label="Courses"
                        icon="chalkboard"
                        path="/admin-portal/content/learn/courses"
                    />
                    <SidebarNavChild
                        label="Lessons"
                        icon="book"
                        path="/admin-portal/content/learn/lessons"
                    />
                    <SidebarNavChild
                        label="Lessons(old)"
                        icon="book"
                        path="/admin/content/learn/lessons"
                    />
                    <SidebarNavChild
                        label="Exams"
                        icon="exam"
                        path="/admin/content/learn/exams"
                    />
                    <SidebarNavChild
                        label="Questions"
                        icon="question-cloud"
                        path="/admin/content/learn/questions"
                    />
                    <SidebarNavChild
                        label="Categories"
                        path="/admin-portal/content/learn/categories"
                    />
                    <SidebarNavChild
                        label="Disciplines"
                        path="/admin-portal/content/learn/disciplines"
                    />
                </SidebarNavParent>
                <SidebarNavParent label="Prep" icon="summit" path="/admin/prep">
                    <SidebarNavChild
                        label="Questions"
                        icon="question-cloud"
                        path="/admin/content/prep/questions"
                    />
                </SidebarNavParent>
                <SidebarNavParent
                    label="Accounts"
                    icon="id"
                    path="/admin/accounts"
                >
                    <SidebarNavChild
                        label="Partners"
                        icon="books"
                        path="/admin/accounts/partners"
                    />
                    <SidebarNavChild
                        label="Users"
                        icon="user"
                        path="/admin/accounts/users"
                    />
                    <SidebarNavChild
                        label="Access Codes"
                        icon="barcode"
                        path="/admin/accounts/access-codes"
                    />
                </SidebarNavParent>
                <SidebarNavParent
                    v-if="user.permissions.super"
                    label="Tools"
                    icon="toolbox"
                >
                    <SidebarNavChild
                        label="Horizon"
                        icon="horizon"
                        path="/horizon"
                        :external="true"
                    />
                    <SidebarNavChild
                        label="Flare"
                        icon="flare"
                        path="https://flareapp.io/projects/3243-meadow"
                        :external="true"
                    />
                    <SidebarNavChild
                        label="Logger"
                        icon="logging-truck"
                        path="/admin/tools/logger"
                    />
                    <SidebarNavChild
                        label="Icons"
                        icon="robot"
                        path="/admin/tools/icons"
                    />
                </SidebarNavParent>
            </template>
        </SidebarBase>
    </div>
</template>

<script lang="ts">
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState(["user"]),
    },
};
</script>
