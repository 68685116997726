<template>
    <button
        type="button"
        class="flex items-center justify-center bg-white rounded-lg shadow w-9 h-9"
        v-tippy
        content="Provide feedback"
        @click="open"
    >
        <SvgIcon class="text-gray-600" icon="contact" size="sm" />
    </button>
</template>

<script lang="ts">
declare const zE: any;

export default {
    props: {
        subject: String,
    },
    methods: {
        open() {
            zE("messenger", "open");
        },
    },
};
</script>
