import axios from "axios";

export function adminSearchLessons(query: string) {
    return axios.get(`/admin/content/learn/lessons-search?q=${query}`);
}

export function adminAddLessonToModule(lessonUuid: string, moduleUuid: string) {
    return axios.post(`/admin/content/learn/add-lesson-to-module`, {
        lessonUuid,
        moduleUuid,
    });
}

export function getModuleLessons(moduleUuid: string) {
    return axios.get(`/api/admin/content/modules/${moduleUuid}/lessons`);
}

export function updateModuleLessonOrder(
    moduleUuid: string,
    lessonIds: number[]
) {
    return axios.put(`/api/admin/content/modules/${moduleUuid}/lessons/order`, {
        lessonIds,
    });
}
