<script lang="ts" setup>
import ScheduledPlanChangeMessage from "./ScheduledPlanChangeMessage.vue";
import { computed } from "vue";
import { format, parse } from "date-fns";
import { checkoutApi } from "./requests";
import { frequencies } from "./types";
import { useUserStore } from "@/stores/userStore";
import { useIsLoading } from "@/components/ui/loaders/loadingStore";

const userStore = useUserStore();
const isLoading = useIsLoading();

const onCancelClicked = async () => {
    isLoading.value = true;
    await checkoutApi.cancelSubscriptionChange();
    await userStore.getUsersSubscriptions();
    isLoading.value = false;
};

const props = defineProps<{
    schedule: any;
}>();

const getMetadata = (key: string) => {
    return props.schedule?.metadata[key];
};

const hasScheduledChanges = computed(() => {
    return props.schedule;
});

const dateChange = computed(() => {
    let dateUnixTime = props.schedule?.phases?.[1]?.start_date;
    let date = parse(dateUnixTime, "t", new Date());
    return format(date, "MMM dd, yyyy");
});

const frequencyCurrent = computed(() => {
    return getMetadata("frequency_current") || "";
});

const frequencyNew = computed(() => {
    return getMetadata("frequency_new") || "";
});

const planCurrent = computed(() => {
    return getMetadata("plan_current") || "";
});

const planNew = computed(() => {
    return getMetadata("plan_new") || "";
});
const newPriceCurrency = computed(() => {
    return (
        props.schedule?.phases?.[1]?.items?.[0]?.price?.currency || "usd"
    ).toUpperCase();
});

const newPrice = computed(() => {
    const amount =
        props.schedule?.phases?.[1]?.items?.[0]?.price?.unit_amount || 0;
    return (amount / 100).toFixed(2);
});

const changeMessage = computed(() => {
    // month, quarter, year
    let frequency = frequencies[frequencyNew.value];
    let planText =
        planNew.value === "professional" ? "Professional" : "Student";
    // Example: Your new billing frequency is monthly effective on Mar 21, 2023.
    return `Your change to <strong>${planText}</strong> plan billed <strong>${frequency.alt}</strong> will be effective
        ${dateChange.value}.`; //The new price will be $${newPrice.value} ${frequency.per}.`;
});
</script>
<template>
    <ScheduledPlanChangeMessage v-if="hasScheduledChanges">
        <template #message>
            <p class="text-sm" v-html="changeMessage"></p>
        </template>
        <template #action>
            <button
                class="text-blue-500 text-sm hover:cursor-pointer"
                @click="onCancelClicked"
            >
                Cancel Change
            </button>
        </template>
    </ScheduledPlanChangeMessage>
</template>
