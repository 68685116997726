<template>
    <div
        class="fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center z-top"
        v-show="active"
    >
        <transition
            enter-active-class="transition ease-out duration-200"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition ease-in duration-200"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div class="fixed inset-0 transition-opacity" v-if="active">
                <div class="absolute inset-0 bg-gray-900 opacity-75"></div>
            </div>
        </transition>
        <transition
            enter-active-class="transition ease-out duration-200"
            enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to-class="opacity-100 translate-y-0 sm:scale-100"
            leave-active-class="transition ease-in duration-200"
            leave-class="opacity-100 translate-y-0 sm:scale-100"
            leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
            <div
                class="bg-white rounded-md px-4 pt-5 pb-4 overflow-hidden shadow-md transform transition-all sm:max-w-md sm:w-full sm:p-6"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
                v-if="active"
                v-click-away="close"
            >
                <div class="sm:flex sm:items-start">
                    <div
                        class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"
                    >
                        <SvgIcon class="text-gray-600" icon="error" />
                    </div>
                    <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3
                            class="text-lg leading-6 font-medium text-gray-900"
                            id="modal-headline"
                        >
                            Report Question
                        </h3>
                        <p class="text-sm leading-5 text-gray-500">
                            Please provide a brief description of the issue
                        </p>
                    </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:ml-10 sm:pl-4">
                    <div class="mb-4">
                        <SelectDropdown v-model="issue">
                            <option value="">Choose an issue</option>
                            <option value="Incorrect answer">
                                Incorrect answer
                            </option>
                            <option value="Incorrect feedback">
                                Incorrect feedback
                            </option>
                            <option value="Poor wording/question unclear">
                                Poor wording/question unclear
                            </option>
                            <option value="Grammar/spelling">
                                Grammar/spelling
                            </option>
                        </SelectDropdown>
                    </div>
                    <div>
                        <textarea
                            class="bg-gray-100 text-base leading-5 rounded p-3 w-full h-32"
                            v-model="description"
                        ></textarea>
                    </div>
                </div>
                <div class="mt-5 sm:mt-2 sm:ml-10 sm:pl-4">
                    <p class="text-xs italic leading-4 mb-6 text-gray-500">
                        Your user profile and information identifying the
                        question will be included in your feedback
                    </p>
                    <div class="sm:flex">
                        <span class="flex w-full rounded sm:w-auto">
                            <button
                                v-if="status == 'ready'"
                                type="button"
                                class="main button px-4 py-2 text-base sm:text-sm leading-6 w-32"
                                @click="submit"
                            >
                                Submit
                            </button>
                            <button
                                v-if="status == 'processing'"
                                type="button"
                                class="flex items-center justify-center primary button px-4 py-2 text-base sm:text-sm leading-6 w-32"
                            >
                                <SvgIcon class="animate-spin" icon="loader" />
                            </button>
                            <button
                                v-if="status == 'finished'"
                                type="button"
                                class="flex items-center justify-center primary button px-4 py-2 text-base sm:text-sm leading-6 w-32"
                                @click="close"
                            >
                                Thank you
                            </button>
                        </span>
                        <span
                            class="mt-3 flex w-full rounded sm:mt-0 sm:ml-3 sm:w-auto"
                        >
                            <button
                                type="button"
                                class="underline px-4 hover:bg-gray-100 rounded transition duration-200"
                                @click="close"
                            >
                                Cancel
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
import axios from "axios";

export default {
    props: {
        active: Boolean,
        question: String,
        questionType: String,
    },
    data(): any {
        return {
            issue: "",
            description: "",
            status: "ready",
        };
    },
    computed: {
        path() {
            return "/api/" + this.questionType + "/questions/provide-feedback";
        },
    },
    methods: {
        close() {
            this.reset();
            this.$emit("close");
        },
        reset() {
            this.issue = "";
            this.description = "";
            this.status = "ready";
        },
        submit() {
            if (!this.issue || !this.description) {
                return;
            }
            this.status = "processing";
            axios
                .post(this.path, {
                    question: this.question,
                    issue: this.issue,
                    description: this.description,
                })
                .then((response) => {
                    this.$events.$emit("toastNotification", {
                        action: "sent",
                        heading: "Success",
                        status: "sent",
                    });

                    this.reset();
                    this.$emit("close");
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("RESPONSE ERROR");
                        console.log(error.response);
                    } else if (error.request) {
                        console.log("REQUEST ERROR");
                        console.log(error.request);
                    } else {
                        console.log("Error", error.message);
                    }
                    console.log("------");
                    console.log(error.config);
                });
        },
    },
};
</script>
