<script setup lang="ts">
import axios from "axios";
import { useStore } from "vuex";
import FullPagination from "../../../_elements/FullPagination.vue";
import { onMounted, ref } from "vue";
import AddLessonToModule from "./AddLessonToModule.vue";

const store = useStore();

const props = defineProps<{
    uuid?: string;
}>();

const data = ref<any>({
    module: null,
    lessons: [],
    path: window.location.pathname + "/",
    pagination: {
        currentPage: 1,
        lastPage: 10,
        perPage: 1,
        total: 0,
        from: 1,
        to: 10,
    },
});

const getLessons = (pageNumber) => {
    axios
        .post(`/api/admin/content/learn/lessons`, { page: pageNumber })
        .then((response) => {
            data.value.lessons = response.data.data;
            data.value.pagination.perPage = response.data.per_page;
            data.value.pagination.total = response.data.total;
            data.value.pagination.currentPage = response.data.current_page;
            data.value.pagination.from = response.data.from;
            data.value.pagination.to = response.data.to;
            data.value.pagination.lastPage = response.data.last_page;
        });
};
const getModuleLessons = (pageNumber) => {
    axios
        .post(`/api/admin/content/learn/modules/${props.uuid}/lessons`, {
            page: pageNumber,
        })
        .then((response) => {
            data.value.module = response.data.data[0];
            data.value.lessons = data.value.module.lessons;
            data.value.pagination.perPage = response.data.per_page;
            data.value.pagination.total = response.data.total;
            data.value.pagination.currentPage = response.data.current_page;
            data.value.pagination.from = response.data.from;
            data.value.pagination.to = response.data.to;
            data.value.pagination.lastPage = response.data.last_page;
        });
};

onMounted(() => {
    store.dispatch("hideBladePage");
    if (!!props.uuid) {
        getModuleLessons(1);
    } else {
        getLessons(1);
    }
});
</script>
<template>
    <div>
        <div v-if="data.module" class="flex flex-row justify-between">
            <div>
                <h1 class="text-xl">Module: {{ data.module?.title ?? "" }}</h1>
            </div>
            <div class="flex flex-row items-center space-x-2">
                <div class="text-xs flex flex-row items-center">
                    Id: {{ data.module.id }}
                    <CopyAction :value="data.module.id" />
                </div>
                <div class="text-xs flex flex-row items-center">
                    Uuid: {{ data.module.uuid }}
                    <CopyAction :value="data.module.uuid" />
                </div>
            </div>
        </div>
        <div class="flex justify-end mb-6 space-x-2">
            <div v-if="data.module" class="flex flex-row space-x-2">
                <router-link
                    class="rounded-md bg-black px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    :to="{
                        name: 'module.edit.lesson-order',
                        params: { uuid: data.module.uuid },
                    }"
                >
                    Change Lesson Order
                </router-link>
                <AddLessonToModule :module="data.module" />
            </div>
            <router-link
                class="flex items-center space-x-2 small main button"
                :to="{ name: 'lesson', params: { uuid: 'new' } }"
            >
                <svg-icon icon="add" size="xxs" weight="extra-bold"></svg-icon>
                <div>New</div>
            </router-link>
        </div>
        <h1 class="text-lg mt-2">Lessons</h1>
        <div class="rounded-md shadow divide-y">
            <div
                class="flex items-center bg-gray-50 border-b-2 border-gray-200 text-xs text-gray-600 uppercase font-semibold leading-none rounded-t-md pt-6 pb-5"
            >
                <div class="flex items-center justify-between px-6 w-1/3">
                    Name
                </div>
                <div class="flex items-center justify-between px-6 w-1/3">
                    Slug
                </div>
                <div class="flex items-center justify-between px-6 w-1/3">
                    Courses
                </div>
            </div>
            <div>
                <div
                    v-for="l in data.lessons"
                    class="flex items-center bg-white text-sm even:bg-gray-50 last:rounded-b-md"
                >
                    <div class="p-6 w-1/3">
                        <router-link
                            class="cta"
                            :to="{ name: 'lesson', params: { uuid: l.uuid } }"
                        >
                            {{ l.title }}
                        </router-link>
                    </div>
                    <div class="font-mono p-6 w-1/3">
                        {{ l.slug }}
                    </div>
                    <div class="p-6 w-1/3">
                        <ul
                            v-for="c in l.courses"
                            class="list-disc list-outside"
                        >
                            <li>{{ c.title }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <FullPagination
                :current-page="data.pagination.currentPage"
                :last-page="data.pagination.lastPage"
                :per-page="data.pagination.perPage"
                :total="data.pagination.total"
                :from="data.pagination.from"
                :to="data.pagination.to"
                @page-updated="getLessons"
            />
        </div>
    </div>
</template>
