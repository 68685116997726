<script setup lang="ts">
import { computed, defineProps } from "vue";
import {
    capitalizeFirstLetter,
    formatDate as _formatDate,
    formatPrice,
} from "@/utils";
import { useUserStore } from "@/stores/userStore";
import PlanChangeMessage from "./Checkout/PlanChangeMessage.vue";
import { FrequencyKeys } from "./Checkout/types";
import SubscriptionActionLink from "@/components/Account/Checkout/SubscriptionActionLink.vue";

const userStore = useUserStore();

interface Subscription {
    cancel_at_period_end: boolean;
    status: string;
    stripe_subscription_id: string;
    title: string;
    price: number;
    current_period_end: string;
    latest_invoice: any;
    slug: string;
    is_legacy: boolean;
    stripe_subscription: any; // StripeSubscription
    subscription_schedules: any;
}

const props = defineProps<{ subscriptions: Subscription[] }>();
const subscription = computed(() => props.subscriptions[0] ?? null);
const frequency = computed<FrequencyKeys>(() => {
    // determine frequency 1m, 3m, 1y
    const interval =
        subscription.value?.stripe_subscription?.items?.data[0]?.price
            ?.recurring?.interval;
    const interval_count =
        subscription.value?.stripe_subscription?.items?.data[0]?.price
            ?.recurring?.interval_count;
    if (interval === "month" && interval_count === 1) {
        return "1m";
    } else if (interval === "month" && interval_count === 3) {
        return "3m";
    } else if (interval === "year" && interval_count === 1) {
        return "1y";
    } else {
        return "1m";
    }
});

const isProfessional = computed(() => {
    return subscription.value?.slug.startsWith("professional-");
});

const formatDate = (date: any) => {
    return _formatDate(date, userStore.user?.user.timezone);
};

const hasPendingChange = computed(() => {
    return subscription.value?.stripe_subscription.schedule !== null;
});

const isRtbc = computed(() => {
    // if it is legacy and if it contains ctbc in the slug
    return (
        subscription.value?.is_legacy &&
        (subscription.value?.slug.includes("radtech") ||
            subscription.value?.slug.includes("rtbc"))
    );
});

const isCtbc = computed(() => {
    return (
        (subscription.value?.is_legacy &&
            subscription.value?.slug.includes("ctbc")) ||
        subscription.value?.slug.includes("cttech") ||
        subscription.value?.slug.includes("cttechbootcamp")
    );
});

const upgradeUrl = computed(() => {
    let url = `/account/checkout/upgrade-plan?frequency=${frequency.value}`;
    if (isRtbc.value) {
        url = url + `&from=rtbc`;
    }
    if (isCtbc.value) {
        url = url + `&from=ctbc`;
    }
    return url;
});

const downGradeUrl = computed(() => {
    let url = `/account/checkout/downgrade-plan`;
    if (isRtbc.value) {
        url = url + `?from=rtbc`;
    }
    if (isCtbc.value) {
        url = url + `?from=ctbc`;
    }
    return url;
});
</script>
<template>
    <div
        v-if="subscription"
        class="space-y-2.5 bg-blue-50 p-6"
        :class="subscription.cancel_at_period_end ? 'bg-gray-100' : ''"
    >
        <PlanChangeMessage
            :schedule="subscription?.stripe_subscription?.schedule"
        />
        <div
            class="flex flex-col md:flex-row gap-4 justify-between md:items-center"
        >
            <div class="flex flex-col space-y-1">
                <div
                    v-if="subscription.cancel_at_period_end"
                    class="flex justify-start"
                >
                    <Pill>Canceled</Pill>
                </div>
                <div
                    v-if="subscription.status !== 'active'"
                    class="flex justify-start"
                >
                    <Pill background-color="red-100" text-color="red-700"
                        >Payment Failed
                    </Pill>
                </div>
                <div class="font-bold text-xl">
                    {{ subscription.title }}
                    {{ subscription.is_legacy ? "(Legacy)" : "" }}
                </div>
                <div class="py-2">
                    <div class="text-lg">
                        ${{ formatPrice(subscription.price) }} per month
                    </div>
                    <div
                        class="text-small tracking-tight"
                        v-if="!subscription.cancel_at_period_end"
                    >
                        Your plan renews on
                        <strong>{{
                            formatDate(subscription.current_period_end)
                        }}</strong>
                    </div>
                </div>
                <div v-if="!subscription.is_legacy">
                    <SubscriptionActionLink
                        :has-pending-change="hasPendingChange"
                        :href="`/account/checkout/change-frequency?frequency=${frequency}`"
                        tip-message="To change your billing frequency first cancel your pending plan change."
                        >Change Billing Frequency</SubscriptionActionLink
                    >
                </div>
                <div
                    class="text-small tracking-tight"
                    v-if="subscription.cancel_at_period_end"
                >
                    Your plan will be canceled on
                    <strong>{{
                        formatDate(subscription.current_period_end)
                    }}</strong>
                </div>
                <div
                    class="flex flex-row"
                    v-if="subscription.status !== 'active'"
                >
                    <div class="card-desc card-details text-gray-400">
                        {{
                            capitalizeFirstLetter(
                                subscription.latest_invoice.charge
                                    .payment_method_details.card.brand
                            )
                        }}
                        ••••
                        {{
                            subscription.latest_invoice.charge
                                .payment_method_details.card.last4
                        }}
                    </div>
                </div>
                <div
                    class="text-small tracking-tight text-red-700"
                    v-if="subscription.status !== 'active'"
                >
                    Your latest payment has failed. Update your payment method
                    to continue this plan.
                </div>
            </div>
            <div class="flex flex-col gap-2 items-center text-center">
                <!--                <a-->
                <!--                    v-if="subscription.is_legacy"-->
                <!--                    class="w-full md:w-auto shadow bg-yellow-400 w-full rounded hover:bg-yellow-300 hover:no-underline text-gray-900 font-bold py-4 px-8 m-4"-->
                <!--                    href="/account/checkout/legacy-upgrade"-->
                <!--                >-->
                <!--                    Upgrade Plan-->
                <!--                </a>-->
                <a
                    v-if="!isProfessional && !isCtbc"
                    class="w-full md:w-auto button border-none bg-yellow-400 w-full rounded-md hover:bg-yellow-300"
                    :href="upgradeUrl"
                >
                    Upgrade Plan
                </a>
                <a
                    class="button main"
                    v-if="subscription.status !== 'active'"
                    :href="
                        '/account/billing/update-subscription-payment/' +
                        subscription.stripe_subscription_id
                    "
                >
                    Update Payment Method
                </a>

                <SubscriptionActionLink
                    v-if="(!subscription.is_legacy && isProfessional) || isCtbc"
                    class="w-full md:w-auto button rounded-md border-none shadow-none bg-blue-100 hover:bg-blue-200"
                    :has-pending-change="hasPendingChange"
                    :href="downGradeUrl"
                    tip-message="To change your billing frequency first cancel your pending plan change."
                    >Switch to Student Plan</SubscriptionActionLink
                >

                <SubscriptionActionLink
                    v-if="!subscription.cancel_at_period_end"
                    :has-pending-change="hasPendingChange"
                    :href="`/account/billing/subscription/${subscription.stripe_subscription_id}/cancel`"
                    tip-message="To change your billing frequency first cancel your pending plan change."
                    class="w-full md:w-auto button rounded-md border-none shadow-none bg-blue-50 hover:bg-blue-100 text-gray-900"
                    >Cancel Subscription</SubscriptionActionLink
                >
                <a
                    class="w-full md:w-auto button button-primary rounded-md shadow-none hover:bg-gray-300"
                    :href="`/account/billing/subscription/${subscription.stripe_subscription_id}/resume`"
                    v-if="subscription.cancel_at_period_end"
                >
                    Resume Subscription
                </a>
            </div>
        </div>
    </div>
</template>
