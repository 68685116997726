<script setup lang="ts">
import { onMounted, ref } from "vue";
import {
    getModuleLessons,
    updateModuleLessonOrder,
} from "../../../../api/admin";
import { Lesson } from "../../../../types";
import draggable from "vuedraggable";

const props = defineProps<{
    uuid: string;
}>();

const lessons = ref<Lesson[]>([]);
const drag = ref(false);

const getLessons = () => {
    getModuleLessons(props.uuid).then((response) => {
        lessons.value = response.data;
    });
};

onMounted(() => {
    getLessons();
});

const saveOrder = () => {
    // extract the lesson ids from the lessons array
    const lessonIds = lessons.value.map((lesson) => lesson.id);
    // send the lesson ids to the server
    updateModuleLessonOrder(props.uuid, lessonIds).then(() => getLessons());
};
</script>
<template>
    <div class="flex flex-row items-center justify-between">
        change lesson order
        <button
            class="ml-2 px-4 py-2 bg-blue-500 text-white rounded-md"
            @click="saveOrder"
        >
            Save Order
        </button>
    </div>
    <div class="flex flex-col space-y-4 mt-4">
        <draggable
            v-model="lessons"
            handle=".drag-handle"
            @start="drag = true"
            @end="drag = false"
        >
            <template #item="{ element: lesson, index }">
                <div class="bg-white flex flex-row items-center my-2">
                    <div
                        class="flex items-center justify-center flex-shrink-0 drag-handle text-gray-400 ml-3 w-9 h-9"
                    >
                        <SvgIcon icon="drag" size="sm" />
                    </div>
                    {{ index + 1 }}. {{ lesson.title }}
                </div>
            </template>
        </draggable>
    </div>
</template>
