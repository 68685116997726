<script setup lang="ts">
// a compone that extends html button or vue RouterLink or anchor tag
import { cva, VariantProps } from "class-variance-authority";
import { buttonStyles } from "@/components/ui/ButtonLinks/buttonStyles";
import { ButtonHTMLAttributes, computed } from "vue";
// build a tailwind button
type ButtonProps = Required<VariantProps<typeof buttonStyles>>;

interface Props extends ButtonHTMLAttributes {
    intent?: ButtonProps["intent"];
    size?: ButtonProps["size"];
    full?: ButtonProps["full"];
    round?: boolean;
}

const props = defineProps<Props>();
const classes = computed(() => {
    return buttonStyles({
        intent: props.intent,
        size: props.size,
        full: props.full,
        round: props.round,
    });
});
</script>
<template>
    <button :class="classes" v-bind="props">
        <slot></slot>
    </button>
</template>
