<script setup lang="ts">
import {
    ref,
    watch,
    computed,
    defineEmits,
    defineProps,
    withDefaults,
} from "vue";
import {
    RegisterData,
    register,
    registerSchema,
    login,
    LoginData,
    loginSchema,
} from "@/api/register";
import CloverFullLogo from "@/components/ui/images/CloverFullLogo.vue";
import WavesBg from "@/components/ui/images/WavesBg.vue";
import OnboardingSurvey from "./OnboardingSurvey/OnboardingSurvey.vue";
import Alert from "@/components/ui/alerts/Alert.vue";
import Button from "@/components/ui/ButtonLinks/Button.vue";
import { useForm } from "@/hooks/form";
import FormInput from "@/components/ui/forms/Input.vue";
import { useBuyNow, useBuyNowLink } from "./hooks";

const buyNow = useBuyNow();
const buyNowLink = useBuyNowLink();

const props = withDefaults(defineProps<{ isRegisterPage: boolean }>(), {
    isRegisterPage: false,
});

const serverErrors = ref<string[]>([]);
const { isValid, isDirty, isSubmitted, isSubmitting, submit, data, errors } =
    useForm({
        schema: loginSchema,
        submit: async (data) => {
            const result = await login(data);
            if (result.success) {
                if (buyNow.value) {
                    window.location.replace(buyNowLink.value);
                } else {
                    // TODO if buy now redirect to select a plan page.
                    window.location.replace("/");
                }
            } else {
                console.log(result.errors);
                serverErrors.value = [result.errors.message];
            }
        },
        initValues: {
            email: "",
            password: "",
        },
        errorsIfDirty: false,
    });

const emits = defineEmits<{
    (event: "toggleRegister"): void;
}>();

const toggleRegister = () => {
    emits("toggleRegister");
};
</script>
<template>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 z-10 relative pb-24">
        <div
            class="flex flex-col md:flex-row items-start justify-center md:space-x-12 pt-8 md:pt-16"
        >
            <div class="space-y-6 mt-4 md:mt-0 w-full md:w-1/2">
                <div>
                    <CloverFullLogo />
                </div>

                <div
                    class="bg-white rounded-md shadow p-8 md:p-12 mb-12 space-y-2"
                >
                    <div class="text-center">
                        <h2
                            class="text-2xl md:text-4xl font-bold tracking-tight"
                        >
                            Sign In
                        </h2>
                    </div>
                    <Alert
                        type="error"
                        title="Error creating account."
                        v-if="isSubmitted && serverErrors.length"
                    >
                        <template #message>
                            <ul>
                                <li v-for="error in serverErrors" :key="error">
                                    {{ error }}
                                </li>
                            </ul>
                        </template>
                    </Alert>
                    <div class="flex flex-col space-y-8 mt-12">
                        <form @submit.prevent="submit">
                            <div class="space-y-6">
                                <div>
                                    <FormInput
                                        type="text"
                                        id="email"
                                        name="email"
                                        label="Email"
                                        v-model="data.email"
                                        :error="errors?.email"
                                    />
                                </div>
                                <div>
                                    <FormInput
                                        type="password"
                                        id="password"
                                        name="password"
                                        label="Password"
                                        v-model="data.password"
                                        :error="errors?.password"
                                    />
                                </div>
                                <div class="text-center">
                                    <Button
                                        intent="primary"
                                        size="lg"
                                        type="submit"
                                        :disabled="isSubmitting"
                                    >
                                        Sign In
                                    </Button>
                                </div>
                                <div class="text-center text-sm">
                                    <p>
                                        <a class="cta" href="/redeem">
                                            Redeem access code</a
                                        >
                                    </p>
                                    <p class="mt-3">
                                        Don't have an account?
                                        <a href="/signup-new" class="cta"
                                            >Register Now</a
                                        >
                                        for a 7-day free trial.
                                    </p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="text-center text-sm space-x-4">
                    <a href="/forgot-password" class="cta text-blue-800"
                        >Forgot your password?</a
                    >
                    <a
                        href="https://help.cloverlearning.com"
                        class="cta text-blue-800"
                        >Help</a
                    >
                </div>
            </div>
        </div>
    </div>
    <div class="w-full fixed bottom-0 left-0 right-0 z-0">
        <WavesBg></WavesBg>
    </div>
</template>
