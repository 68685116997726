<script setup lang="ts">
import DotProgressIndicator from "@/components/ui/progress/DotProgressIndicator.vue";
import Button from "@/components/ui/ButtonLinks/Button.vue";
import { computed, onMounted, ref, defineProps } from "vue";
import { TransitionRoot } from "@headlessui/vue";
import {
    OnboardingSurveyData,
    OnboardingSurveyDataKeys,
    createOnboardingSurvey,
} from "@/api/register";
import { wait } from "@/utils";
import { updateOnboardingSurvey } from "@/api/register";
import Question from "@/components/ui/quiz/Question.vue";
import QuestionOption from "@/components/ui/quiz/QuestionOption.vue";
import StyledOption from "@/components/ui/quiz/StyledOption.vue";
import CloverFullLogo from "@/components/ui/images/CloverFullLogo.vue";
import { ArrowRightIcon } from "@heroicons/vue/20/solid";

const inputRef = ref<HTMLInputElement | null>(null);

const props = defineProps<{
    name: string;
}>();

import { useBuyNow, useBuyNowLink } from "../hooks";
const buyNow = useBuyNow();
const buyNowLink = useBuyNowLink();

type Question = {
    type: "multiple" | "single" | "freeform";
    key: OnboardingSurveyDataKeys;
    question: string;
    options?: string[];
    optional?: boolean;
};

const answers = ref<OnboardingSurveyData>({
    self_identified_as: "",
    institution: "",
    using_for: "",
    interested_in_topic: [] as string[],
    how_did_you_hear: "",
});

const questions = ref<Question[]>([
    {
        type: "single",
        key: "self_identified_as",
        question: "What best describes you?",
        options: [
            "Student",
            "Technologist",
            "Educator",
            "Medical Student (PA, NP, ARNP)",
            "Other",
        ],
    },
    {
        type: "freeform",
        key: "institution",
        question: "Please provide the name of your school?",
        optional: true,
    },
    {
        type: "freeform",
        key: "institution",
        question: "Please provide the name of your employer or institution?",
        optional: true,
    },
    {
        type: "single",
        key: "using_for",
        question: "How do you plan on using the Clover Learning Platform?",
        options: [
            "Training or review",
            "For certification exam prep",
            "For CE or SE",
            "Other",
        ],
    },
    {
        type: "multiple",
        key: "interested_in_topic",
        question: "What are you most interested in?",
        options: [
            "Radiography",
            "Computed Tomography",
            "Fluoroscopy",
            "Other Imaging Modalities",
        ],
    },
    {
        type: "single",
        key: "how_did_you_hear",
        question: "How did you first hear about Clover Learning?",
        options: [
            "Someone told me about you all",
            "Internet (Google, Bing, etc.)",
            "Social Media",
            "YouTube",
            "Other",
        ],
    },
]);

const currentSelectedAnswer = ref<string[]>([]);
const currentTextAnswer = ref<string>("");

const currentQuestionIndex = ref(0);
const currentQuestion = computed(
    () => questions.value[currentQuestionIndex.value]
);
const showQuestion = ref(false);

const next = async () => {
    showQuestion.value = false;
    const previousAnswer = await submitAnswerForCurrentQuestion();
    if (currentQuestionIndex.value === 0) {
        if (
            previousAnswer === "Student" ||
            previousAnswer === "Educator" ||
            previousAnswer === "Medical Student (PA, NP, ARNP)"
        ) {
            currentQuestionIndex.value++;
        }
        if (previousAnswer === "Technologist") {
            currentQuestionIndex.value += 2;
        }
        if (previousAnswer === "Other") {
            currentQuestionIndex.value += 3;
        }
    } else if (currentQuestionIndex.value === 1) {
        currentQuestionIndex.value += 2;
    } else if (currentQuestionIndex.value === questions.value.length - 1) {
        if (buyNow.value) {
            window.location.replace(buyNowLink.value);
        } else {
            window.location.replace("/");
        }
    } else {
        currentQuestionIndex.value++;
    }

    await wait(400);
    showQuestion.value = true;
    inputRef.value?.focus();
};

onMounted(async () => {
    await createOnboardingSurvey({});
    showQuestion.value = true;
});

const submitAnswerForCurrentQuestion = async () => {
    const key = currentQuestion.value.key;
    try {
        if (currentQuestion.value.type === "freeform") {
            await updateOnboardingSurvey(key, currentTextAnswer.value);
        }
        if (currentQuestion.value.type === "multiple") {
            await updateOnboardingSurvey(key, currentSelectedAnswer.value);
        }
        if (currentQuestion.value.type === "single") {
            await updateOnboardingSurvey(
                key,
                currentSelectedAnswer.value?.[0] ?? ""
            );
        }
    } catch (e) {
    } finally {
        // clear
        let tempAnswer = currentSelectedAnswer.value[0] ?? "";
        currentSelectedAnswer.value = [];
        currentTextAnswer.value = "";
        return tempAnswer;
    }
};

const buttonText = computed(() => {
    if (currentQuestionIndex.value === questions.value.length - 1) {
        return "Finish";
    }
    return "Next";
});
</script>
<template>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 z-10 relative pb-24">
        <div
            class="flex flex-col md:flex-row items-start justify-center md:space-x-12 pt-8 md:pt-16"
        >
            <div class="space-y-6 mt-4 md:mt-0 w-full md:w-1/2">
                <div>
                    <CloverFullLogo />
                </div>

                <div class="bg-white rounded-md shadow p-8 md:p-12 mb-1">
                    <div class="text-center mb-12">
                        <h2
                            class="text-2xl md:text-4xl font-bold tracking-tight"
                        >
                            Welcome, {{ props.name }}!
                        </h2>
                        <p class="mt-2">
                            Tell us a little more about yourself.
                        </p>
                    </div>
                    <template v-if="currentQuestion.type === 'freeform'">
                        <div class="text-xl font-semibold mb-4">
                            {{ currentQuestion.question }}
                        </div>
                        <div>
                            <input
                                type="text"
                                v-model="currentTextAnswer"
                                ref="inputRef"
                            />
                        </div>
                    </template>
                    <template v-else>
                        <Question
                            v-model="currentSelectedAnswer"
                            :multi-select="currentQuestion.type === 'multiple'"
                        >
                            <div class="text-xl font-semibold mb-4">
                                {{ currentQuestion.question }}
                            </div>
                            <QuestionOption
                                :value="option"
                                v-slot="{ selected, events }"
                                v-for="option in currentQuestion.options"
                                :key="option + currentQuestionIndex"
                            >
                                <StyledOption
                                    :selected="selected"
                                    :text="option"
                                    :type="
                                        currentQuestion.type === 'multiple'
                                            ? 'checkbox'
                                            : 'radio'
                                    "
                                    status="unanswered"
                                    :toggle="events.toggle"
                                    :inputRef="inputRef"
                                >
                                </StyledOption>
                            </QuestionOption>
                        </Question>
                    </template>
                    <div class="flex flex-col justify-center mt-4">
                        <Button class="self-end" @click="next"
                            >{{ buttonText }}
                            <ArrowRightIcon class="ml-2 w-4 h-4"
                        /></Button>
                        <DotProgressIndicator
                            class="mt-10"
                            :currentStep="currentQuestionIndex"
                            :totalSteps="questions.length"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="w-full fixed bottom-0 left-0 right-0 z-0">
        <WavesBg></WavesBg>
    </div>
</template>
