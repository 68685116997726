<script setup lang="ts">
import { defineProps, onMounted, onUnmounted, ref } from "vue";
import { useQuestionContext } from "./QuestionContext";
import { v4 as uuid } from "uuid";

const props = withDefaults(
    defineProps<{
        as?: string;
        value: string;
    }>(),
    {
        as: "div",
    }
);

const api = useQuestionContext("QuestionOption");

const onClick = () => {
    toggle();
};

const toggle = () => {
    console.log(`toggle: ${id}`);
    api.toggleById(id);
};

let optionRef = ref<HTMLElement | null>(null);
const selected = ref(false);
const active = ref(false);
const id = uuid();

onMounted(() => {
    api.registerOption({
        id: id,
        value: props.value,
        selected,
        active,
        element: optionRef,
    });
});

onUnmounted(() => {
    api.unregisterOption(id);
});

const onChange = (value: string) => {
    alert(`onChange: ${value}`);
};
const events = {
    toggle,
};
</script>
<template>
    <component :is="as" :ref="optionRef">
        <slot :selected="selected" :active="active" :events="events"></slot>
    </component>
</template>
