<script setup lang="ts">
import { debounce } from "lodash";
import {
    MagnifyingGlassIcon,
    ChevronDownIcon,
} from "@heroicons/vue/24/outline";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { onMounted } from "vue";
import { useCoursesStore } from "@/stores/coursesStore";
import { storeToRefs } from "pinia";

const coursesStore = useCoursesStore();
const {
    categories,
    disciplines,
    ceFilters,
    cqrFilters,
    structuredEdFilters,
    categoryFilters,
    completedFilter,
    startedFilter,
    notStartedFilter,
} = storeToRefs(coursesStore);

const onSearchChange = (e: Event) => {};

const catKeyToFilter = {
    ce: ceFilters,
    cqr: cqrFilters,
    structured_ed: structuredEdFilters,
    category: categoryFilters,
};

// On category checked we want to add it to the query params like ?category=fluoroscopy
const onCategoryChange = (category: string, key: string) => {
    console.log(`category: ${category}, key: ${key}`);
    let filter = catKeyToFilter[key];

    if (filter.value.includes(category)) {
        filter.value = filter.value.filter((c) => c !== category);
    } else {
        filter.value.push(category);
    }
};

const isCategoryChecked = (category: string, key: string) => {
    if (key === "category") {
        return ceFilters.value.includes(category);
    }
    return false;
};
</script>

<template>
    <div
        class="flex w-full flex-col rounded-md bg-slate-900 text-white"
        data-cl-container="course-filters"
    >
        <h2 class="hidden px-5 py-4 text-lg font-bold lg:block">
            Filter Courses
        </h2>
        <hr md:hidden class="border-slate-800" />

        <div class="flex flex-col">
            <Disclosure
                :default-open="true"
                class="p-5"
                v-slot="{ open }"
                :as="'div'"
            >
                <DisclosureButton
                    class="flex w-full items-center justify-between text-left"
                >
                    <h3 class="mb-2 font-bold">Status</h3>
                    <span class="ml-6 flex h-7 items-center">
                        <ChevronDownIcon
                            class="h-6 w-6 transition-all"
                            :class="open && 'rotate-180'"
                        ></ChevronDownIcon>
                    </span>
                </DisclosureButton>
                <DisclosurePanel
                    class="space-y-2 py-4 text-sm"
                    :class="open && 'rounded-b-md'"
                >
                    <div class="flex gap-2" data-cl-event="course-filter-click">
                        <input
                            class="relative top-0.5 h-4 w-4 rounded-sm"
                            type="checkbox"
                            :id="`status-input-started`"
                            :name="'started'"
                            v-model="startedFilter"
                        />
                        <label
                            :for="`status-input-started`"
                            class="text-gray-200"
                        >
                            Started
                        </label>
                    </div>
                    <!-- repeat above for Not Started and Completed-->
                    <div class="flex gap-2" data-cl-event="course-filter-click">
                        <input
                            class="relative top-0.5 h-4 w-4 rounded-sm"
                            type="checkbox"
                            :id="`status-input-not-started`"
                            :name="'not-started'"
                            v-model="notStartedFilter"
                        />
                        <label
                            :for="`status-input-not-started`"
                            class="text-gray-200"
                        >
                            Not Started
                        </label>
                    </div>
                    <div class="flex gap-2" data-cl-event="course-filter-click">
                        <input
                            class="relative top-0.5 h-4 w-4 rounded-sm"
                            type="checkbox"
                            :id="`status-input-completed`"
                            v-model="completedFilter"
                            :name="'completed'"
                        />
                        <label
                            :for="`status-input-completed`"
                            class="text-gray-200"
                        >
                            Completed
                        </label>
                    </div>
                </DisclosurePanel>
            </Disclosure>

            <hr md:hidden class="border-slate-800" />
            <Disclosure
                :default-open="true"
                class="p-5"
                v-slot="{ open }"
                :as="'div'"
            >
                <DisclosureButton
                    class="flex w-full items-center justify-between text-left"
                >
                    <h3 class="mb-2 font-bold">Category</h3>
                    <span class="ml-6 flex h-7 items-center">
                        <ChevronDownIcon
                            class="h-6 w-6 transition-all"
                            :class="open && 'rotate-180'"
                        ></ChevronDownIcon>
                    </span>
                </DisclosureButton>
                <DisclosurePanel
                    class="space-y-2 py-4 text-sm"
                    :class="open && 'rounded-b-md'"
                >
                    <div class="flex gap-2" v-for="cat in categories" data-cl-event="course-filter-click">
                        <input
                            class="relative top-0.5 h-4 w-4 rounded-sm"
                            type="checkbox"
                            :id="`cat-input-${cat.slug}`"
                            :name="cat.slug"
                            :value="cat.slug"
                            :checked="isCategoryChecked(cat.slug, 'category')"
                            @change="onCategoryChange(cat.slug, 'category')"
                        />
                        <label
                            :for="`cat-input-${cat.slug}`"
                            class="text-gray-200"
                            >{{ ` ${cat.name}` }}</label
                        >
                    </div>
                </DisclosurePanel>
            </Disclosure>

            <hr md:hidden class="border-slate-800" />

            <Disclosure class="p-5" v-slot="{ open }" :as="'div'">
                <DisclosureButton
                    class="flex w-full items-center justify-between text-left"
                >
                    <h3 class="mb-2 font-bold">Continuing Education</h3>
                    <span class="ml-6 flex h-7 items-center">
                        <ChevronDownIcon
                            class="h-6 w-6 transition-all"
                            :class="open && 'rotate-180'"
                        ></ChevronDownIcon>
                    </span>
                </DisclosureButton>
                <DisclosurePanel
                    class="space-y-2 py-4 text-sm"
                    :class="open && 'rounded-b-md'"
                >
                    <div class="flex gap-2" v-for="d in disciplines" data-cl-event="course-filter-click">
                        <input
                            class="relative top-0.5 h-4 w-4 rounded-sm"
                            type="checkbox"
                            :id="`ce-${d.slug}`"
                            :name="`ce-${d.slug}`"
                            :value="d.slug"
                            :checked="isCategoryChecked(d.slug, 'ce')"
                            @change="onCategoryChange(d.slug, 'ce')"
                        />
                        <label :for="`ce-${d.slug}`" class="text-gray-200">{{
                            d.name
                        }}</label>
                    </div>
                </DisclosurePanel>
            </Disclosure>

            <hr md:hidden class="border-slate-800" />

            <Disclosure class="p-5" v-slot="{ open }" :as="'div'">
                <DisclosureButton
                    class="flex w-full items-center justify-between text-left"
                >
                    <h3 class="mb-2 font-bold">
                        Structured Education Disciplines
                    </h3>
                    <span class="ml-6 flex h-7 items-center">
                        <ChevronDownIcon
                            class="h-6 w-6 transition-all"
                            :class="open && 'rotate-180'"
                        ></ChevronDownIcon>
                    </span>
                </DisclosureButton>
                <DisclosurePanel
                    class="space-y-2 py-4 text-sm"
                    :class="open && 'rounded-b-md'"
                >
                    <div class="flex gap-2" v-for="d in disciplines" data-cl-event="course-filter-click">
                        <input
                            class="relative top-0.5 h-4 w-4 rounded-sm"
                            type="checkbox"
                            :id="`structured-${d.slug}`"
                            :name="`structured-${d.slug}`"
                            :value="d.slug"
                            :checked="
                                isCategoryChecked(d.slug, 'structured_ed')
                            "
                            @change="onCategoryChange(d.slug, 'structured_ed')"
                        />
                        <label
                            :for="`structured-${d.slug}`"
                            class="text-gray-200"
                            >{{ d.name }}</label
                        >
                    </div>
                </DisclosurePanel>
            </Disclosure>

            <hr md:hidden class="border-slate-800" />

            <Disclosure class="p-5" v-slot="{ open }" :as="'div'">
                <DisclosureButton
                    class="flex w-full items-center justify-between text-left"
                >
                    <h3 class="mb-2 font-bold">CQR Disciplines</h3>
                    <span class="ml-6 flex h-7 items-center">
                        <ChevronDownIcon
                            class="h-6 w-6 transition-all"
                            :class="open && 'rotate-180'"
                        ></ChevronDownIcon>
                    </span>
                </DisclosureButton>
                <DisclosurePanel
                    class="space-y-2 py-4 text-sm"
                    :class="open && 'rounded-b-md'"
                >
                    <div class="flex gap-2" v-for="d in disciplines" data-cl-event="course-filter-click">
                        <input
                            class="relative top-0.5 h-4 w-4 rounded-sm"
                            type="checkbox"
                            :id="`cqr-${d.slug}`"
                            :name="`cqr-${d.slug}`"
                            :value="d.slug"
                            :checked="isCategoryChecked(d.slug, 'cqr')"
                            @change="onCategoryChange(d.slug, 'cqr')"
                        />
                        <label :for="`cqr-${d.slug}`" class="text-gray-200">{{
                            d.name
                        }}</label>
                    </div>
                </DisclosurePanel>
            </Disclosure>
        </div>
    </div>
</template>
