<template>
    <Menu as="div" class="relative">
        <MenuButton
            v-tippy="{
                delay: [1000, 0],
                placement: 'left',
            }"
            class="flex items-center justify-center rounded-full h-9 w-9 focus-visible:ring"
            :class="
                impersonating
                    ? 'bg-red-600 text-white'
                    : 'bg-slate-900 text-white'
            "
            ref="tip"
            :content="label"
            type="button"
        >
            <img
                v-if="avatar"
                class="rounded-full w-full h-full"
                :src="avatar"
                :alt="initials"
            />
            <span class="text-sm" v-else>
                {{ initials }}
            </span>
        </MenuButton>
        <MenuItems
            class="absolute top-0 right-0 mt-10 z-10 min-w-full flex flex-col bg-white rounded shadow"
        >
            <MenuItem
                v-if="loaded && user.is_in_trial"
                class="border-b border-gray-100 last:border-none first:rounded-t last:rounded-b"
                v-slot="{ active }"
            >
                <a
                    :href="pricingUrl"
                    :class="{
                        'flex gap-3 items-center whitespace-nowrap p-4 pr-12 bg-blue-50 focus-visible:ring hover:no-underline':
                            active,
                        'hover:no-underline flex gap-3 items-center whitespace-nowrap p-4 pr-12':
                            !active,
                    }"
                >
                    <SvgIcon icon="add-square" size="base" weight="medium" />
                    Upgrade Now
                </a>
            </MenuItem>
            <MenuItem
                class="border-b border-gray-100 last:border-none first:rounded-t last:rounded-b"
                v-slot="{ active }"
            >
                <a
                    href="/account/profile"
                    :class="{
                        'flex gap-3 items-center whitespace-nowrap p-4 pr-12 bg-blue-50 focus-visible:ring hover:no-underline':
                            active,
                        'hover:no-underline flex gap-3 items-center whitespace-nowrap p-4 pr-12':
                            !active,
                    }"
                >
                    <SvgIcon icon="user" size="base" weight="medium" />
                    Profile
                </a>
            </MenuItem>
            <MenuItem
                class="border-b border-gray-100 last:border-none first:rounded-t last:rounded-b"
                v-slot="{ active }"
            >
                <a
                    href="/account/manage-data-access"
                    :class="{
                        'flex gap-3 items-center whitespace-nowrap p-4 pr-12 bg-blue-50 focus-visible:ring hover:no-underline':
                            active,
                        'hover:no-underline flex gap-3 items-center whitespace-nowrap p-4 pr-12':
                            !active,
                    }"
                >
                    <SvgIcon icon="safe" size="base" weight="medium" />
                    Manage data access
                </a>
            </MenuItem>
            <MenuItem
                class="border-b border-gray-100 last:border-none first:rounded-t last:rounded-b"
                v-slot="{ active }"
            >
                <a
                    href="/account/certifications"
                    :class="{
                        'flex gap-3 items-center whitespace-nowrap p-4 pr-12 bg-blue-50 focus-visible:ring hover:no-underline':
                            active,
                        'hover:no-underline flex gap-3 items-center whitespace-nowrap p-4 pr-12':
                            !active,
                    }"
                >
                    <SvgIcon icon="certificate" size="base" weight="medium" />
                    Certifications
                </a>
            </MenuItem>
            <MenuItem
                v-if="user.stripe_id !== null"
                class="border-b border-gray-100 last:border-none first:rounded-t last:rounded-b"
                v-slot="{ active }"
            >
                <a
                    href="/account/billing"
                    :class="{
                        'flex gap-3 items-center whitespace-nowrap p-4 pr-12 bg-blue-50 focus-visible:ring hover:no-underline':
                            active,
                        'hover:no-underline flex gap-3 items-center whitespace-nowrap p-4 pr-12':
                            !active,
                    }"
                >
                    <SvgIcon icon="payment" size="base" weight="medium" />
                    Billing &amp; Plan
                </a>
            </MenuItem>
            <MenuItem
                v-if="impersonating"
                class="border-b border-gray-100 last:border-none first:rounded-t last:rounded-b"
                v-slot="{ active }"
            >
                <a
                    href="/unimpersonate"
                    :class="{
                        'flex gap-3 items-center whitespace-nowrap p-4 pr-12 bg-blue-50 focus-visible:ring hover:no-underline':
                            active,
                        'hover:no-underline flex gap-3 items-center whitespace-nowrap p-4 pr-12':
                            !active,
                    }"
                >
                    <SvgIcon icon="logout" size="base" weight="medium" />
                    Exit Impersonation
                </a>
            </MenuItem>
            <MenuItem
                class="border-b border-gray-100 last:border-none first:rounded-t last:rounded-b"
                v-slot="{ active }"
            >
                <a
                    href="/logout"
                    :class="{
                        'flex gap-3 items-center whitespace-nowrap p-4 pr-12 bg-blue-50 focus-visible:ring hover:no-underline':
                            active,
                        'hover:no-underline flex gap-3 items-center whitespace-nowrap p-4 pr-12':
                            !active,
                    }"
                >
                    <SvgIcon icon="logout" size="base" weight="medium" />
                    Logout
                </a>
            </MenuItem>
        </MenuItems>
    </Menu>
</template>

<script lang="ts">
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import { mapState } from "vuex";
import { useUserStore } from "@/stores/userStore";
import { storeToRefs } from "pinia";
import { computed, watch, defineComponent } from "vue";
import { User } from "@/types/user";
import { pricingUrl } from "@/utils";

export default defineComponent({
    setup: () => {
        const userStore = useUserStore();
        const { userObj, loaded } = storeToRefs(userStore);
        const user = computed(() => userObj.value as User);
        return { user, loaded, pricingUrl };
    },
    props: {
        avatar: String,
        icon: String,
        initials: String,
        label: String,
        theme: String,
        showBilling: Boolean,
        impersonating: Boolean,
    },
    methods: {
        openSupportWidget() {
            zE("messenger", "open");
        },
    },
    components: {
        Menu,
        MenuButton,
        MenuItems,
        MenuItem,
    },
    computed: {
        ...mapState(["user"]),
    },
});
</script>
