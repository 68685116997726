<template>
    <div
        class="hover:bg-gray-100 border-b last:border-b-0 whitespace-nowrap first:rounded-t last:rounded-b"
    >
        <button
            v-if="type == 'button'"
            type="button"
            class="flex items-center cursor-default space-x-3 p-4 min-w-36"
            @click="clickAction"
        >
            <SvgIcon :class="iconColor" :icon="icon" size="base" />
            <span class="text-sm">{{ label }}</span>
        </button>
        <button
            v-if="type == 'form'"
            class="flex items-center cursor-default space-x-3 p-4"
            type="button"
            @click="clickAction"
        >
            <SvgIcon :class="iconColor" :icon="icon" size="base" />
            <span class="text-sm">{{ label }}</span>
        </button>
        <div v-if="type == 'submit'">
            <button
                type="button"
                class="flex items-center cursor-default space-x-3 p-4 min-w-36"
                @click="clickAction"
            >
                <SvgIcon :class="iconColor" :icon="icon" size="base" />
                <span class="ml-3 text-sm">{{ label }}</span>
            </button>
        </div>
        <a
            v-if="type == 'link'"
            class="flex items-center hover:no-underline cursor-default space-x-3 p-4"
            :href="action"
            :target="target"
            @click="clickAction"
        >
            <SvgIcon :class="iconColor" :icon="icon" size="base" />
            <span class="text-sm">{{ label }}</span>
        </a>
        <ConfirmDeleteModal
            class="normal-case"
            v-if="useModal == 'true'"
            :active="showDelete"
            :message="message"
            @close="closeDelete"
            @confirm="confirmDelete"
        />
    </div>
</template>

<script lang="ts">
import ConfirmDeleteModal from "./modals/ConfirmDeleteModal.vue";

export default {
    components: { ConfirmDeleteModal },
    props: {
        action: [Function, String],
        argument: [String, Number, Boolean, Object],
        form: String,
        icon: String,
        iconColor: String,
        label: String,
        target: {
            type: String,
            default: "_blank",
        },
        type: String,
        useModal: String,
        message: {
            type: String,
            default: "Are you sure you want to delete this?",
        },
    },
    data(): any {
        return {
            showDelete: false,
        };
    },
    methods: {
        submitForm() {
            (document.getElementById(this.form) as HTMLFormElement)?.submit();
        },
        closeDelete() {
            this.showDelete = false;
            this.$parent.$parent.$emit("hide");
        },
        confirmDelete() {
            if (this.type === "form" || this.type === "submit") {
                this.submitForm();
            } else if (this.type === "link") {
                window.location.assign(this.action);
            }
            this.$parent.$parent.$emit("hide"); //the components are linked so this is admissible
            this.showDelete = false;
        },
        clickAction(event) {
            if (this.type === "button") {
                this.action(this.argument);
                this.$parent.$emit("hide");
                this.$parent.$parent.$emit("hide");
            }

            if (this.type === "form") {
                if (this.useModal === "true") {
                    this.showDelete = true;
                } else {
                    this.submitForm();
                    this.$parent.$emit("hide");
                }
            }

            if (this.type === "link") {
                this.$parent.$emit("hide");
            }

            if (this.type === "submit") {
                if (this.useModal === "true") {
                    this.showDelete = true;
                } else {
                    this.submitForm();
                    this.$parent.$emit("hide");
                }
            }
        },
    },
};
</script>
