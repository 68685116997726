<template>
    <div class="flex items-center text-sm">
        <div class="whitespace-nowrap">
            Showing {{ rangeLow }} - {{ rangeHigh }} of {{ total }}
        </div>
        <button
            class="flex items-center justify-center rounded-full w-6 h-6 ml-3 mr-1"
            :class="{
                'bg-gray-200 text-gray-400 cursor-not-allowed': page == 1,
                'bg-gray-300 text-gray-800': page != 1,
            }"
            :disabled="page == 1"
            @click="prevPage"
        >
            <SvgIcon icon="arrow-left" size="xs" weight="bold" />
        </button>
        <button
            class="flex items-center justify-center rounded-full w-6 h-6"
            :class="{
                'bg-gray-200 text-gray-400 cursor-not-allowed': page == pages,
                'bg-gray-300 text-gray-800': page != pages,
            }"
            :disabled="page == pages"
            @click="nextPage"
        >
            <SvgIcon icon="arrow-right" size="xs" weight="bold" />
        </button>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        modelValue: Number,
        total: Number,
        perPage: [Number, String],
    },
    data(): any {
        return {
            page: null,
        };
    },
    computed: {
        perPageNumber() {
            return Number(this.perPage);
        },
        pages() {
            return Math.ceil(this.total / this.perPageNumber);
        },
        rangeLow() {
            return this.perPageNumber * (this.page - 1) + 1;
        },
        rangeHigh() {
            return Math.min(this.perPageNumber * this.page, this.total);
        },
    },
    methods: {
        nextPage() {
            this.page++;
            this.$emit("update:modelValue", this.page);
        },
        prevPage() {
            this.page--;
            this.$emit("update:modelValue", this.page);
        },
    },
    mounted() {
        this.page = this.modelValue;
    },
    watch: {
        modelValue(value) {
            this.page = value;
        },
    },
});
</script>
