import { BillingAddress } from "./checkoutStore";
import { FrequencyKeys, PlanKeys } from "./types";
import { backend, fail, responseMatch, succeedText } from "@/api/utils";

interface LoginResponse {
    authenticated: boolean;
    user: User;
}

export interface User {
    email: string;
    first_name: string;
    last_name: string;
    full_name: string;
}

const baseApiUrl = "/api";

export const checkoutApi = {
    getCart: async (cartUuid?: string) => {
        let url = `${baseApiUrl}/cart`;
        if (cartUuid) {
            url += "?uuid=" + cartUuid;
        }
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        const json = await response.json();
        return json?.data;
    },
    setProductInCart(product: string, quantity: number) {
        return fetch(`${baseApiUrl}/cart/add`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({ product, quantity }),
        });
    },
    removeProduct(productId: number) {
        return fetch(`${baseApiUrl}/cart/remove/${productId}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({}),
        });
    },
    getLoginStatus: async () => {
        const response = await fetch(`${baseApiUrl}/auth-status`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        return (await response.json()) as LoginResponse;
    },
    async getBillingInfo() {
        const response = await fetch(`${baseApiUrl}/cart/billing-info`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        return await response.json();
    },
    async getCheckoutEstimate(
        address: any,
        coupon: any,
        planKey: PlanKeys,
        frequencyKey: FrequencyKeys
    ) {
        const response = await fetch(
            `${baseApiUrl}/cart/new/checkout-estimate`,
            {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify({
                    planKey,
                    frequencyKey,
                    address,
                    coupon,
                }),
            }
        );
        return await response.json();
    },
    async applyCoupon(coupon: any) {
        const response = await fetch(`${baseApiUrl}/cart/coupon/${coupon}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        return await response.json();
    },
    async checkout(
        paymentMethod: string,
        billingAddress: BillingAddress,
        plan: PlanKeys,
        frequency: FrequencyKeys,
        coupon?: string | null
    ) {
        const response = await fetch(`${baseApiUrl}/cart/new/checkout`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                plan,
                frequency,
                payment_method: paymentMethod,
                billing_address: billingAddress,
                coupon_id: coupon,
            }),
        });
        return {
            code: response.status,
            data: await response.json(),
        };
    },
    async updateBillingInfo(billingInfo: any) {
        // use fetch
        const response = await fetch(`${baseApiUrl}/cart/billing-info`, {
            method: "POST",
            body: JSON.stringify(billingInfo),
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        return await response.json();
    },
    async getConfirmation() {
        const response = await fetch(`${baseApiUrl}/cart/confirmation`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        return await response.json();
    },

    async logCheckoutStarted() {
        const response = await fetch(`${baseApiUrl}/cart/checkout-started`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        return await response.json();
    },

    async upgradePlan(frequency: FrequencyKeys) {
        const response = await fetch(`${baseApiUrl}/cart/new/upgrade-plan`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({ frequency }),
        });
        return await response.json();
    },

    async downGradePlan(frequency: FrequencyKeys) {
        const response = await fetch(`${baseApiUrl}/cart/new/downgrade-plan`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({ frequency }),
        });
        return await response.json();
    },

    async changeFrequency(frequency: FrequencyKeys) {
        const response = await fetch(
            `${baseApiUrl}/cart/new/change-frequency`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify({ frequency }),
            }
        );
        return await response.json();
    },

    async cancelSubscriptionChange() {
        const response = await fetch(
            `${baseApiUrl}/cart/new/cancel-subscription-change`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            }
        );
        return await response.json();
    },

    async getChangePreview(
        plan?: PlanKeys,
        frequency?: FrequencyKeys,
        from: string | null = null
    ) {
        const params = new URLSearchParams();
        if (plan) {
            params.append("plan", plan);
        }
        if (frequency) {
            params.append("frequency", frequency);
        }
        if (from) {
            params.append("from", from);
        }
        const response = await fetch(
            `${baseApiUrl}/cart/new/preview-change?${params}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            }
        );
        return await response.json();
    },

    async upgradeFromLegacy(plan: PlanKeys, frequency: FrequencyKeys) {
        const response = await fetch(
            `${baseApiUrl}/cart/new/upgrade-from-legacy`,
            {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify({
                    plan,
                    frequency,
                }),
            }
        );
        return {
            code: response.status,
            data: await response.json(),
        };
    },
};

export async function logCheckoutStarted(plan: string, frequency: string) {
    const response = await backend.post(
        `/api/cart/new/checkout-started?plan=${plan}&frequency=${frequency}`,
        {},
        {}
    );
    let temp = await responseMatch(response, {
        302: succeedText,
        "200s": succeedText,
        "300s": succeedText,
        "500s": fail,
        default: fail,
    });
    return temp;
}
