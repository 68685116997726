<template>
    <div class="relative question-row" :class="question.status">
        <div class="flex items-center">
            <div class="relative w-2/3 p-8">
                <div
                    class="absolute top-0 right-0 flex items-center bg-red-600 text-xs text-red-50 font-medium uppercase rounded-bl p-4 z-10"
                    v-if="question.flagged"
                >
                    <SvgIcon
                        class="text-red-50 mr-2"
                        icon="flag"
                        size="sm"
                        weight="bold"
                    />
                    <div>Flagged</div>
                </div>
                <div class="question pr-24">
                    <div v-html="question.question"></div>
                </div>
                <div
                    class="answers bg-gray-700 text-gray-50 rounded-md py-2 px-4"
                >
                    <div
                        class="flex items-center my-2"
                        v-for="answer in question.answers"
                    >
                        <div class="flex-shrink-0 w-5 mr-2">
                            <SvgIcon
                                v-if="
                                    answer.checked && question.multiple_response
                                "
                                icon="square-checked"
                                size="base"
                            />
                            <SvgIcon
                                v-if="
                                    answer.checked &&
                                    !question.multiple_response
                                "
                                icon="radio-button"
                                size="base"
                            />
                            <SvgIcon
                                v-if="
                                    !answer.checked &&
                                    question.multiple_response
                                "
                                icon="square"
                                size="base"
                            />
                            <SvgIcon
                                v-if="
                                    !answer.checked &&
                                    !question.multiple_response
                                "
                                icon="circle"
                                size="base"
                            />
                        </div>
                        <div class="flex-grow" v-html="answer.answer"></div>
                    </div>
                </div>
                <div
                    class="feedback bg-gray-100 border border-gray-200 text-sm p-4 mt-4 rounded-md"
                    v-if="question.feedback"
                >
                    <h4 class="font-medium mb-2">Feedback</h4>
                    <div class="space-y-4" v-html="question.feedback"></div>
                </div>
            </div>
            <div
                class="absolute top-0 right-0 bg-gray-50 border-l border-gray-100 rounded-r-md w-1/3 h-full p-8 pr-28"
            >
                <div class="category mb-8">
                    <h4 class="text-sm font-medium uppercase mb-2">Category</h4>
                    <div class="bg-white rounded-md shadow-inner p-4">
                        <PrepCategoryBadge
                            v-for="category in question.categories"
                            :category="category"
                            :key="category.uuid + '|' + question.uuid"
                            :show-bank="true"
                        />
                    </div>
                </div>
                <div
                    class="text-sm"
                    v-if="question && question.history.length > 0"
                >
                    <div>
                        Edited
                        <span
                            class="border-b border-dotted border-gray-800"
                            v-tippy
                            :content="
                                $filters.dateTime(
                                    question.history[0].created_at,
                                    user.timezone
                                )
                            "
                            v-text="
                                $filters.relative(
                                    question.history[0].created_at,
                                    user.timezone
                                )
                            "
                        />
                        by
                        <strong class="font-medium">{{
                            question.history[0].user.first_name
                        }}</strong>
                    </div>
                    <div
                        class="inline-block bg-white border-l-4 border-cyan-400 leading-5 rounded shadow mt-2 py-2 px-4 overflow-y-auto"
                        style="max-height: 100px"
                        v-if="question.history && question.history[0].note"
                    >
                        <div v-html="question.history[0].note"></div>
                    </div>
                </div>
                <div
                    class="absolute bg-white rounded-r-md border-l border-gray-200 top-0 right-0 flex flex-col items-center justify-start w-20 py-4 space-y-3 h-full"
                >
                    <button
                        class="flex items-center justify-center bg-transparent transition duration-500 hover:bg-green-50 text-green-700 border border-green-400 rounded-full shadow w-12 h-12"
                        type="button"
                        content="Approve"
                        v-tippy="{ placement: 'left' }"
                        @click="approve(question)"
                    >
                        <LoadingSpinner placement="button" :show="approving" />
                        <SvgIcon v-if="!approving" icon="like" size="sm" />
                    </button>
                    <button
                        class="flex items-center justify-center bg-transparent transition duration-500 hover:bg-yellow-50 text-yellow-700 border border-yellow-400 rounded-full shadow w-12 h-12"
                        type="button"
                        content="Quick edit"
                        v-tippy="{ placement: 'left' }"
                        @click="edit(question)"
                    >
                        <SvgIcon icon="lightning" size="sm" weight="medium" />
                    </button>
                    <a
                        class="flex items-center justify-center bg-transparent transition duration-500 hover:bg-blue-50 text-blue-700 border border-blue-400 rounded-full shadow w-12 h-12"
                        :href="
                            '/admin/content/' +
                            questionType +
                            '/questions/' +
                            question.uuid
                        "
                        content="Edit"
                        v-tippy="{ placement: 'left' }"
                    >
                        <SvgIcon icon="edit" size="sm" weight="medium" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import axios from "axios";
import { mapState } from "vuex";

export default {
    props: {
        question: Object,
        questionType: {
            type: String,
            default: "prep",
        },
    },
    data(): any {
        return {
            approving: false,
        };
    },
    computed: {
        ...mapState(["user"]),
    },
    methods: {
        approve(question) {
            if (!this.approving) {
                this.approving = true;

                axios
                    .post("/api/" + this.questionType + "/questions/approve", {
                        question: question.uuid,
                    })
                    .then((response) => {
                        this.approving = false;
                        this.$events.$emit("questionApproved", response.data);
                    });
            }
        },
        edit(question) {
            this.$events.$emit(
                "editQuestion",
                JSON.parse(JSON.stringify(question))
            );
        },
        remove(question) {
            this.$events.$emit("deleteQuestion", question);
        },
    },
};
</script>
