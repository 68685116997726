/**
 * Ideally we would not need these imports.
 * Except for the ones used in blade templates. But again we should be getting rid of those too.
 */
import { Plugin } from "vue";
import ActionButton from "./components/_elements/ActionButton.vue";
import ActionLink from "./components/_elements/ActionLink.vue";
import ActionMenu from "./components/_elements/ActionMenu.vue";
import ActionMenuAction from "./components/_elements/ActionMenuAction.vue";
import ActivityList from "./components/Dashboard/Widgets/ActivityList.vue";
import ActivityListItem from "./components/Dashboard/Widgets/ActivityListItem.vue";
import AddItemToFolderModal from "./components/_elements/modals/AddItemToFolderModal.vue";
import Admin from "./components/Admin/Content/Admin.vue";
import AdminPage from "./components/Admin/Content/AdminPage.vue";
import Alert from "./components/_elements/Alert.vue";
import AppData from "./components/AppData.vue";
import AppRecentActivityWidget from "./components/Dashboard/Widgets/RecentActivity/AppRecentActivityWidget.vue";
import ArticulateImage from "./components/ArticulateImage.vue";
import AssignmentGroupFields from "./components/Prep/assignments/AssignmentGroupFields.vue";
import BaseModal from "./components/_elements/modals/BaseModal.vue";
import BillingActiveSubscriptions from "./components/Account/Billing/BillingActiveSubscriptions.vue";
import BillingAddPaymentMethod from "./components/Account/Billing/BillingAddPaymentMethod.vue";
import BillingBillingInfo from "./components/Account/Billing/BillingBillingInfo.vue";
import BillingCancelPlan from "./components/Account/Billing/BillingCancelPlan.vue";
import BillingEditInfoPage from "./components/Account/Billing/BillingEditInfoPage.vue";
import BillingInvoiceHistory from "./components/Account/Billing/BillingInvoiceHistory.vue";
import BillingPage from "./components/Account/Billing/BillingPage.vue";
import BillingPaymentMethods from "./components/Account/Billing/BillingPaymentMethods.vue";
import BillingResumePlan from "./components/Account/Billing/BillingResumePlan.vue";
import BillingSection from "./components/Account/Billing/BillingSection.vue";
import BillingUpdateSubscriptionPayment from "./components/Account/Billing/BillingUpdateSubscriptionPayment.vue";
import BladeContent from "./components/BladeContent.vue";
import Categories from "./components/Prep/categories/Categories.vue";
import CategoryCreate from "./components/Prep/categories/CategoryCreate.vue";
import CourseAssessmentLock from "./components/Courses/CourseAssessmentLock.vue";
import CategoryEdit from "./components/Prep/categories/CategoryEdit.vue";
import ChartDonut from "./components/_elements/ChartDonut.vue";
import ChartLine from "./components/_elements/ChartLine.vue";
import CompletionStatus from "./components/Reporting/components/CompletionStatus.vue";
import ConceptComponent from "./components/Learn/ConceptComponent.vue";
import ConfirmDeleteModal from "./components/_elements/modals/ConfirmDeleteModal.vue";
import ConfirmDeleteSavedItemsFolderModal from "./components/_elements/modals/ConfirmDeleteSavedItemsFolderModal.vue";
import ContentEditor from "./components/_elements/ContentEditor.vue";
import ContentQuestionFeedbackCollection from "./components/Admin/Content/questions/ContentQuestionFeedbackCollection.vue";
import ContentQuestionFeedbackCollectionItem from "./components/Admin/Content/questions/ContentQuestionFeedbackCollectionItem.vue";
import ContentQuestionForm from "./components/Admin/Content/questions/ContentQuestionForm.vue";
import ContentQuestionHistoryList from "./components/Admin/Content/questions/ContentQuestionHistoryList.vue";
import ContentQuestionHistoryListItem from "./components/Admin/Content/questions/ContentQuestionHistoryListItem.vue";
import ContentQuestions from "./components/Admin/Content/questions/ContentQuestions.vue";
import ContentQuestionsList from "./components/Admin/Content/questions/ContentQuestionsList.vue";
import ContentQuestionsListItem from "./components/Admin/Content/questions/ContentQuestionsListItem.vue";
import CopyAction from "./components/_elements/CopyAction.vue";
import CourseBadge from "./components/Search/CourseBadge.vue";
import CourseCard from "./components/Courses/CourseCard.vue";
import CoursePage from "./components/Admin/Content/courses/CoursePage.vue";
import CoursesPage from "./components/Admin/Content/courses/CoursesPage.vue";
import CoursesReportView from "./components/Reporting/courses/CoursesReportView.vue";
import CoursesSliderContainer from "./components/Courses/CoursesSlider/CoursesSliderContainer.vue";
import CoursesTitleBar from "./components/Admin/Content/courses/CoursesTitleBar.vue";
import DashboardWidget from "./components/Dashboard/Widgets/DashboardWidget.vue";
import DataExport from "./components/_elements/DataExport.vue";
import DeleteTrashWithModal from "./components/_elements/DeleteTrashWithModal.vue";
import DismissableModal from "./components/_elements/DismissableModal.vue";
import Dropdown from "./components/_elements/Dropdown.vue";
import DropdownItem from "./components/_elements/DropdownItem.vue";
import EditCoursePage from "./components/Admin/Content/courses/EditCoursePage.vue";
import Editor from "./components/_elements/Editor.vue";
import EditorLinkModal from "./components/_elements/modals/EditorLinkModal.vue";
import EditorSourceModal from "./components/_elements/modals/EditorSourceModal.vue";
import EmailForm from "./components/Auth/EmailForm.vue";
import EmptyPanel from "./components/_elements/EmptyPanel.vue";
import EmptyRouterView from "./components/_elements/EmptyRouterView.vue";
import ExamCategoryItem from "./components/Prep/exam/ExamCategoryItem.vue";
import ExamFeedback from "./components/Prep/exam/ExamFeedback.vue";
import ExamQuestions from "./components/Admin/Content/exams/ExamQuestions.vue";
import ExamResultsDonut from "./components/Learn/ExamResultsDonut.vue";
import ExamScoreWidget from "./components/Learn/ExamScoreWidget.vue";
import ExamShow from "./components/Prep/exam/ExamShow.vue";
import ExamTake from "./components/Prep/exam/ExamTake.vue";
import ExtendAccessFields from "./components/Admin/Partners/ExtendAccessFields.vue";
import Fade from "./components/_elements/Fade.vue";
import FavoriteItem from "./components/Learn/FavoriteItem.vue";
import FeedbackButton from "./components/FeedbackButton.vue";
import FeedbackModal from "./components/_elements/modals/FeedbackModal.vue";
import Flashcard from "./components/Learn/Flashcard.vue";
import Flashcards from "./components/Learn/Flashcards.vue";
import FullPagination from "./components/_elements/FullPagination.vue";
import GroupActionMenu from "./components/Admin/Partners/GroupActionMenu.vue";
import GroupAssignModal from "./components/_elements/modals/GroupAssignModal.vue";
import ImageBrowser from "./components/_elements/ImageBrowser.vue";
import ImageSelector from "./components/_elements/ImageSelector.vue";
import LearnCourseMap from "./components/Learn/LearnCourseMap.vue";
import LearnCourseMapModule from "./components/Learn/LearnCourseMapModule.vue";
import LearnTestExamQuestionProps from "./components/Prep/exam/LearnTestExamQuestionProps.vue";
import LearnerDetailsList from "./components/Admin/Partners/LearnerDetailsList.vue";
import LearnerViewCourses from "./components/Reporting/courses/LearnerViewCourses.vue";
import LearnerViewModule from "./components/Reporting/module/LearnerViewModule.vue";
import LearnerViewSingleCourse from "./components/Reporting/course/LearnerViewSingleCourse.vue";
import LessonActivityStats from "./components/Dashboard/Widgets/RecentActivity/LessonActivityStats.vue";
import LessonBadge from "./components/Search/LessonBadge.vue";
import LessonListing from "./components/Learn/LessonListing.vue";
import LessonNote from "./components/Learn/LessonNote.vue";
import LessonNotes from "./components/Learn/LessonNotes.vue";
import LessonPage from "./components/Admin/Content/lessons/LessonPage.vue";
import LessonTitleBar from "./components/Admin/Content/lessons/LessonTitleBar.vue";
import LessonViewActivityWidget from "./components/Dashboard/Widgets/RecentActivity/LessonViewActivityWidget.vue";
import LessonsPage from "./components/Admin/Content/lessons/LessonsPage.vue";
import LessonsTitleBar from "./components/Admin/Content/lessons/LessonsTitleBar.vue";
import LoadData from "./components/LoadData.vue";
import Loader from "./components/_elements/Loader.vue";
import LoadingSpinner from "./components/_elements/LoadingSpinner.vue";
import LoggerItems from "./components/Admin/Logger/LoggerItems.vue";
import LoginForm from "./components/Auth/LoginForm.vue";
import MainVueLayout from "./components/MainVueLayout.vue";
import MenuAccount from "./components/_elements/MenuAccount.vue";
import MenuSupport from "./components/_elements/MenuSupport.vue";
import MobileNav from "./components/_elements/MobileNav.vue";
import Modal from "./components/_elements/modals/Modal.vue";
import ModuleBadge from "./components/Search/ModuleBadge.vue";
import ModuleReportView from "./components/Reporting/module/ModuleReportView.vue";
import MoreButton from "./components/_elements/MoreButton.vue";
import MostRecentLessonLink from "./components/Learn/MostRecentLessonLink.vue";
import MulticheckboxSelect from "./components/_elements/MulticheckboxSelect.vue";
import Multichoice from "./components/_elements/Multichoice.vue";
import OverviewSearch from "./components/Pulse/explore/OverviewSearch.vue";
import PageSearch from "./components/_elements/PageSearch.vue";
import PageTitle from "./components/_elements/PageTitle.vue";
import Pagination from "./components/_elements/Pagination.vue";
import PartnerAccessCodesModal from "./components/_elements/modals/PartnerAccessCodesModal.vue";
import PartnerGroupModal from "./components/_elements/modals/PartnerGroupModal.vue";
import PartnerGroupsList from "./components/Admin/Users/PartnerGroupsList.vue";
import PauseButton from "./components/_elements/PauseButton.vue";
import PdfResource from "./components/_elements/PdfResource.vue";
import Pill from "./components/_elements/Pill.vue";
import Placeholder from "./components/_elements/Placeholder.vue";
import PrepAssignmentAssignedCollection from "./components/Prep/exam/PrepAssignmentAssignedCollection.vue";
import PrepAssignmentAssignedCollectionResource from "./components/Prep/exam/PrepAssignmentAssignedCollectionResource.vue";
import PrepAssignmentAssigneeModal from "./components/_elements/modals/PrepAssignmentAssigneeModal.vue";
import PrepAssignmentBuilder from "./components/Prep/exam/PrepAssignmentBuilder.vue";
import PrepAssignmentsCreatedCollection from "./components/Prep/exam/PrepAssignmentsCreatedCollection.vue";
import PrepAssignmentsCreatedCollectionResource from "./components/Prep/exam/PrepAssignmentsCreatedCollectionResource.vue";
import PrepBlueprintCollection from "./components/Prep/exam/PrepBlueprintCollection.vue";
import PrepBlueprintCollectionResource from "./components/Prep/exam/PrepBlueprintCollectionResource.vue";
import PrepCategoryBadge from "./components/Prep/categories/PrepCategoryBadge.vue";
import PrepCategoryCircle from "./components/Prep/exam/PrepCategoryCircle.vue";
import PrepCategoryCircles from "./components/Prep/exam/PrepCategoryCircles.vue";
import PrepCategoryFilter from "./components/Prep/categories/PrepCategoryFilter.vue";
import PrepCategoryReport from "./components/Prep/exam/PrepCategoryReport.vue";
import PrepCategoryReportDonut from "./components/Prep/exam/PrepCategoryReportDonut.vue";
import PrepCategoryReportModal from "./components/_elements/modals/PrepCategoryReportModal.vue";
import PrepCategorySlider from "./components/Prep/exam/PrepCategorySlider.vue";
import PrepExam from "./components/Prep/exam/PrepExam.vue";
import PrepExamBuilder from "./components/Prep/exam/PrepExamBuilder.vue";
import PrepExamCollection from "./components/Prep/exam/PrepExamCollection.vue";
import PrepExamCollectionCard from "./components/Prep/exam/PrepExamCollectionCard.vue";
import PrepExamCollectionListItem from "./components/Prep/exam/PrepExamCollectionListItem.vue";
import PrepExamCollectionResource from "./components/Prep/exam/PrepExamCollectionResource.vue";
import PrepExamFlagQuestion from "./components/Prep/exam/PrepExamFlagQuestion.vue";
import PrepExamHistory from "./components/Prep/exam/PrepExamHistory.vue";
import PrepExamLibraryCollection from "./components/Prep/exam/PrepExamLibraryCollection.vue";
import PrepExamLibraryCollectionResource from "./components/Prep/exam/PrepExamLibraryCollectionResource.vue";
import PrepExamOverview from "./components/Prep/exam/PrepExamOverview.vue";
import PrepExamOverviewContainer from "./components/Prep/exam/PrepExamOverviewContainer.vue";
import PrepExamOverviewNavigation from "./components/Prep/exam/PrepExamOverviewNavigation.vue";
import PrepExamQuestion from "./components/Prep/exam/PrepExamQuestion.vue";
import PrepExamResults from "./components/Prep/exam/PrepExamResults.vue";
import PrimaryLayout from "./components/_layout/PrimaryLayout.vue";
import PrimarySearch from "./components/_elements/PrimarySearch.vue";
import ProductEnrollmentsListModal from "./components/_elements/modals/ProductEnrollmentsListModal.vue";
import ProfilePhoto from "./components/_elements/ProfilePhoto.vue";
import ProgressBar from "./components/_elements/ProgressBar.vue";
import PulseActivityTable from "./components/Pulse/_shared/table/PulseActivityTable.vue";
import PulseActivityTableAccess from "./components/Pulse/_shared/table/content/PulseActivityTableAccess.vue";
import PulseActivityTableCell from "./components/Pulse/_shared/table/PulseActivityTableCell.vue";
import PulseActivityTableCheckbox from "./components/Pulse/_shared/table/PulseActivityTableCheckbox.vue";
import PulseActivityTableColumnHeader from "./components/Pulse/_shared/table/PulseActivityTableColumnHeader.vue";
import PulseActivityTableCourseFilter from "./components/Pulse/_shared/table/PulseActivityTableCourseFilter.vue";
import PulseActivityTableFilter from "./components/Pulse/_shared/table/PulseActivityTableFilter.vue";
import PulseActivityTableLearner from "./components/Pulse/_shared/table/content/PulseActivityTableLearner.vue";
import PulseActivityTableLesson from "./components/Pulse/_shared/table/content/PulseActivityTableLesson.vue";
import PulseActivityTableLevelDropdown from "./components/Pulse/_shared/table/PulseActivityTableLevelDropdown.vue";
import PulseActivityTableRow from "./components/Pulse/_shared/table/PulseActivityTableRow.vue";
import PulseActivityTableType from "./components/Pulse/_shared/table/content/PulseActivityTableType.vue";
import PulseActivityTableViewToggle from "./components/Pulse/_shared/table/PulseActivityTableViewToggle.vue";
import PulseActivityTableViewed from "./components/Pulse/_shared/table/content/PulseActivityTableViewed.vue";
import PulseActivityTabs from "./components/Reporting/PulseActivityTabs.vue";
import PulseActivityTypeFilter from "./components/Pulse/_shared/PulseActivityTypeFilter.vue";
import PulseAssessment from "./components/Pulse/explore/PulseAssessment.vue";
import PulseAssessmentAttempts from "./components/Pulse/explore/PulseAssessmentAttempts.vue";
import PulseAssessmentAttemptsRow from "./components/Pulse/explore/PulseAssessmentAttemptsRow.vue";
import PulseAssessmentInstance from "./components/Pulse/explore/PulseAssessmentInstance.vue";
import PulseAssessmentLearners from "./components/Pulse/explore/PulseAssessmentLearners.vue";
import PulseAssessmentLearnersRow from "./components/Pulse/explore/PulseAssessmentLearnersRow.vue";
import PulseAssessments from "./components/Pulse/explore/PulseAssessments.vue";
import PulseAssessmentsRow from "./components/Pulse/explore/PulseAssessmentsRow.vue";
import PulseAssignmentTableRow from "./components/Pulse/prep/assignment/rows/PulseAssignmentTableRow.vue";
import PulseAssignmentsTableRow from "./components/Pulse/prep/assignment/rows/PulseAssignmentsTableRow.vue";
import PulseAttempt from "./components/Pulse/explore/PulseAttempt.vue";
import PulseCompletionBar from "./components/Pulse/_shared/PulseCompletionBar.vue";
import PulseContentCountBadges from "./components/Pulse/_shared/cards/_shared/PulseContentCountBadges.vue";
import PulseContentEngagementCard from "./components/Pulse/_shared/cards/PulseContentEngagementCard.vue";
import PulseContentEngagementStats from "./components/Pulse/_shared/cards/_shared/PulseContentEngagementStats.vue";
import PulseContextToggle from "./components/Pulse/_shared/PulseContextToggle.vue";
import PulseContextToggleNavItem from "./components/Pulse/_shared/PulseContextToggleNavItem.vue";
import PulseCourseEngagementCard from "./components/Pulse/_shared/cards/PulseCourseEngagementCard.vue";
import PulseExamsTableRow from "./components/Pulse/prep/exam/PulseExamsTableRow.vue";
import PulseExplore from "./components/Pulse/explore/PulseExplore.vue";
import PulseExploreAssessmentDetails from "./components/Pulse/explore/PulseExploreAssessmentDetails.vue";
import PulseExploreAssessments from "./components/Pulse/explore/details/assessments/PulseExploreAssessments.vue";
import PulseExploreAssessmentsIndex from "./components/Pulse/explore/PulseExploreAssessmentsIndex.vue";
import PulseExploreAssessmentsListingGroup from "./components/Pulse/explore/details/assessments/PulseExploreAssessmentsListingGroup.vue";
import PulseExploreAssessmentsListingInstance from "./components/Pulse/explore/details/assessments/PulseExploreAssessmentsListingInstance.vue";
import PulseExploreAssessmentsListingSingle from "./components/Pulse/explore/details/assessments/PulseExploreAssessmentsListingSingle.vue";
import PulseExploreChart from "./components/Pulse/explore/_shared/PulseExploreChart.vue";
import PulseExploreDetailsCourseOverview from "./components/Pulse/explore/details/_shared/PulseExploreDetailsCourseOverview.vue";
import PulseExploreDetailsCourseOverviewRow from "./components/Pulse/explore/details/_shared/PulseExploreDetailsCourseOverviewRow.vue";
import PulseExploreDetailsListingInstances from "./components/Pulse/explore/details/_shared/PulseExploreDetailsListingInstances.vue";
import PulseExploreDetailsListingInstancesRow from "./components/Pulse/explore/details/_shared/PulseExploreDetailsListingInstancesRow.vue";
import PulseExploreDetailsListingLearners from "./components/Pulse/explore/details/_shared/PulseExploreDetailsListingLearners.vue";
import PulseExploreDetailsListingLearnersRow from "./components/Pulse/explore/details/_shared/PulseExploreDetailsListingLearnersRow.vue";
import PulseExploreDetailsListingMeta from "./components/Pulse/explore/details/_shared/PulseExploreDetailsListingMeta.vue";
import PulseExploreDetailsQuestions from "./components/Pulse/explore/details/_shared/PulseExploreDetailsQuestions.vue";
import PulseExploreDetailsQuestionsRow from "./components/Pulse/explore/details/_shared/PulseExploreDetailsQuestionsRow.vue";
import PulseExploreLayout from "./components/Pulse/explore/_shared/PulseExploreLayout.vue";
import PulseExploreLessons from "./components/Pulse/explore/details/lessons/PulseExploreLessons.vue";
import PulseExploreLessonsListingGroup from "./components/Pulse/explore/details/lessons/PulseExploreLessonsListingGroup.vue";
import PulseExploreLessonsListingInstance from "./components/Pulse/explore/details/lessons/PulseExploreLessonsListingInstance.vue";
import PulseExploreLessonsListingSingle from "./components/Pulse/explore/details/lessons/PulseExploreLessonsListingSingle.vue";
import PulseExploreOverview from "./components/Pulse/explore/overview/PulseExploreOverview.vue";
import PulseExploreOverviewTable from "./components/Pulse/explore/overview/PulseExploreOverviewTable.vue";
import PulseExploreOverviewTableRow from "./components/Pulse/explore/overview/PulseExploreOverviewTableRow.vue";
import PulseExplorePrep from "./components/Pulse/explore/prep/PulseExplorePrep.vue";
import PulseExplorePrepCategoryChart from "./components/Pulse/explore/prep/PulseExplorePrepCategoryChart.vue";
import PulseExplorePrepRow from "./components/Pulse/explore/prep/PulseExplorePrepRow.vue";
import PulseExplorePrepTable from "./components/Pulse/explore/prep/PulseExplorePrepTable.vue";
import PulseExploreQuizzes from "./components/Pulse/explore/details/quizzes/PulseExploreQuizzes.vue";
import PulseExploreQuizzesListingGroup from "./components/Pulse/explore/details/quizzes/PulseExploreQuizzesListingGroup.vue";
import PulseExploreQuizzesListingInstance from "./components/Pulse/explore/details/quizzes/PulseExploreQuizzesListingInstance.vue";
import PulseExploreQuizzesListingSingle from "./components/Pulse/explore/details/quizzes/PulseExploreQuizzesListingSingle.vue";
import PulseExploreReportPeriod from "./components/Pulse/explore/_shared/PulseExploreReportPeriod.vue";
import PulseExploreReportSubject from "./components/Pulse/explore/_shared/PulseExploreReportSubject.vue";
import PulseExploreReportType from "./components/Pulse/explore/_shared/PulseExploreReportType.vue";
import PulseExploreReportTypeNavChild from "./components/Pulse/explore/_shared/PulseExploreReportTypeNavChild.vue";
import PulseExploreReportTypeNavItem from "./components/Pulse/explore/_shared/PulseExploreReportTypeNavItem.vue";
import PulseExploreReportTypeNavParent from "./components/Pulse/explore/_shared/PulseExploreReportTypeNavParent.vue";
import PulseExploreTimeline from "./components/Pulse/explore/timeline/PulseExploreTimeline.vue";
import PulseExploreTimelineRow from "./components/Pulse/explore/timeline/PulseExploreTimelineRow.vue";
import PulseExploreTimelineTable from "./components/Pulse/explore/timeline/PulseExploreTimelineTable.vue";
import PulseGroupSearch from "./components/Pulse/_shared/search/PulseGroupSearch.vue";
import PulseLayout from "./components/Pulse/_shared/PulseLayout.vue";
import PulseLearn from "./components/Pulse/learn/PulseLearn.vue";
import PulseLearnCompletionsRow from "./components/Pulse/learn/_shared/PulseLearnCompletionsRow.vue";
import PulseLearnCompletionsRowTable from "./components/Pulse/learn/_shared/PulseLearnCompletionsRowTable.vue";
import PulseLearnCourse from "./components/Pulse/learn/PulseLearnCourse.vue";
import PulseLearnCourseActivity from "./components/Pulse/learn/course/PulseLearnCourseActivity.vue";
import PulseLearnCourseLearnersRow from "./components/Pulse/learn/course/rows/PulseLearnCourseLearnersRow.vue";
import PulseLearnCourseOverview from "./components/Pulse/learn/course/PulseLearnCourseOverview.vue";
import PulseLearnCourseStructuredRow from "./components/Pulse/learn/course/rows/PulseLearnCourseStructuredRow.vue";
import PulseLearnCourses from "./components/Pulse/learn/PulseLearnCourses.vue";
import PulseLearnCoursesActivity from "./components/Pulse/learn/courses/PulseLearnCoursesActivity.vue";
import PulseLearnCoursesLearnersRow from "./components/Pulse/learn/courses/rows/PulseLearnCoursesLearnersRow.vue";
import PulseLearnCoursesOverview from "./components/Pulse/learn/courses/PulseLearnCoursesOverview.vue";
import PulseLearnCoursesStructuredRow from "./components/Pulse/learn/courses/rows/PulseLearnCoursesStructuredRow.vue";
import PulseLearnModule from "./components/Pulse/learn/PulseLearnModule.vue";
import PulseLearnModuleActivity from "./components/Pulse/learn/module/PulseLearnModuleActivity.vue";
import PulseLearnModuleLearnersRow from "./components/Pulse/learn/module/rows/PulseLearnModuleLearnersRow.vue";
import PulseLearnModuleStructuredRow from "./components/Pulse/learn/module/rows/PulseLearnModuleStructuredRow.vue";
import PulseLearnOverview from "./components/Pulse/learn/_shared/PulseLearnOverview.vue";
import PulseLearnOverviewPagination from "./components/Pulse/learn/_shared/PulseLearnOverviewPagination.vue";
import PulseLearnOverviewSort from "./components/Pulse/learn/_shared/PulseLearnOverviewSort.vue";
import PulseLearnTabs from "./components/Pulse/learn/_shared/PulseLearnTabs.vue";
import PulseLearnerSearch from "./components/Pulse/_shared/search/PulseLearnerSearch.vue";
import PulseLesson from "./components/Pulse/explore/PulseLesson.vue";
import PulseLessonLearner from "./components/Pulse/explore/PulseLessonLearner.vue";
import PulseLessonLearnerRow from "./components/Pulse/explore/PulseLessonLearnerRow.vue";
import PulseLessonLearners from "./components/Pulse/explore/PulseLessonLearners.vue";
import PulseLessonLearnersRow from "./components/Pulse/explore/PulseLessonLearnersRow.vue";
import PulseLookup from "./components/Pulse/lookup/PulseLookup.vue";
import PulseModuleEngagementCard from "./components/Pulse/_shared/cards/PulseModuleEngagementCard.vue";
import PulsePagination from "./components/Pulse/_shared/PulsePagination.vue";
import PulsePrepAssignmentActivity from "./components/Pulse/prep/assignment/PulsePrepAssignmentActivity.vue";
import PulsePrepAssignmentReport from "./components/Pulse/prep/report/PulsePrepAssignmentReport.vue";
import PulsePrepAssignmentTable from "./components/Pulse/prep/assignment/PulsePrepAssignmentTable.vue";
import PulsePrepAssignmentsTable from "./components/Pulse/prep/assignment/PulsePrepAssignmentsTable.vue";
import PulsePrepCategoryCircle from "./components/Pulse/prep/_shared/PulsePrepCategoryCircle.vue";
import PulsePrepExamActivity from "./components/Pulse/prep/exam/PulsePrepExamActivity.vue";
import PulsePrepExamAssignment from "./components/Pulse/prep/PulsePrepExamAssignment.vue";
import PulsePrepExamCategories from "./components/Pulse/prep/exam/PulsePrepExamCategories.vue";
import PulsePrepExamReport from "./components/Pulse/prep/report/PulsePrepExamReport.vue";
import PulsePrepExamsTable from "./components/Pulse/prep/exam/PulsePrepExamsTable.vue";
import PulsePrepReport from "./components/Pulse/prep/PulsePrepReport.vue";
import PulsePrepRow from "./components/Pulse/explore/PulsePrepRow.vue";
import PulsePrepScoreData from "./components/Pulse/prep/_shared/PulsePrepScoreData.vue";
import PulsePrepTabs from "./components/Pulse/prep/PulsePrepTabs.vue";
import PulseReportPeriod from "./components/Pulse/_shared/PulseReportPeriod.vue";
import PulseSubjectHeader from "./components/Pulse/_shared/PulseSubjectHeader.vue";
import PulseSync from "./components/Pulse/_shared/PulseSync.vue";
import PulseToggle from "./components/Pulse/_shared/PulseToggle.vue";
import QuillRenderer from "./components/_elements/QuillRenderer.vue";
import ReferenceList from "./components/Learn/ReferenceList.vue";
import RegistrationCodes from "./components/RegistrationCodes.vue";
import ReportingRouteMixin from "./components/Reporting/ReportingRouteMixin.vue";
import ReportingTab from "./components/Reporting/shared/ReportingTab.vue";
import ReportingTabs from "./components/Reporting/shared/ReportingTabs.vue";
import RevokeDataAccess from "./components/Account/RevokeDataAccess.vue";
import SavedFoldersList from "./components/Learn/SavedFoldersList.vue";
import SavedItems from "./components/Learn/SavedItems.vue";
import SavedItemsFolderModal from "./components/_elements/modals/SavedItemsFolderModal.vue";
import SavedItemsList from "./components/Learn/SavedItemsList.vue";
import ScoreData from "./components/_elements/ScoreData.vue";
import SearchAnnouncement from "./components/Search/SearchAnnouncement.vue";
import SearchButton from "./components/Search/SearchButton.vue";
import SearchDialog from "./components/Search/SearchDialog.vue";
import SearchInput from "./components/_elements/SearchInput.vue";
import SearchInputBox from "./components/Search/SearchInputBox.vue";
import SearchList from "./components/Search/SearchList.vue";
import SearchListItem from "./components/Search/SearchListItem.vue";
import SearchReveal from "./components/_elements/SearchReveal.vue";
import SearchableSelectDropdown from "./components/_elements/SearchableSelectDropdown.vue";
import SearchableSelectDropdownItem from "./components/_elements/SearchableSelectDropdownItem.vue";
import SelectAll from "./components/_elements/SelectAll.vue";
import SelectDropdown from "./components/_elements/SelectDropdown.vue";
import SelectList from "./components/_elements/SelectList.vue";
import SidebarAdmin from "./components/_layout/SidebarAdmin.vue";
import SidebarBase from "./components/_layout/SidebarBase.vue";
import SidebarItem from "./components/_layout/SidebarItem.vue";
import SidebarMainNew from "./components/_layout/SidebarMainNew.vue";
import SidebarNavChild from "./components/_layout/SidebarNavChild.vue";
import SidebarNavItem from "./components/_layout/SidebarNavItem.vue";
import SidebarNavParent from "./components/_layout/SidebarNavParent.vue";
import SidebarNavRouterChild from "./components/_layout/SidebarNavRouterChild.vue";
import SidebarUser from "./components/_layout/SidebarUser.vue";
import SingleCourseReportView from "./components/Reporting/course/SingleCourseReportView.vue";
import SiteRibbon from "./components/_elements/SiteRibbon.vue";
import Skeleton from "./components/_elements/Skeleton.vue";
import SkipToContent from "./components/_elements/SkipToContent.vue";
import SlideoverPanel from "./components/_elements/SlideoverPanel.vue";
import SortButton from "./components/_elements/SortButton.vue";
import SortableLink from "./components/_elements/SortableLink.vue";
import StructuredAssessmentRows from "./components/Reporting/course/StructuredAssessmentRows.vue";
import StructuredLessonRows from "./components/Reporting/course/StructuredLessonRows.vue";
import StructuredModuleAssessmentRows from "./components/Reporting/module/StructuredModuleAssessmentRows.vue";
import StructuredModuleLessonRows from "./components/Reporting/module/StructuredModuleLessonRows.vue";
import StructuredModuleQuizRows from "./components/Reporting/module/StructuredModuleQuizRows.vue";
import StructuredQuizRows from "./components/Reporting/course/StructuredQuizRows.vue";
import StructuredViewCourses from "./components/Reporting/courses/StructuredViewCourses.vue";
import StructuredViewModule from "./components/Reporting/module/StructuredViewModule.vue";
import StructuredViewSingleCourse from "./components/Reporting/course/StructuredViewSingleCourse.vue";
import SvgIcon from "./components/_elements/SvgIcon.vue";
import TableHeading from "./components/_elements/TableHeading.vue";
import Tabs from "./components/_elements/Tabs.vue";
import TimezoneSelect from "./components/_elements/TimezoneSelect.vue";
import TitleBar from "./components/_layout/TitleBar.vue";
import TitleBarNavChild from "./components/_layout/TitleBarNavChild.vue";
import TitleBarNavItem from "./components/_layout/TitleBarNavItem.vue";
import TitleBarRouter from "./components/_layout/TitleBarRouter.vue";
import Toast from "./components/_elements/Toast.vue";
import ToastNotification from "./components/_elements/ToastNotification.vue";
import Toaster from "./components/_elements/Toaster.vue";
import ToggleSwitch from "./components/_elements/ToggleSwitch.vue";
import ToggleVisibility from "./components/ToggleVisibility.vue";
import UpdateItem from "./components/Support/UpdateItem.vue";
import VideoProgress from "./components/Learn/VideoProgress.vue";
import VideosWidget from "./components/Learn/VideosWidget.vue";
import WaveContainer from "./components/_elements/WaveContainer.vue";
import WistiaPreviewer from "./components/_elements/WistiaPreviewer.vue";
import WistiaSimple from "./components/_elements/WistiaSimple.vue";
import WistiaVideo from "./components/_elements/WistiaVideo.vue";
import LessonPageDescription from "./components/Learn/LessonPageDescription.vue";
import InfoAlert from "./components/_elements/alerts/InfoAlert.vue";
import SimpleLayout from "./components/_layout/SimpleLayout.vue";
import RegisterPage from "@/components/Account/RegisterPage.vue";
import LoginPage from "@/components/Account/LoginPage.vue";
import NewAlert from "@/components/ui/alerts/Alert.vue";
import CoursesList from "./components/Courses/CoursesList.vue";
import CePill from "./components/Courses/CePill.vue";
import Bookmark from "./components/Courses/Bookmark.vue";
import Description from "./components/Courses/Description.vue";
import BookmarksPage from "./components/Learn/BookmarksPage.vue";

export const VueComponents: Plugin = {
    install(app, options) {
        app.component("app-data", AppData);
        app.component("AppData", AppData);
        app.component("action-button", ActionButton);
        app.component("ActionButton", ActionButton);
        app.component("action-link", ActionLink);
        app.component("ActionLink", ActionLink);
        app.component("action-menu", ActionMenu);
        app.component("ActionMenu", ActionMenu);
        app.component("action-menu-action", ActionMenuAction);
        app.component("ActionMenuAction", ActionMenuAction);
        app.component("activity-list-item", ActivityListItem);
        app.component("ActivityListItem", ActivityListItem);
        app.component("activity-list", ActivityList);
        app.component("ActivityList", ActivityList);
        app.component("add-item-to-folder-modal", AddItemToFolderModal);
        app.component("AddItemToFolderModal", AddItemToFolderModal);
        app.component("admin", AdminPage);
        app.component("Admin", AdminPage);
        app.component("alert", Alert);
        app.component("Alert", Alert);
        app.component("app-recent-activity-widget", AppRecentActivityWidget);
        app.component("AppRecentActivityWidget", AppRecentActivityWidget);
        app.component("articulate-image", ArticulateImage);
        app.component("ArticulateImage", ArticulateImage);
        app.component("assignment-group-fields", AssignmentGroupFields);
        app.component("AssignmentGroupFields", AssignmentGroupFields);
        app.component("base-modal", BaseModal);
        app.component("BaseModal", BaseModal);
        app.component(
            "billing-active-subscriptions",
            BillingActiveSubscriptions
        );
        app.component("BillingActiveSubscriptions", BillingActiveSubscriptions);
        app.component("billing-add-payment-method", BillingAddPaymentMethod);
        app.component("BillingAddPaymentMethod", BillingAddPaymentMethod);
        app.component("billing-billing-info", BillingBillingInfo);
        app.component("BillingBillingInfo", BillingBillingInfo);
        app.component("billing-cancel-plan", BillingCancelPlan);
        app.component("BillingCancelPlan", BillingCancelPlan);
        app.component("billing-edit-info-page", BillingEditInfoPage);
        app.component("BillingEditInfoPage", BillingEditInfoPage);
        app.component("billing-invoice-history", BillingInvoiceHistory);
        app.component("BillingInvoiceHistory", BillingInvoiceHistory);
        app.component("billing-page", BillingPage);
        app.component("BillingPage", BillingPage);
        app.component("billing-payment-methods", BillingPaymentMethods);
        app.component("BillingPaymentMethods", BillingPaymentMethods);
        app.component("billing-resume-plan", BillingResumePlan);
        app.component("BillingResumePlan", BillingResumePlan);
        app.component("billing-section", BillingSection);
        app.component("BillingSection", BillingSection);
        app.component(
            "billing-update-subscription-payment",
            BillingUpdateSubscriptionPayment
        );
        app.component(
            "BillingUpdateSubscriptionPayment",
            BillingUpdateSubscriptionPayment
        );
        app.component("blade-content", BladeContent);
        app.component("BladeContent", BladeContent);
        app.component("categories", Categories);
        app.component("Categories", Categories);
        app.component("category-create", CategoryCreate);
        app.component("CategoryCreate", CategoryCreate);
        app.component("category-edit", CategoryEdit);
        app.component("CategoryEdit", CategoryEdit);
        app.component("chart-donut", ChartDonut);
        app.component("ChartDonut", ChartDonut);
        app.component("chart-line", ChartLine);
        app.component("ChartLine", ChartLine);
        app.component("completion-status", CompletionStatus);
        app.component("CompletionStatus", CompletionStatus);
        app.component("concept-component", ConceptComponent);
        app.component("ConceptComponent", ConceptComponent);
        app.component("confirm-delete-modal", ConfirmDeleteModal);
        app.component("ConfirmDeleteModal", ConfirmDeleteModal);
        app.component(
            "confirm-delete-saved-items-folder-modal",
            ConfirmDeleteSavedItemsFolderModal
        );
        app.component(
            "ConfirmDeleteSavedItemsFolderModal",
            ConfirmDeleteSavedItemsFolderModal
        );
        app.component("content-editor", ContentEditor);
        app.component("ContentEditor", ContentEditor);
        app.component(
            "content-question-feedback-collection",
            ContentQuestionFeedbackCollection
        );
        app.component(
            "ContentQuestionFeedbackCollection",
            ContentQuestionFeedbackCollection
        );
        app.component(
            "content-question-feedback-collection-item",
            ContentQuestionFeedbackCollectionItem
        );
        app.component(
            "ContentQuestionFeedbackCollectionItem",
            ContentQuestionFeedbackCollectionItem
        );
        app.component("content-question-form", ContentQuestionForm);
        app.component("ContentQuestionForm", ContentQuestionForm);
        app.component(
            "content-question-history-list",
            ContentQuestionHistoryList
        );
        app.component("ContentQuestionHistoryList", ContentQuestionHistoryList);
        app.component(
            "content-question-history-list-item",
            ContentQuestionHistoryListItem
        );
        app.component(
            "ContentQuestionHistoryListItem",
            ContentQuestionHistoryListItem
        );
        app.component("content-questions", ContentQuestions);
        app.component("ContentQuestions", ContentQuestions);
        app.component("content-questions-list", ContentQuestionsList);
        app.component("ContentQuestionsList", ContentQuestionsList);
        app.component("content-questions-list-item", ContentQuestionsListItem);
        app.component("ContentQuestionsListItem", ContentQuestionsListItem);
        app.component("copy-action", CopyAction);
        app.component("CopyAction", CopyAction);
        app.component("course-badge", CourseBadge);
        app.component("CourseBadge", CourseBadge);
        app.component("course-card", CourseCard);
        app.component("CourseCard", CourseCard);
        app.component("course-page", CoursePage);
        app.component("CoursePage", CoursePage);
        app.component("courses-page", CoursesPage);
        app.component("CoursesPage", CoursesPage);
        app.component("courses-report-view", CoursesReportView);
        app.component("CoursesReportView", CoursesReportView);
        app.component("courses-slider-container", CoursesSliderContainer);
        app.component("CoursesSliderContainer", CoursesSliderContainer);
        app.component("courses-title-bar", CoursesTitleBar);
        app.component("CoursesTitleBar", CoursesTitleBar);
        app.component("dashboard-widget", DashboardWidget);
        app.component("DashboardWidget", DashboardWidget);
        app.component("data-export", DataExport);
        app.component("DataExport", DataExport);
        app.component("delete-trash-with-modal", DeleteTrashWithModal);
        app.component("DeleteTrashWithModal", DeleteTrashWithModal);
        app.component("dismissable-modal", DismissableModal);
        app.component("DismissableModal", DismissableModal);
        app.component("dropdown", Dropdown);
        app.component("Dropdown", Dropdown);
        app.component("dropdown-item", DropdownItem);
        app.component("DropdownItem", DropdownItem);
        app.component("edit-course-page", EditCoursePage);
        app.component("EditCoursePage", EditCoursePage);
        app.component("editor", Editor);
        app.component("Editor", Editor);
        app.component("editor-link-modal", EditorLinkModal);
        app.component("EditorLinkModal", EditorLinkModal);
        app.component("editor-source-modal", EditorSourceModal);
        app.component("EditorSourceModal", EditorSourceModal);
        app.component("email-form", EmailForm);
        app.component("EmailForm", EmailForm);
        app.component("empty-panel", EmptyPanel);
        app.component("EmptyPanel", EmptyPanel);
        app.component("empty-router-view", EmptyRouterView);
        app.component("EmptyRouterView", EmptyRouterView);
        app.component("exam-category-item", ExamCategoryItem);
        app.component("ExamCategoryItem", ExamCategoryItem);
        app.component("exam-feedback", ExamFeedback);
        app.component("ExamFeedback", ExamFeedback);
        app.component("exam-questions", ExamQuestions);
        app.component("ExamQuestions", ExamQuestions);
        app.component("exam-results-donut", ExamResultsDonut);
        app.component("ExamResultsDonut", ExamResultsDonut);
        app.component("exam-score-widget", ExamScoreWidget);
        app.component("ExamScoreWidget", ExamScoreWidget);
        app.component("exam-show", ExamShow);
        app.component("ExamShow", ExamShow);
        app.component("exam-take", ExamTake);
        app.component("ExamTake", ExamTake);
        app.component("extend-access-fields", ExtendAccessFields);
        app.component("ExtendAccessFields", ExtendAccessFields);
        app.component("fade", Fade);
        app.component("Fade", Fade);
        app.component("favorite-item", FavoriteItem);
        app.component("FavoriteItem", FavoriteItem);
        app.component("feedback-button", FeedbackButton);
        app.component("FeedbackButton", FeedbackButton);
        app.component("feedback-modal", FeedbackModal);
        app.component("FeedbackModal", FeedbackModal);
        app.component("flashcard", Flashcard);
        app.component("Flashcard", Flashcard);
        app.component("flashcards", Flashcards);
        app.component("Flashcards", Flashcards);
        app.component("full-pagination", FullPagination);
        app.component("FullPagination", FullPagination);
        app.component("group-action-menu", GroupActionMenu);
        app.component("GroupActionMenu", GroupActionMenu);
        app.component("group-assign-modal", GroupAssignModal);
        app.component("GroupAssignModal", GroupAssignModal);
        app.component("image-browser", ImageBrowser);
        app.component("ImageBrowser", ImageBrowser);
        app.component("image-selector", ImageSelector);
        app.component("ImageSelector", ImageSelector);
        app.component("learn-course-map", LearnCourseMap);
        app.component("LearnCourseMap", LearnCourseMap);
        app.component("learn-course-map-module", LearnCourseMapModule);
        app.component("LearnCourseMapModule", LearnCourseMapModule);
        app.component(
            "learn-test-exam-question-props",
            LearnTestExamQuestionProps
        );
        app.component("LearnTestExamQuestionProps", LearnTestExamQuestionProps);
        app.component("learner-details-list", LearnerDetailsList);
        app.component("LearnerDetailsList", LearnerDetailsList);
        app.component("learner-view-courses", LearnerViewCourses);
        app.component("LearnerViewCourses", LearnerViewCourses);

        //break

        app.component("learner-view-module", LearnerViewModule);
        app.component("LearnerViewModule", LearnerViewModule);
        app.component("learner-view-single-course", LearnerViewSingleCourse);
        app.component("LearnerViewSingleCourse", LearnerViewSingleCourse);
        app.component("lesson-activity-stats", LessonActivityStats);
        app.component("LessonActivityStats", LessonActivityStats);
        app.component("lesson-badge", LessonBadge);
        app.component("LessonBadge", LessonBadge);
        app.component("lesson-listing", LessonListing);
        app.component("LessonListing", LessonListing);
        app.component("lesson-note", LessonNote);
        app.component("LessonNote", LessonNote);
        app.component("lesson-notes", LessonNotes);
        app.component("LessonNotes", LessonNotes);
        app.component("lesson-page", LessonPage);
        app.component("LessonPage", LessonPage);
        app.component("lesson-title-bar", LessonTitleBar);
        app.component("LessonTitleBar", LessonTitleBar);
        app.component("lesson-view-activity-widget", LessonViewActivityWidget);
        app.component("LessonViewActivityWidget", LessonViewActivityWidget);
        app.component("lessons-page", LessonsPage);
        app.component("LessonsPage", LessonsPage);
        app.component("lessons-title-bar", LessonsTitleBar);
        app.component("LessonsTitleBar", LessonsTitleBar);
        app.component("load-data", LoadData);
        app.component("LoadData", LoadData);
        app.component("loader", Loader);
        app.component("Loader", Loader);
        app.component("loading-spinner", LoadingSpinner);
        app.component("LoadingSpinner", LoadingSpinner);
        app.component("logger-items", LoggerItems);
        app.component("LoggerItems", LoggerItems);
        app.component("login-form", LoginForm);
        app.component("LoginForm", LoginForm);
        app.component("main-vue-layout", MainVueLayout);
        app.component("MainVueLayout", MainVueLayout);
        app.component("menu-account", MenuAccount);
        app.component("MenuAccount", MenuAccount);
        app.component("menu-support", MenuSupport);
        app.component("MenuSupport", MenuSupport);
        app.component("mobile-nav", MobileNav);
        app.component("MobileNav", MobileNav);
        app.component("modal", Modal);
        app.component("Modal", Modal);
        app.component("module-badge", ModuleBadge);
        app.component("ModuleBadge", ModuleBadge);
        app.component("module-report-view", ModuleReportView);
        app.component("ModuleReportView", ModuleReportView);
        app.component("more-button", MoreButton);
        app.component("MoreButton", MoreButton);
        app.component("most-recent-lesson-link", MostRecentLessonLink);
        app.component("MostRecentLessonLink", MostRecentLessonLink);
        app.component("multicheckbox-select", MulticheckboxSelect);
        app.component("MulticheckboxSelect", MulticheckboxSelect);
        app.component("multichoice", Multichoice);
        app.component("Multichoice", Multichoice);
        app.component("overview-search", OverviewSearch);
        app.component("OverviewSearch", OverviewSearch);
        app.component("page-search", PageSearch);
        app.component("PageSearch", PageSearch);
        app.component("page-title", PageTitle);
        app.component("PageTitle", PageTitle);
        app.component("pagination", Pagination);
        app.component("Pagination", Pagination);
        app.component("partner-access-codes-modal", PartnerAccessCodesModal);
        app.component("PartnerAccessCodesModal", PartnerAccessCodesModal);
        app.component("partner-group-modal", PartnerGroupModal);
        app.component("PartnerGroupModal", PartnerGroupModal);
        app.component("partner-groups-list", PartnerGroupsList);
        app.component("PartnerGroupsList", PartnerGroupsList);
        app.component("pause-button", PauseButton);
        app.component("PauseButton", PauseButton);
        app.component("pdf-resource", PdfResource);
        app.component("PdfResource", PdfResource);
        app.component("pill", Pill);
        app.component("Pill", Pill);
        app.component("placeholder", Placeholder);
        app.component("Placeholder", Placeholder);
        app.component(
            "prep-assignment-assigned-collection",
            PrepAssignmentAssignedCollection
        );
        app.component(
            "PrepAssignmentAssignedCollection",
            PrepAssignmentAssignedCollection
        );
        app.component(
            "prep-assignment-assigned-collection-resource",
            PrepAssignmentAssignedCollectionResource
        );
        app.component(
            "PrepAssignmentAssignedCollectionResource",
            PrepAssignmentAssignedCollectionResource
        );
        app.component(
            "prep-assignment-assignee-modal",
            PrepAssignmentAssigneeModal
        );
        app.component(
            "PrepAssignmentAssigneeModal",
            PrepAssignmentAssigneeModal
        );
        app.component("prep-assignment-builder", PrepAssignmentBuilder);
        app.component("PrepAssignmentBuilder", PrepAssignmentBuilder);
        app.component(
            "prep-assignments-created-collection",
            PrepAssignmentsCreatedCollection
        );
        app.component(
            "PrepAssignmentsCreatedCollection",
            PrepAssignmentsCreatedCollection
        );
        app.component(
            "prep-assignments-created-collection-resource",
            PrepAssignmentsCreatedCollectionResource
        );
        app.component(
            "PrepAssignmentsCreatedCollectionResource",
            PrepAssignmentsCreatedCollectionResource
        );
        app.component("prep-blueprint-collection", PrepBlueprintCollection);
        app.component("PrepBlueprintCollection", PrepBlueprintCollection);
        app.component(
            "prep-blueprint-collection-resource",
            PrepBlueprintCollectionResource
        );
        app.component(
            "PrepBlueprintCollectionResource",
            PrepBlueprintCollectionResource
        );
        app.component("prep-category-badge", PrepCategoryBadge);
        app.component("PrepCategoryBadge", PrepCategoryBadge);
        app.component("prep-category-circle", PrepCategoryCircle);
        app.component("PrepCategoryCircle", PrepCategoryCircle);
        app.component("prep-category-circles", PrepCategoryCircles);
        app.component("PrepCategoryCircles", PrepCategoryCircles);
        app.component("prep-category-filter", PrepCategoryFilter);
        app.component("PrepCategoryFilter", PrepCategoryFilter);
        app.component("prep-category-report", PrepCategoryReport);
        app.component("PrepCategoryReport", PrepCategoryReport);
        app.component("prep-category-report-donut", PrepCategoryReportDonut);
        app.component("PrepCategoryReportDonut", PrepCategoryReportDonut);
        app.component("prep-category-report-modal", PrepCategoryReportModal);
        app.component("PrepCategoryReportModal", PrepCategoryReportModal);
        app.component("prep-category-slider", PrepCategorySlider);
        app.component("PrepCategorySlider", PrepCategorySlider);
        app.component("prep-exam", PrepExam);
        app.component("prep-exam-builder", PrepExamBuilder);
        app.component("PrepExamBuilder", PrepExamBuilder);
        app.component("prep-exam-collection", PrepExamCollection);
        app.component("PrepExamCollection", PrepExamCollection);
        app.component("prep-exam-collection-card", PrepExamCollectionCard);
        app.component("PrepExamCollectionCard", PrepExamCollectionCard);
        app.component(
            "prep-exam-collection-list-item",
            PrepExamCollectionListItem
        );
        app.component("PrepExamCollectionListItem", PrepExamCollectionListItem);
        app.component(
            "prep-exam-collection-resource",
            PrepExamCollectionResource
        );
        app.component("PrepExamCollectionResource", PrepExamCollectionResource);
        app.component("prep-exam-flag-question", PrepExamFlagQuestion);
        app.component("PrepExamFlagQuestion", PrepExamFlagQuestion);
        app.component("prep-exam-history", PrepExamHistory);
        app.component("PrepExamHistory", PrepExamHistory);
        app.component(
            "prep-exam-library-collection",
            PrepExamLibraryCollection
        );
        app.component("PrepExamLibraryCollection", PrepExamLibraryCollection);
        app.component(
            "prep-exam-library-collection-resource",
            PrepExamLibraryCollectionResource
        );
        app.component(
            "PrepExamLibraryCollectionResource",
            PrepExamLibraryCollectionResource
        );
        app.component("prep-exam-overview", PrepExamOverview);
        app.component("PrepExamOverview", PrepExamOverview);
        app.component(
            "prep-exam-overview-container",
            PrepExamOverviewContainer
        );
        app.component("PrepExamOverviewContainer", PrepExamOverviewContainer);
        app.component(
            "prep-exam-overview-navigation",
            PrepExamOverviewNavigation
        );
        app.component("PrepExamOverviewNavigation", PrepExamOverviewNavigation);
        app.component("prep-exam-question", PrepExamQuestion);
        app.component("PrepExamQuestion", PrepExamQuestion);
        app.component("prep-exam-results", PrepExamResults);
        app.component("PrepExamResults", PrepExamResults);
        app.component("primary-layout", PrimaryLayout);
        app.component("PrimaryLayout", PrimaryLayout);
        app.component("primary-search", PrimarySearch);
        app.component("PrimarySearch", PrimarySearch);
        app.component(
            "product-enrollments-list-modal",
            ProductEnrollmentsListModal
        );
        app.component(
            "ProductEnrollmentsListModal",
            ProductEnrollmentsListModal
        );
        app.component("profile-photo", ProfilePhoto);
        app.component("ProfilePhoto", ProfilePhoto);
        app.component("progress-bar", ProgressBar);
        app.component("ProgressBar", ProgressBar);
        app.component("pulse-activity-table", PulseActivityTable);
        app.component("PulseActivityTable", PulseActivityTable);
        app.component("pulse-activity-table-access", PulseActivityTableAccess);
        app.component("PulseActivityTableAccess", PulseActivityTableAccess);
        app.component("pulse-activity-table-cell", PulseActivityTableCell);
        app.component("PulseActivityTableCell", PulseActivityTableCell);
        app.component(
            "pulse-activity-table-checkbox",
            PulseActivityTableCheckbox
        );
        app.component("PulseActivityTableCheckbox", PulseActivityTableCheckbox);
        app.component(
            "pulse-activity-table-column-header",
            PulseActivityTableColumnHeader
        );
        app.component(
            "PulseActivityTableColumnHeader",
            PulseActivityTableColumnHeader
        );
        app.component(
            "pulse-activity-table-course-filter",
            PulseActivityTableCourseFilter
        );
        app.component(
            "PulseActivityTableCourseFilter",
            PulseActivityTableCourseFilter
        );
        app.component("pulse-activity-table-filter", PulseActivityTableFilter);
        app.component("PulseActivityTableFilter", PulseActivityTableFilter);
        app.component(
            "pulse-activity-table-learner",
            PulseActivityTableLearner
        );
        app.component("PulseActivityTableLearner", PulseActivityTableLearner);
        app.component("pulse-activity-table-lesson", PulseActivityTableLesson);
        app.component("PulseActivityTableLesson", PulseActivityTableLesson);
        app.component(
            "pulse-activity-table-level-dropdown",
            PulseActivityTableLevelDropdown
        );
        app.component(
            "PulseActivityTableLevelDropdown",
            PulseActivityTableLevelDropdown
        );
        app.component("pulse-activity-table-row", PulseActivityTableRow);
        app.component("PulseActivityTableRow", PulseActivityTableRow);
        app.component("pulse-activity-table-type", PulseActivityTableType);
        app.component("PulseActivityTableType", PulseActivityTableType);
        app.component(
            "pulse-activity-table-view-toggle",
            PulseActivityTableViewToggle
        );
        app.component(
            "PulseActivityTableViewToggle",
            PulseActivityTableViewToggle
        );
        app.component("pulse-activity-table-viewed", PulseActivityTableViewed);
        app.component("PulseActivityTableViewed", PulseActivityTableViewed);
        app.component("pulse-activity-tabs", PulseActivityTabs);
        app.component("PulseActivityTabs", PulseActivityTabs);
        app.component("pulse-activity-type-filter", PulseActivityTypeFilter);
        app.component("PulseActivityTypeFilter", PulseActivityTypeFilter);
        app.component("pulse-assessment", PulseAssessment);
        app.component("pulse-assessment-attempts", PulseAssessmentAttempts);
        app.component("PulseAssessmentAttempts", PulseAssessmentAttempts);
        app.component(
            "pulse-assessment-attempts-row",
            PulseAssessmentAttemptsRow
        );
        app.component("PulseAssessmentAttemptsRow", PulseAssessmentAttemptsRow);
        app.component("pulse-assessment-instance", PulseAssessmentInstance);
        app.component("PulseAssessmentInstance", PulseAssessmentInstance);
        app.component("pulse-assessment-learners", PulseAssessmentLearners);
        app.component("PulseAssessmentLearners", PulseAssessmentLearners);
        app.component(
            "pulse-assessment-learners-row",
            PulseAssessmentLearnersRow
        );
        app.component("PulseAssessmentLearnersRow", PulseAssessmentLearnersRow);
        app.component("pulse-assessments", PulseAssessments);
        app.component("PulseAssessments", PulseAssessments);
        app.component("pulse-assessments-row", PulseAssessmentsRow);
        app.component("PulseAssessmentsRow", PulseAssessmentsRow);
        app.component("pulse-assignment-table-row", PulseAssignmentTableRow);
        app.component("PulseAssignmentTableRow", PulseAssignmentTableRow);
        app.component("pulse-assignments-table-row", PulseAssignmentsTableRow);
        app.component("PulseAssignmentsTableRow", PulseAssignmentsTableRow);
        app.component("pulse-attempt", PulseAttempt);
        app.component("PulseAttempt", PulseAttempt);
        app.component("pulse-completion-bar", PulseCompletionBar);
        app.component("PulseCompletionBar", PulseCompletionBar);
        app.component("pulse-content-count-badges", PulseContentCountBadges);
        app.component("PulseContentCountBadges", PulseContentCountBadges);
        app.component(
            "pulse-content-engagement-card",
            PulseContentEngagementCard
        );
        app.component("PulseContentEngagementCard", PulseContentEngagementCard);
        app.component(
            "pulse-content-engagement-stats",
            PulseContentEngagementStats
        );
        app.component(
            "PulseContentEngagementStats",
            PulseContentEngagementStats
        );
        app.component("pulse-context-toggle", PulseContextToggle);
        app.component("PulseContextToggle", PulseContextToggle);
        app.component(
            "pulse-context-toggle-nav-item",
            PulseContextToggleNavItem
        );
        app.component("PulseContextToggleNavItem", PulseContextToggleNavItem);
        app.component(
            "pulse-course-engagement-card",
            PulseCourseEngagementCard
        );
        app.component("PulseCourseEngagementCard", PulseCourseEngagementCard);
        app.component("pulse-exams-table-row", PulseExamsTableRow);
        app.component("PulseExamsTableRow", PulseExamsTableRow);
        app.component("pulse-explore", PulseExplore);
        app.component("PulseExplore", PulseExplore);
        app.component(
            "pulse-explore-assessment-details",
            PulseExploreAssessmentDetails
        );
        app.component(
            "PulseExploreAssessmentDetails",
            PulseExploreAssessmentDetails
        );
        app.component("pulse-explore-assessments", PulseExploreAssessments);
        app.component("PulseExploreAssessments", PulseExploreAssessments);
        app.component(
            "pulse-explore-assessments-index",
            PulseExploreAssessmentsIndex
        );
        app.component(
            "PulseExploreAssessmentsIndex",
            PulseExploreAssessmentsIndex
        );
        app.component(
            "pulse-explore-assessments-listing-group",
            PulseExploreAssessmentsListingGroup
        );
        app.component(
            "PulseExploreAssessmentsListingGroup",
            PulseExploreAssessmentsListingGroup
        );
        app.component(
            "pulse-explore-assessments-listing-instance",
            PulseExploreAssessmentsListingInstance
        );
        app.component(
            "PulseExploreAssessmentsListingInstance",
            PulseExploreAssessmentsListingInstance
        );
        app.component(
            "pulse-explore-assessments-listing-single",
            PulseExploreAssessmentsListingSingle
        );
        app.component(
            "PulseExploreAssessmentsListingSingle",
            PulseExploreAssessmentsListingSingle
        );
        app.component("pulse-explore-chart", PulseExploreChart);
        app.component("PulseExploreChart", PulseExploreChart);
        app.component(
            "pulse-explore-details-course-overview",
            PulseExploreDetailsCourseOverview
        );
        app.component(
            "PulseExploreDetailsCourseOverview",
            PulseExploreDetailsCourseOverview
        );
        app.component(
            "pulse-explore-details-course-overview-row",
            PulseExploreDetailsCourseOverviewRow
        );
        app.component(
            "PulseExploreDetailsCourseOverviewRow",
            PulseExploreDetailsCourseOverviewRow
        );
        app.component(
            "pulse-explore-details-listing-instances",
            PulseExploreDetailsListingInstances
        );
        app.component(
            "PulseExploreDetailsListingInstances",
            PulseExploreDetailsListingInstances
        );
        app.component(
            "pulse-explore-details-listing-instances-row",
            PulseExploreDetailsListingInstancesRow
        );
        app.component(
            "PulseExploreDetailsListingInstancesRow",
            PulseExploreDetailsListingInstancesRow
        );
        app.component(
            "pulse-explore-details-listing-learners",
            PulseExploreDetailsListingLearners
        );
        app.component(
            "PulseExploreDetailsListingLearners",
            PulseExploreDetailsListingLearners
        );
        app.component(
            "pulse-explore-details-listing-learners-row",
            PulseExploreDetailsListingLearnersRow
        );
        app.component(
            "PulseExploreDetailsListingLearnersRow",
            PulseExploreDetailsListingLearnersRow
        );
        app.component(
            "pulse-explore-details-listing-meta",
            PulseExploreDetailsListingMeta
        );
        app.component(
            "PulseExploreDetailsListingMeta",
            PulseExploreDetailsListingMeta
        );
        app.component(
            "pulse-explore-details-questions",
            PulseExploreDetailsQuestions
        );
        app.component(
            "PulseExploreDetailsQuestions",
            PulseExploreDetailsQuestions
        );
        app.component(
            "pulse-explore-details-questions-row",
            PulseExploreDetailsQuestionsRow
        );
        app.component(
            "PulseExploreDetailsQuestionsRow",
            PulseExploreDetailsQuestionsRow
        );
        app.component("pulse-explore-layout", PulseExploreLayout);
        app.component("PulseExploreLayout", PulseExploreLayout);
        app.component("pulse-explore-lessons", PulseExploreLessons);
        app.component("PulseExploreLessons", PulseExploreLessons);
        app.component(
            "pulse-explore-lessons-listing-group",
            PulseExploreLessonsListingGroup
        );
        app.component(
            "PulseExploreLessonsListingGroup",
            PulseExploreLessonsListingGroup
        );
        app.component(
            "pulse-explore-lessons-listing-instance",
            PulseExploreLessonsListingInstance
        );
        app.component(
            "PulseExploreLessonsListingInstance",
            PulseExploreLessonsListingInstance
        );
        app.component(
            "pulse-explore-lessons-listing-single",
            PulseExploreLessonsListingSingle
        );
        app.component(
            "PulseExploreLessonsListingSingle",
            PulseExploreLessonsListingSingle
        );
        app.component("pulse-explore-overview", PulseExploreOverview);
        app.component("PulseExploreOverview", PulseExploreOverview);
        app.component(
            "pulse-explore-overview-table",
            PulseExploreOverviewTable
        );
        app.component("PulseExploreOverviewTable", PulseExploreOverviewTable);
        app.component(
            "pulse-explore-overview-table-row",
            PulseExploreOverviewTableRow
        );
        app.component(
            "PulseExploreOverviewTableRow",
            PulseExploreOverviewTableRow
        );
        app.component("pulse-explore-prep", PulseExplorePrep);
        app.component("PulseExplorePrep", PulseExplorePrep);
        app.component(
            "pulse-explore-prep-category-chart",
            PulseExplorePrepCategoryChart
        );
        app.component(
            "PulseExplorePrepCategoryChart",
            PulseExplorePrepCategoryChart
        );
        app.component("pulse-explore-prep-row", PulseExplorePrepRow);
        app.component("PulseExplorePrepRow", PulseExplorePrepRow);
        app.component("pulse-explore-prep-table", PulseExplorePrepTable);
        app.component("PulseExplorePrepTable", PulseExplorePrepTable);
        app.component("pulse-explore-quizzes", PulseExploreQuizzes);
        app.component("PulseExploreQuizzes", PulseExploreQuizzes);
        app.component(
            "pulse-explore-quizzes-listing-group",
            PulseExploreQuizzesListingGroup
        );
        app.component(
            "PulseExploreQuizzesListingGroup",
            PulseExploreQuizzesListingGroup
        );
        app.component(
            "pulse-explore-quizzes-listing-instance",
            PulseExploreQuizzesListingInstance
        );
        app.component(
            "PulseExploreQuizzesListingInstance",
            PulseExploreQuizzesListingInstance
        );
        app.component(
            "pulse-explore-quizzes-listing-single",
            PulseExploreQuizzesListingSingle
        );
        app.component(
            "PulseExploreQuizzesListingSingle",
            PulseExploreQuizzesListingSingle
        );
        app.component("pulse-explore-report-period", PulseExploreReportPeriod);
        app.component("PulseExploreReportPeriod", PulseExploreReportPeriod);
        app.component(
            "pulse-explore-report-subject",
            PulseExploreReportSubject
        );
        app.component("PulseExploreReportSubject", PulseExploreReportSubject);
        app.component("pulse-explore-report-type", PulseExploreReportType);
        app.component("PulseExploreReportType", PulseExploreReportType);
        app.component(
            "pulse-explore-report-type-nav-child",
            PulseExploreReportTypeNavChild
        );
        app.component(
            "PulseExploreReportTypeNavChild",
            PulseExploreReportTypeNavChild
        );
        app.component(
            "pulse-explore-report-type-nav-item",
            PulseExploreReportTypeNavItem
        );
        app.component(
            "PulseExploreReportTypeNavItem",
            PulseExploreReportTypeNavItem
        );
        app.component(
            "pulse-explore-report-type-nav-parent",
            PulseExploreReportTypeNavParent
        );
        app.component(
            "PulseExploreReportTypeNavParent",
            PulseExploreReportTypeNavParent
        );
        app.component("pulse-explore-timeline", PulseExploreTimeline);
        app.component("PulseExploreTimeline", PulseExploreTimeline);
        app.component("pulse-explore-timeline-row", PulseExploreTimelineRow);
        app.component("PulseExploreTimelineRow", PulseExploreTimelineRow);
        app.component(
            "pulse-explore-timeline-table",
            PulseExploreTimelineTable
        );
        app.component("PulseExploreTimelineTable", PulseExploreTimelineTable);
        app.component("pulse-group-search", PulseGroupSearch);
        app.component("PulseGroupSearch", PulseGroupSearch);
        app.component("pulse-layout", PulseLayout);
        app.component("PulseLayout", PulseLayout);
        app.component("pulse-learn", PulseLearn);
        app.component("PulseLearn", PulseLearn);
        app.component("pulse-learn-completions-row", PulseLearnCompletionsRow);
        app.component("PulseLearnCompletionsRow", PulseLearnCompletionsRow);
        app.component(
            "pulse-learn-completions-row-table",
            PulseLearnCompletionsRowTable
        );
        app.component(
            "PulseLearnCompletionsRowTable",
            PulseLearnCompletionsRowTable
        );
        app.component("pulse-learn-course", PulseLearnCourse);
        app.component("PulseLearnCourse", PulseLearnCourse);
        app.component("pulse-learn-courses", PulseLearnCourses);
        app.component("PulseLearnCourses", PulseLearnCourses);
        app.component(
            "pulse-learn-courses-activity",
            PulseLearnCoursesActivity
        );
        app.component("PulseLearnCoursesActivity", PulseLearnCoursesActivity);
        app.component(
            "pulse-learn-courses-learners-row",
            PulseLearnCoursesLearnersRow
        );
        app.component(
            "PulseLearnCoursesLearnersRow",
            PulseLearnCoursesLearnersRow
        );
        app.component(
            "pulse-learn-courses-overview",
            PulseLearnCoursesOverview
        );
        app.component("PulseLearnCoursesOverview", PulseLearnCoursesOverview);
        app.component(
            "pulse-learn-courses-structured-row",
            PulseLearnCoursesStructuredRow
        );
        app.component(
            "PulseLearnCoursesStructuredRow",
            PulseLearnCoursesStructuredRow
        );
        app.component("pulse-learn-course-activity", PulseLearnCourseActivity);
        app.component("PulseLearnCourseActivity", PulseLearnCourseActivity);
        app.component(
            "pulse-learn-course-learners-row",
            PulseLearnCourseLearnersRow
        );
        app.component(
            "PulseLearnCourseLearnersRow",
            PulseLearnCourseLearnersRow
        );
        app.component("pulse-learn-course-overview", PulseLearnCourseOverview);
        app.component("PulseLearnCourseOverview", PulseLearnCourseOverview);
        app.component(
            "pulse-learn-course-structured-row",
            PulseLearnCourseStructuredRow
        );
        app.component(
            "PulseLearnCourseStructuredRow",
            PulseLearnCourseStructuredRow
        );
        app.component("pulse-learn-module", PulseLearnModule);
        app.component("PulseLearnModule", PulseLearnModule);
        app.component("pulse-learn-module-activity", PulseLearnModuleActivity);
        app.component("PulseLearnModuleActivity", PulseLearnModuleActivity);
        app.component(
            "pulse-learn-module-learners-row",
            PulseLearnModuleLearnersRow
        );
        app.component(
            "PulseLearnModuleLearnersRow",
            PulseLearnModuleLearnersRow
        );
        app.component(
            "pulse-learn-module-structured-row",
            PulseLearnModuleStructuredRow
        );
        app.component(
            "PulseLearnModuleStructuredRow",
            PulseLearnModuleStructuredRow
        );
        app.component("pulse-learn-overview", PulseLearnOverview);
        app.component("PulseLearnOverview", PulseLearnOverview);
        app.component(
            "pulse-learn-overview-pagination",
            PulseLearnOverviewPagination
        );
        app.component(
            "PulseLearnOverviewPagination",
            PulseLearnOverviewPagination
        );
        app.component("pulse-learn-overview-sort", PulseLearnOverviewSort);
        app.component("PulseLearnOverviewSort", PulseLearnOverviewSort);
        app.component("pulse-learn-tabs", PulseLearnTabs);
        app.component("PulseLearnTabs", PulseLearnTabs);
        app.component("pulse-learner-search", PulseLearnerSearch);
        app.component("PulseLearnerSearch", PulseLearnerSearch);
        app.component("pulse-lesson", PulseLesson);
        app.component("PulseLesson", PulseLesson);
        app.component("pulse-lesson-learner", PulseLessonLearner);
        app.component("PulseLessonLearner", PulseLessonLearner);
        app.component("pulse-lesson-learner-row", PulseLessonLearnerRow);
        app.component("PulseLessonLearnerRow", PulseLessonLearnerRow);
        app.component("pulse-lesson-learners", PulseLessonLearners);
        app.component("PulseLessonLearners", PulseLessonLearners);
        app.component("pulse-lesson-learners-row", PulseLessonLearnersRow);
        app.component("PulseLessonLearnersRow", PulseLessonLearnersRow);
        app.component("pulse-lookup", PulseLookup);
        app.component("PulseLookup", PulseLookup);
        app.component(
            "pulse-module-engagement-card",
            PulseModuleEngagementCard
        );
        app.component("PulseModuleEngagementCard", PulseModuleEngagementCard);
        app.component("pulse-pagination", PulsePagination);
        app.component("PulsePagination", PulsePagination);
        app.component(
            "pulse-prep-assignment-activity",
            PulsePrepAssignmentActivity
        );
        app.component(
            "PulsePrepAssignmentActivity",
            PulsePrepAssignmentActivity
        );
        app.component(
            "pulse-prep-assignment-report",
            PulsePrepAssignmentReport
        );
        app.component("PulsePrepAssignmentReport", PulsePrepAssignmentReport);
        app.component("pulse-prep-assignment-table", PulsePrepAssignmentTable);
        app.component("PulsePrepAssignmentTable", PulsePrepAssignmentTable);
        app.component(
            "pulse-prep-assignments-table",
            PulsePrepAssignmentsTable
        );
        app.component("PulsePrepAssignmentsTable", PulsePrepAssignmentsTable);
        app.component("pulse-prep-category-circle", PulsePrepCategoryCircle);
        app.component("PulsePrepCategoryCircle", PulsePrepCategoryCircle);
        app.component("pulse-prep-exam-activity", PulsePrepExamActivity);
        app.component("PulsePrepExamActivity", PulsePrepExamActivity);
        app.component("pulse-prep-exam-assignment", PulsePrepExamAssignment);
        app.component("PulsePrepExamAssignment", PulsePrepExamAssignment);
        app.component("pulse-prep-exam-categories", PulsePrepExamCategories);
        app.component("PulsePrepExamCategories", PulsePrepExamCategories);
        app.component("pulse-prep-exam-report", PulsePrepExamReport);
        app.component("PulsePrepExamReport", PulsePrepExamReport);
        app.component("pulse-prep-exams-table", PulsePrepExamsTable);
        app.component("PulsePrepExamsTable", PulsePrepExamsTable);
        app.component("pulse-prep-report", PulsePrepReport);
        app.component("PulsePrepReport", PulsePrepReport);
        app.component("pulse-prep-row", PulsePrepRow);
        app.component("PulsePrepRow", PulsePrepRow);
        app.component("pulse-prep-score-data", PulsePrepScoreData);
        app.component("PulsePrepScoreData", PulsePrepScoreData);
        app.component("pulse-prep-tabs", PulsePrepTabs);
        app.component("PulsePrepTabs", PulsePrepTabs);
        app.component("pulse-report-period", PulseReportPeriod);
        app.component("PulseReportPeriod", PulseReportPeriod);
        app.component("pulse-subject-header", PulseSubjectHeader);
        app.component("PulseSubjectHeader", PulseSubjectHeader);
        app.component("pulse-sync", PulseSync);
        app.component("PulseSync", PulseSync);
        app.component("pulse-toggle", PulseToggle);
        app.component("PulseToggle", PulseToggle);
        app.component("quill-renderer", QuillRenderer);
        app.component("QuillRenderer", QuillRenderer);
        app.component("reference-list", ReferenceList);
        app.component("ReferenceList", ReferenceList);
        app.component("registration-codes", RegistrationCodes);
        app.component("RegistrationCodes", RegistrationCodes);
        app.component("reporting-route-mixin", ReportingRouteMixin);
        app.component("ReportingRouteMixin", ReportingRouteMixin);
        app.component("reporting-tab", ReportingTab);
        app.component("ReportingTab", ReportingTab);
        app.component("reporting-tabs", ReportingTabs);
        app.component("ReportingTabs", ReportingTabs);
        app.component("revoke-data-access", RevokeDataAccess);
        app.component("RevokeDataAccess", RevokeDataAccess);
        app.component("saved-folders-list", SavedFoldersList);
        app.component("SavedFoldersList", SavedFoldersList);
        app.component("saved-items", SavedItems);
        app.component("SavedItems", SavedItems);
        app.component("saved-items-folder-modal", SavedItemsFolderModal);
        app.component("SavedItemsFolderModal", SavedItemsFolderModal);
        app.component("saved-items-list", SavedItemsList);
        app.component("SavedItemsList", SavedItemsList);
        app.component("score-data", ScoreData);
        app.component("ScoreData", ScoreData);
        app.component("search-announcement", SearchAnnouncement);
        app.component("SearchAnnouncement", SearchAnnouncement);
        app.component("search-button", SearchButton);
        app.component("SearchButton", SearchButton);
        app.component("search-dialog", SearchDialog);
        app.component("SearchDialog", SearchDialog);
        app.component("search-input", SearchInput);
        app.component("SearchInput", SearchInput);
        app.component("search-input-box", SearchInputBox);
        app.component("SearchInputBox", SearchInputBox);
        app.component("search-list", SearchList);
        app.component("SearchList", SearchList);
        app.component("search-list-item", SearchListItem);
        app.component("SearchListItem", SearchListItem);
        app.component("search-reveal", SearchReveal);
        app.component("SearchReveal", SearchReveal);
        app.component("searchable-select-dropdown", SearchableSelectDropdown);
        app.component("SearchableSelectDropdown", SearchableSelectDropdown);
        app.component(
            "searchable-select-dropdown-item",
            SearchableSelectDropdownItem
        );
        app.component(
            "SearchableSelectDropdownItem",
            SearchableSelectDropdownItem
        );
        app.component("select-all", SelectAll);
        app.component("SelectAll", SelectAll);
        app.component("select-dropdown", SelectDropdown);
        app.component("SelectDropdown", SelectDropdown);
        app.component("select-list", SelectList);
        app.component("SelectList", SelectList);
        app.component("sidebar-admin", SidebarAdmin);
        app.component("SidebarAdmin", SidebarAdmin);
        app.component("sidebar-base", SidebarBase);
        app.component("SidebarBase", SidebarBase);
        app.component("sidebar-item", SidebarItem);
        app.component("SidebarItem", SidebarItem);
        app.component("sidebar-main-new", SidebarMainNew);
        app.component("SidebarMainNew", SidebarMainNew);
        app.component("sidebar-nav-child", SidebarNavChild);
        app.component("SidebarNavChild", SidebarNavChild);
        app.component("sidebar-nav-item", SidebarNavItem);
        app.component("SidebarNavItem", SidebarNavItem);
        app.component("sidebar-nav-parent", SidebarNavParent);
        app.component("SidebarNavParent", SidebarNavParent);
        app.component("sidebar-nav-router-child", SidebarNavRouterChild);
        app.component("SidebarNavRouterChild", SidebarNavRouterChild);
        app.component("sidebar-user", SidebarUser);
        app.component("SidebarUser", SidebarUser);
        app.component("single-course-report-view", SingleCourseReportView);
        app.component("SingleCourseReportView", SingleCourseReportView);
        app.component("site-ribbon", SiteRibbon);
        app.component("SiteRibbon", SiteRibbon);
        app.component("skeleton", Skeleton);
        app.component("Skeleton", Skeleton);
        // app.component('skip-to-content', SkipToContent);
        app.component("slideover-panel", SlideoverPanel);
        app.component("SlideoverPanel", SlideoverPanel);
        app.component("sort-button", SortButton);
        app.component("SortButton", SortButton);
        app.component("sortable-link", SortableLink);
        app.component("SortableLink", SortableLink);
        app.component("structured-assessment-rows", StructuredAssessmentRows);
        app.component("StructuredAssessmentRows", StructuredAssessmentRows);
        app.component("structured-lesson-rows", StructuredLessonRows);
        app.component("StructuredLessonRows", StructuredLessonRows);
        app.component(
            "structured-module-assessment-rows",
            StructuredModuleAssessmentRows
        );
        app.component(
            "StructuredModuleAssessmentRows",
            StructuredModuleAssessmentRows
        );
        app.component(
            "structured-module-lesson-rows",
            StructuredModuleLessonRows
        );
        app.component("StructuredModuleLessonRows", StructuredModuleLessonRows);
        app.component("structured-module-quiz-rows", StructuredModuleQuizRows);
        app.component("StructuredModuleQuizRows", StructuredModuleQuizRows);
        app.component("structured-quiz-rows", StructuredQuizRows);
        app.component("StructuredQuizRows", StructuredQuizRows);
        app.component("structured-view-courses", StructuredViewCourses);
        app.component("StructuredViewCourses", StructuredViewCourses);
        app.component("structured-view-module", StructuredViewModule);
        app.component("StructuredViewModule", StructuredViewModule);
        app.component(
            "structured-view-single-course",
            StructuredViewSingleCourse
        );
        app.component("StructuredViewSingleCourse", StructuredViewSingleCourse);
        app.component("svg-icon", SvgIcon);
        app.component("SvgIcon", SvgIcon);
        app.component("table-heading", TableHeading);
        app.component("TableHeading", TableHeading);
        app.component("tabs", Tabs);
        app.component("Tabs", Tabs);
        app.component("timezone-select", TimezoneSelect);
        app.component("TimezoneSelect", TimezoneSelect);
        app.component("title-bar", TitleBar);
        app.component("TitleBar", TitleBar);
        app.component("title-bar-nav-child", TitleBarNavChild);
        app.component("TitleBarNavChild", TitleBarNavChild);
        app.component("title-bar-nav-item", TitleBarNavItem);
        app.component("TitleBarNavItem", TitleBarNavItem);
        app.component("title-bar-router", TitleBarRouter);
        app.component("TitleBarRouter", TitleBarRouter);
        app.component("toast", Toast);
        app.component("Toast", Toast);
        app.component("toast-notification", ToastNotification);
        app.component("ToastNotification", ToastNotification);
        app.component("toaster", Toaster);
        app.component("Toaster", Toaster);
        app.component("toggle-switch", ToggleSwitch);
        app.component("ToggleSwitch", ToggleSwitch);
        app.component("toggle-visibility", ToggleVisibility);
        app.component("ToggleVisibility", ToggleVisibility);
        app.component("update-item", UpdateItem);
        app.component("UpdateItem", UpdateItem);
        app.component("video-progress", VideoProgress);
        app.component("VideoProgress", VideoProgress);
        app.component("videos-widget", VideosWidget);
        app.component("VideosWidget", VideosWidget);
        app.component("wave-container", WaveContainer);
        app.component("WaveContainer", WaveContainer);
        app.component("wistia-previewer", WistiaPreviewer);
        app.component("WistiaPreviewer", WistiaPreviewer);
        app.component("wistia-simple", WistiaSimple);
        app.component("WistiaSimple", WistiaSimple);
        app.component("wistia-video", WistiaVideo);
        app.component("lesson-page-description", LessonPageDescription);
        app.component("info-alert", InfoAlert);
        app.component("simple-layout", SimpleLayout);
        app.component("course-assessment-lock", CourseAssessmentLock);
        app.component("register-page", RegisterPage);
        app.component("login-page", LoginPage);
        app.component("new-alert", NewAlert);
        app.component("ce-pill", CePill);
        app.component("Bookmark", Bookmark);
        app.component("Description", Description);
        app.component("bookmarks-page", BookmarksPage);
        app.component("courses-list", CoursesList);
    },
};
