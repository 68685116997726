<script lang="ts" setup>
import { storeToRefs } from "pinia";
import CourseCard from "./CourseCard.vue";
import { useCoursesStore } from "@/stores/coursesStore";
import CourseListFilters from "./CourseListFilters.vue";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/24/outline";

const courseStore = useCoursesStore();
const { filteredCourses, courseEnrollments, isFetching, isLoading } =
    storeToRefs(courseStore);
</script>
<template>
    <div class="w-full flex flex-col gap-14 pt-2 lg:flex-row">
        <div class="w-full lg:hidden">
            <Disclosure v-slot="{ open }" :as="'div'" class="group lg:hidden">
                <DisclosureButton
                    class="flex w-full items-start justify-between bg-slate-900 px-5 py-4 text-left text-white"
                    :class="{
                        'rounded-t-md border-b-2 border-slate-800': open,
                        'rounded-md': !open,
                    }"
                >
                    <span class="font-bold">Filter Courses</span>
                    <ChevronDownIcon
                        class="h-6 w-6 text-white transition-all"
                        :class="open && 'rotate-180'"
                    ></ChevronDownIcon>
                </DisclosureButton>
                <DisclosurePanel class="rounded-b-md bg-slate-900">
                    <CourseListFilters />
                </DisclosurePanel>
            </Disclosure>
        </div>
        <div class="flex-1">
            <div class="mb-4 flex w-full justify-between gap-2">
                <div
                    class="w-full flex flex-col gap-4 md:flex-row md:items-center justify-between"
                >
                    <h3
                        id="slider-heading"
                        class="text-xl font-medium leading-6 text-gray-900 sm:mr-10"
                    >
                        All Courses
                    </h3>
                    <p class="whitespace-nowrap">
                        {{ filteredCourses.length }} Courses
                    </p>
                </div>
                <div class="flex items-center gap-2"></div>
            </div>
            <div class="grid-cols-250 grid gap-6">
                <div
                    v-for="courseEnrollment in filteredCourses"
                    :key="courseEnrollment.course.id"
                >
                    <CourseCard
                        :course="courseEnrollment.course"
                        :status="courseEnrollment?.enrollment?.status ?? 'new'"
                    >
                    </CourseCard>
                </div>
            </div>
        </div>
        <div class="w-full lg:max-w-[275px] hidden lg:block">
            <CourseListFilters />
        </div>
    </div>
</template>
