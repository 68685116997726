<script setup lang="ts">
import { defineProps, defineEmits } from "vue";
import { TaxonomyItem } from "@/types/content";
import { CheckIcon } from "@heroicons/vue/20/solid";

const props = defineProps<{
    taxonomyList: TaxonomyItem[];
    modelValue?: string[];
}>();

const emits = defineEmits(["update:modelValue"]);

const updateSelected = (slug: string) => {
    const selected = props.modelValue || [];
    const index = selected.indexOf(slug);
    if (index >= 0) {
        selected.splice(index, 1);
    } else {
        selected.push(slug);
    }
    emits("update:modelValue", selected);
};
</script>
<template>
    <div class="grid grid-cols-5 gap-4">
        <div
            v-for="item in taxonomyList"
            :key="item.slug"
            class="border border-gray-300 rounded-md p-2 cursor-pointer transition-all duration-200 ease-in-out transform hover:scale-105 hover:shadow-lg text-sm"
            :class="{
                'bg-blue-200 text-blue-800':
                    props.modelValue && props.modelValue.includes(item.slug),
                'hover:bg-gray-300': !(
                    props.modelValue && props.modelValue.includes(item.slug)
                ),
            }"
            @click="updateSelected(item.slug)"
        >
            <CheckIcon
                v-if="props.modelValue && props.modelValue.includes(item.slug)"
                class="h-4 w-4 inline-block mr-2"
            />
            {{ item.name }}
        </div>
    </div>
</template>
