<template>
    <div class="flex flex-col" v-if="course">
        <div class="flex items-center justify-between">
            <div>
                <div
                    class="flex items-baseline justify-between border-b p-6 h-[5.5rem] space-x-2"
                >
                    <h1 class="text-2xl font-semibold leading-none">
                        {{ course.title }}
                    </h1>
                    <h1 class="text-md font-semibold leading-none">
                        {{ course.slug }}
                    </h1>
                </div>
            </div>
            <Button @click="saveCourse">Save</Button>
        </div>
        <div class="w-full px-2 my-2 sm:px-0">
            <TabGroup>
                <TabList class="flex space-x-1 rounded bg-slate-900/80 p-1">
                    <Tab
                        v-for="tab in tabs"
                        as="template"
                        :key="tab"
                        v-slot="{ selected }"
                    >
                        <button
                            :class="[
                                'w-full rounded py-1 text-sm font-medium leading-5',
                                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                                selected
                                    ? 'bg-white shadow text-blue-700'
                                    : 'text-blue-100 hover:bg-white/[0.12] hover:text-white',
                            ]"
                        >
                            {{ tab }}
                        </button>
                    </Tab>
                </TabList>

                <TabPanels class="mt-2">
                    <TabPanel key="details" :unmount="false">
                        <div class="bg-white shadow rounded relative z-0">
                            <div class="flex flex-col p-6 space-y-6">
                                <!-- Title -->
                                <div>
                                    <label
                                        for="title"
                                        class="block text-sm font-medium text-gray-700"
                                    >
                                        Title
                                    </label>
                                    <input
                                        type="text"
                                        v-model="course.title"
                                        class="w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>
                                <!-- Slug -->
                                <div>
                                    <label
                                        for="slug"
                                        class="block text-sm font-medium text-gray-700"
                                    >
                                        Slug
                                    </label>
                                    <input
                                        type="text"
                                        v-model="course.slug"
                                        class="w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>
                                <!-- Description -->
                                <div>
                                    <label
                                        for="description"
                                        class="block text-sm font-medium text-gray-700"
                                    >
                                        Description
                                    </label>
                                    <CourseContentEditor
                                        v-model="course.description"
                                    />
                                </div>
                                <!-- Objectives -->
                                <div>
                                    <label
                                        for="objectives"
                                        class="block text-sm font-medium text-gray-700"
                                    >
                                        Objectives
                                    </label>
                                    <CourseContentEditor
                                        v-model="course.objectives"
                                    />
                                </div>
                                <div>
                                    <label
                                        for="target_audience"
                                        class="block text-sm font-medium text-gray-700"
                                    >
                                        Target Audience
                                    </label>
                                    <CourseContentEditor
                                        v-model="course.target_audience"
                                    />
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel key="CE" :unmount="false">
                        <div class="bg-white shadow rounded relative z-0">
                            <div class="flex flex-col p-6 space-y-6">
                                <!-- Credit Hours -->
                                <div>
                                    <label
                                        for="ce_credit_hours"
                                        class="block text-sm font-medium text-gray-700"
                                    >
                                        Credit Hours
                                    </label>
                                    <input
                                        type="number"
                                        v-model="course.ce_credit_hours"
                                        name="ce_credit_hours"
                                        class="w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>
                                <div>
                                    <label
                                        for="target_audience"
                                        class="block text-sm font-medium text-gray-700"
                                    >
                                        CE Info
                                    </label>
                                    <CourseContentEditor
                                        v-model="course.ce_info"
                                    />
                                </div>
                                <div>
                                    <label
                                        for="target_audience"
                                        class="block text-sm font-medium text-gray-700"
                                    >
                                        Structured Ed Info
                                    </label>
                                    <CourseContentEditor
                                        v-model="course.structured_ed_info"
                                    />
                                </div>
                                <div>
                                    <label
                                        for="target_audience"
                                        class="block text-sm font-medium text-gray-700"
                                    >
                                        CQR Info
                                    </label>
                                    <CourseContentEditor
                                        v-model="course.cqr_info"
                                    />
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel key="taxonomy">
                        <div class="space-y-8 p-4 bg-white rounded">
                            <div>
                                <label
                                    for="ce_credit_hours"
                                    class="block text-sm font-medium text-gray-700"
                                >
                                    Category
                                </label>
                                <Combobox
                                    :options="
                                        categories.map((d) => ({
                                            value: d.slug,
                                            label: d.name,
                                        }))
                                    "
                                    class="w-1/4"
                                    v-model="course.category_slug"
                                />
                            </div>
                            <div>
                                <h3 class="text-lg">CE Disciplines</h3>
                                <TaxonomySelector
                                    v-model="course.ce_disciplines"
                                    :taxonomy-list="disciplines"
                                />
                            </div>
                            <div>
                                <h3 class="text-lg">
                                    Structured Ed Disciplines
                                </h3>
                                <TaxonomySelector
                                    v-model="course.structured_ed_disciplines"
                                    :taxonomy-list="disciplines"
                                />
                            </div>
                            <div>
                                <h3 class="text-lg">CQR Disciplines</h3>
                                <TaxonomySelector
                                    v-model="course.cqr_disciplines"
                                    :taxonomy-list="disciplines"
                                />
                            </div>
                        </div>
                    </TabPanel>
                </TabPanels>
            </TabGroup>
        </div>
    </div>
</template>
<script setup lang="ts">
import { onMounted, ref } from "vue";
import axios from "axios";
import { Course } from "@/types";
import Button from "@/components/ui/ButtonLinks/Button.vue";
import CourseContentEditor from "@/components/_elements/CourseContentEditor.vue";
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";
import TaxonomySelector from "@/components/Admin/Content/taxonomy/TaxonomySelector.vue";
import Combobox from "@/components/ui/forms/Combobox.vue";
import { useCategories, useDisciplines } from "@/hooks/data";
import { toast } from "@/components/utils/toast";

const course = ref<Partial<Course> | null>(null);
const disciplines = useDisciplines();
const categories = useCategories();
const props = defineProps({
    uuid: String,
});

const tabs = ref(["Details", "CE", "Taxonomy"]);

function getCourse(uuid) {
    axios.get(`/api/admin/content/learn/courses/${uuid}`).then((response) => {
        course.value = response.data;
    });
}

function updateCourse() {
    axios
        .put(`/api/admin/content/learn/courses/${props.uuid}`, course.value)
        .then((response) => {
            course.value = response.data;
        });
}

onMounted(async () => {
    getCourse(props.uuid);
});

const saveCourse = async () => {
    await updateCourse();
    toast({ status: "success", heading: "Course Saved" });
};
</script>
