<template>
    <div>
        <SidebarBase :theme="theme">
            <template #primary>
                <SidebarNavItem
                    label="Home"
                    :naked="true"
                    path="/"
                    icon="home"
                />
                <SidebarNavParent
                    label="Learn"
                    colors="bg-gradient-to-tr from-blue-500 to-blue-400"
                    icon="booklight"
                    path="/learn"
                    noChildren="true"
                >
                </SidebarNavParent>
                <SidebarNavParent
                    v-if="user.permissions.prep"
                    label="Prep"
                    colors="bg-gradient-to-tr from-blue-500 to-blue-400"
                    icon="summit"
                    path="/prep"
                >
                    <template
                        v-if="
                            !user.permissions.prep_exam_assign ||
                            user.learnerMode
                        "
                    >
                        <SidebarNavChild
                            label="Exams"
                            icon="exam"
                            path="/prep/exams"
                        />
                        <SidebarNavChild
                            label="History"
                            icon="data"
                            path="/prep/history"
                        />
                    </template>
                    <template v-else>
                        <SidebarNavChild
                            label="Assignments"
                            icon="checklist"
                            path="/prep/assignments"
                        />
                        <SidebarNavChild
                            label="Exam Library"
                            icon="bookshelf"
                            path="/prep/exam-library"
                        />
                    </template>
                </SidebarNavParent>
                <SidebarNavParent
                    v-if="user.permissions.pulse"
                    label="Reporting"
                    colors="bg-gradient-to-tr from-blue-500 to-blue-400"
                    icon="pulse"
                    path="/pulse"
                >
                    <SidebarNavChild
                        v-if="user.permissions.pulse_explore"
                        label="Pulse Explore"
                        icon="map"
                        path="/pulse/explore"
                    />
                    <SidebarNavChild
                        v-if="isManager"
                        label="Learn Reporting (Beta)"
                        icon="booklight"
                        path="/reporting/courses"
                    />
                </SidebarNavParent>
                <SidebarNavParent
                    v-if="false"
                    label="Bookmarks"
                    colors="bg-gradient-to-tr from-blue-500 to-blue-400"
                    icon="bookmark"
                    path="/learn/bookmarks"
                    noChildren="true"
                ></SidebarNavParent>
            </template>
            <template #secondary>
                <SidebarNavItem
                    v-if="user.permissions.admin"
                    label="Admin"
                    :naked="true"
                    icon="crown"
                    path="/admin"
                />
            </template>
        </SidebarBase>
    </div>
</template>

<script lang="ts">
import { mapState } from "vuex";

export default {
    props: {
        theme: String,
    },
    computed: {
        ...mapState(["user"]),
        isManager() {
            const roles = [
                "admin",
                "admin-view-client-reports",
                "panorama-admin",
                "partner-owner",
                "partner-admin",
                "group-owner",
                "group-admin",
            ];
            // if user.role is in roles
            return roles.includes(this.user.role);
        },
    },
};
</script>
