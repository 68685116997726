import { CourseEnrollment } from "@/types";
import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { useQuery } from "@tanstack/vue-query";
import { getCategories, getCourses, getDisciplines } from "@/api/courses";

export const useCoursesStore = defineStore("courses", () => {
    const completedFilter = ref(false);
    const startedFilter = ref(false);
    const notStartedFilter = ref(false);

    const {
        isLoading,
        isFetching,
        data: courseEnrollments,
    } = useQuery({
        queryKey: ["course-enrollments"],
        queryFn: async () => {
            return await getCourses();
        },
    });

    const {
        isLoading: isLoadingCategories,
        isFetching: isFetchingCategories,
        data: categories,
    } = useQuery({
        queryKey: ["course-categories"],
        queryFn: async () => {
            return await getCategories();
        },
    });

    const {
        isLoading: isLoadingDisciplines,
        isFetching: isFetchingDisciplines,
        data: disciplines,
    } = useQuery({
        queryKey: ["course-disciplines"],
        queryFn: async () => {
            return await getDisciplines();
        },
    });

    const categoryFilters = ref<string[]>([]);
    const ceFilters = ref<string[]>([]);
    const structuredEdFilters = ref<string[]>([]);
    const cqrFilters = ref<string[]>([]);

    const filteredCourses = computed(() => {
        let list = [...(courseEnrollments.value ?? [])];
        if (completedFilter.value) {
            list = list.filter((ce: CourseEnrollment) => {
                return ce.enrollment?.status === "completed";
            });
        }
        if (startedFilter.value) {
            list = list.filter((ce: CourseEnrollment) => {
                return ce.enrollment?.status !== "new";
            });
        }
        if (notStartedFilter.value) {
            list = list.filter((ce: CourseEnrollment) => {
                return ce.enrollment?.status === "new";
            });
        }

        // filter by category
        if (categoryFilters.value.length > 0) {
            list = list.filter((ce: CourseEnrollment) => {
                return categoryFilters.value.includes(
                    ce.course?.category_slug.toLowerCase()
                );
            });
        }
        // filter by ce
        if (ceFilters.value.length > 0) {
            // ce.course?.ce_disciplines is a list of strings
            list = list.filter((ce: CourseEnrollment) => {
                return ce.course?.ce_disciplines?.some((discipline: string) => {
                    return ceFilters.value.includes(discipline);
                });
            });
        }

        // filter by structured ed
        if (structuredEdFilters.value.length > 0) {
            // ce.course?.structured_ed_disciplines is a list of strings
            list = list.filter((ce: CourseEnrollment) => {
                return ce.course?.structured_ed_disciplines?.some(
                    (discipline: string) => {
                        return structuredEdFilters.value.includes(discipline);
                    }
                );
            });
        }

        // filter by cqr
        if (cqrFilters.value.length > 0) {
            // ce.course?.cqr_disciplines is a list of strings
            list = list.filter((ce: CourseEnrollment) => {
                return ce.course?.cqr_disciplines?.some(
                    (discipline: string) => {
                        return cqrFilters.value.includes(discipline);
                    }
                );
            });
        }

        return list;
    });

    const isLoadingAll = computed(() => {
        return (
            isLoading.value ||
            isLoadingCategories.value ||
            isLoadingDisciplines.value
        );
    });

    return {
        isLoading,
        isFetching,
        courseEnrollments,
        isLoadingAll,
        categories,
        disciplines,
        filteredCourses,
        ceFilters,
        structuredEdFilters,
        cqrFilters,
        categoryFilters,
        completedFilter,
        startedFilter,
        notStartedFilter,
    };
});
