import _ from "lodash";
import { createApp } from "vue";
// @ts-ignore
import VueClipboard from "vue-clipboard2";
import { plugin as VueTippy } from "vue-tippy";
import adminRouter from "./router";
import reportRouter from "./router/report-routing";
import Alpine from "alpinejs";
import axios from "axios";
import "tippy.js/dist/tippy.css"; // optional for styling

import store from "./stores/store";
import emitter from "./event-bus";
import vueMixins from "./vue-mixins";
import { filters } from "./vue-filters";
import VueClickAway from "vue3-click-away";
import VCalendarLibrary from "v-calendar";
import { createPinia } from "pinia";
import "v-calendar/dist/style.css";
import "chart.js/auto";
import VueCountdown from "@chenfengyuan/vue-countdown";
import { VueComponents } from "./vue-components";
import { VueQueryPlugin } from "@tanstack/vue-query";
const checkoutRouter = import("./router/checkout-routing");

(window as any).Alpine = Alpine;
Alpine.start();

axios.defaults.headers.common["X-CSRF-TOKEN"] =
    // @ts-ignore
    document.querySelector('meta[name="token"]').content;

let router: any;
const path = window.location.pathname.split("/")[1];
export const app = createApp({
    devtools: true,
    components: {},
});
app.component(VueCountdown.name, VueCountdown);
const pinia = createPinia();

(async () => {
    app.use(store())
        .use(pinia)
        .use(VueClickAway)
        .use(VueClipboard)
        // .use(vueFilters)
        .use(VCalendarLibrary)
        .use(VueQueryPlugin)
        .use(vueMixins)
        .use(VueTippy, {
            directive: "tippy",
            arrow: true,
            animation: "shift-toward",
            intertia: true,
            defaultProps: {
                allowHTML: true,
            },
        });

    if (path === "reporting") {
        console.log(" set reporting router");
        router = reportRouter();
    } else if (window.location.pathname?.startsWith("/account/checkout")) {
        console.log(" set checkout router");
        router = (await checkoutRouter).default();
    } else if (path === "admin-portal") {
        router = adminRouter();
    }

    if (router) {
        app.use(router);
    }

    app.config.globalProperties.$filters = filters;
    app.config.globalProperties.$events = emitter;

    app.use(VueComponents);

    app.mount("#app");
})();
