<template>
    <div class="relative" v-click-away="hide">
        <div class="relative w-full">
            <div
                class="filter-box relative bg-white flex flex-wrap items-center leading-7 min-h-[3rem] rounded-lg pl-2 pt-1 pr-2 pb-2 z-20 w-full"
                :class="theme"
                @click="show = !show"
            >
                <div
                    class="flex items-center max-w-full mt-1 ml-1"
                    :class="'bank-' + option.bank_slug"
                    :title="option.bank"
                    v-tippy
                    v-for="option in selected"
                >
                    <div
                        class="text-white px-2 rounded-l-lg text-sm leading-7 font-medium truncate"
                    >
                        <div>{{ option.category }}</div>
                    </div>
                    <button
                        class="remove-button flex items-center justify-center text-white rounded-r-lg w-7 h-7"
                        type="button"
                        @click="remove(option)"
                    >
                        <SvgIcon icon="close" size="xs" weight="bold" />
                    </button>
                </div>
                <div v-if="selected.length == 0" class="mt-1 ml-1">
                    All Categories
                </div>
            </div>
        </div>
        <fade
            classes="category-filter absolute top-0 mt-[3.5rem] left-0 z-20"
            :show="show"
        >
            <div class="bg-gray-50 flex items-start rounded-md shadow w-128">
                <div class="relative flex items-start p-6 pb-24 w-full">
                    <div class="flex flex-col w-full">
                        <div>
                            <label
                                class="block text-sm uppercase font-bold mb-1"
                                >Filter Category</label
                            >
                            <SelectDropdown
                                class="w-full"
                                :required="false"
                                v-model="pendingBankId"
                            >
                                <option value="">Choose question bank</option>
                                <option
                                    :value="key"
                                    v-for="(category, key) in categories"
                                >
                                    {{ key }}
                                </option>
                            </SelectDropdown>
                        </div>
                        <div class="mt-2" v-if="pendingBankId">
                            <SelectDropdown
                                class="w-full"
                                :required="false"
                                v-model="pendingParentId"
                            >
                                <option value="">All</option>
                                <option
                                    :value="category.uuid"
                                    v-for="category in pendingBank"
                                >
                                    {{ category.category }}
                                </option>
                            </SelectDropdown>
                        </div>
                        <div class="mt-2" v-if="pendingParentId">
                            <SelectDropdown
                                class="w-full"
                                :required="false"
                                v-model="pendingChildId1"
                            >
                                <option value="">All</option>
                                <option
                                    :value="category.uuid"
                                    v-for="category in pendingParent.children"
                                >
                                    {{ category.category }}
                                </option>
                            </SelectDropdown>
                        </div>
                        <div class="mt-2" v-if="pendingChildId1">
                            <SelectDropdown
                                class="w-full"
                                :required="false"
                                v-model="pendingChildId2"
                            >
                                <option value="">All</option>
                                <option
                                    :value="category.uuid"
                                    v-for="category in pendingChild1.children"
                                >
                                    {{ category.category }}
                                </option>
                            </SelectDropdown>
                        </div>
                        <div class="mt-2" v-if="pendingChildId2">
                            <SelectDropdown
                                class="w-full"
                                :required="false"
                                v-model="pendingChildId3"
                            >
                                <option value="">All</option>
                                <option
                                    :value="category.uuid"
                                    v-for="category in pendingChild2.children"
                                >
                                    {{ category.category }}
                                </option>
                            </SelectDropdown>
                        </div>
                        <div class="mt-2" v-if="pendingChildId3">
                            <SelectDropdown
                                class="w-full"
                                :required="false"
                                v-model="pendingChildId4"
                            >
                                <option value="">All</option>
                                <option
                                    :value="category.uuid"
                                    v-for="category in pendingChild3.children"
                                >
                                    {{ category.category }}
                                </option>
                            </SelectDropdown>
                        </div>
                        <div class="mt-2" v-if="pendingChildId4">
                            <SelectDropdown
                                class="w-full"
                                :required="false"
                                v-model="pendingChildId5"
                            >
                                <option value="">All</option>
                                <option
                                    :value="category.uuid"
                                    v-for="category in pendingChild4.children"
                                >
                                    {{ category.category }}
                                </option>
                            </SelectDropdown>
                        </div>
                        <div class="mt-2" v-if="pendingChildId5">
                            <SelectDropdown
                                class="w-full"
                                :required="false"
                                v-model="pendingChildId6"
                            >
                                <option value="">All</option>
                                <option
                                    :value="category.uuid"
                                    v-for="category in pendingChild5.children"
                                >
                                    {{ category.category }}
                                </option>
                            </SelectDropdown>
                        </div>
                        <div class="mt-2" v-if="pendingChildId6">
                            <SelectDropdown
                                class="w-full"
                                :required="false"
                                v-model="pendingChildId7"
                            >
                                <option value="">All</option>
                                <option
                                    :value="category.uuid"
                                    v-for="category in pendingChild6.children"
                                >
                                    {{ category.category }}
                                </option>
                            </SelectDropdown>
                        </div>
                    </div>
                    <div
                        class="absolute bottom-0 left-0 bg-gray-100 rounded-b-md w-full py-4 px-6"
                    >
                        <div class="flex items-center justify-between">
                            <button
                                class="flex items-center small primary button"
                                :disabled="!pendingBankId"
                                type="button"
                                @click="filter"
                            >
                                <SvgIcon
                                    class="mr-2"
                                    icon="add"
                                    size="xs"
                                    weight="bold"
                                />
                                <div>Add</div>
                            </button>
                            <button
                                type="button"
                                class="text-sm text-blue-600 underline hover:bg-white rounded-lg px-4 leading-9"
                                @click="setUncategorized"
                            >
                                Uncategorized
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </fade>
    </div>
</template>

<script lang="ts">
import axios from "axios";

export default {
    props: {
        theme: {
            type: String,
            default: "float",
        },
        modelValue: {
            type: Array,
            default: () => [],
        },
    },
    data(): any {
        return {
            category: "Choose a category",
            categories: [],
            pendingBankId: "",
            pendingParentId: "",
            pendingChildId1: "",
            pendingChildId2: "",
            pendingChildId3: "",
            pendingChildId4: "",
            pendingChildId5: "",
            pendingChildId6: "",
            pendingChildId7: "",
            selected: [],
            show: false,
        };
    },
    computed: {
        pending() {
            if (!this.pendingChildId7) {
                if (!this.pendingChildId6) {
                    if (!this.pendingChildId5) {
                        if (!this.pendingChildId4) {
                            if (!this.pendingChildId3) {
                                if (!this.pendingChildId2) {
                                    if (!this.pendingChildId1) {
                                        if (!this.pendingParentId) {
                                            return this.pendingBank;
                                        }
                                        return this.pendingParent;
                                    }
                                    return this.pendingChild1;
                                }
                                return this.pendingChild2;
                            }
                            return this.pendingChild3;
                        }
                        return this.pendingChild4;
                    }
                    return this.pendingChild5;
                }
                return this.pendingChild6;
            }
            return this.pendingChild7;
        },
        pendingBank() {
            if (this.pendingBankId) {
                return this.categories[this.pendingBankId];
            }
            return {};
        },
        pendingParent() {
            if (this.pendingParentId) {
                return this.pendingBank.filter(
                    (o) => o.uuid == this.pendingParentId
                )[0];
            }
            return {};
        },
        pendingChild1() {
            if (this.pendingChildId1) {
                return this.pendingParent.children.filter(
                    (o) => o.uuid == this.pendingChildId1
                )[0];
            }
            return {};
        },
        pendingChild2() {
            if (this.pendingChildId2) {
                return this.pendingChild1.children.filter(
                    (o) => o.uuid == this.pendingChildId2
                )[0];
            }
            return {};
        },
        pendingChild3() {
            if (this.pendingChildId3) {
                return this.pendingChild2.children.filter(
                    (o) => o.uuid == this.pendingChildId3
                )[0];
            }
            return {};
        },
        pendingChild4() {
            if (this.pendingChildId4) {
                return this.pendingChild3.children.filter(
                    (o) => o.uuid == this.pendingChildId4
                )[0];
            }
            return {};
        },
        pendingChild5() {
            if (this.pendingChildId5) {
                return this.pendingChild4.children.filter(
                    (o) => o.uuid == this.pendingChildId5
                )[0];
            }
            return {};
        },
        pendingChild6() {
            if (this.pendingChildId6) {
                return this.pendingChild5.children.filter(
                    (o) => o.uuid == this.pendingChildId6
                )[0];
            }
            return {};
        },
        pendingChild7() {
            if (this.pendingChildId7) {
                return this.pendingChild6.children.filter(
                    (o) => o.uuid == this.pendingChildId7
                )[0];
            }
            return {};
        },
    },
    methods: {
        filter() {
            if (this.pendingBankId) {
                if (!this.pendingParentId) {
                    this.pending.map((child) => {
                        this.selected.push({ ...child, children: null });
                    });
                } else {
                    this.selected.push({ ...this.pending, children: null });
                }
                this.hide();
            }
        },
        hide() {
            this.show = false;
            this.pendingBankId = "";
        },
        remove(option) {
            this.selected.splice(this.selected.indexOf(option), 1);
        },
        setUncategorized() {
            this.selected.splice(0, this.selected.length);
            this.selected.push({
                ...{
                    id: 0,
                    bank: "Uncategorized",
                    bank_slug: "uncategorized",
                    category: "Uncategorized",
                },
                children: null,
            });
        },
    },
    mounted() {
        axios.get("/api/categories").then((response) => {
            this.categories = response.data;
        });
    },
    watch: {
        pendingBankId() {
            this.pendingParentId = "";
        },
        pendingParentId() {
            this.pendingChildId1 = "";
        },
        pendingChildId1() {
            this.pendingChildId2 = "";
        },
        pendingChildId2() {
            this.pendingChildId3 = "";
        },
        pendingChildId3() {
            this.pendingChildId4 = "";
        },
        pendingChildId4() {
            this.pendingChildId5 = "";
        },
        pendingChildId5() {
            this.pendingChildId6 = "";
        },
        pendingChildId6() {
            this.pendingChildId7 = "";
        },
        selected(value) {
            this.$emit("update:modelValue", value);
        },
        modelValue(value) {
            this.selected = value;
        },
    },
};
</script>

<style scoped>
.filter-box.float {
    @apply shadow;
}

.filter-box.flat {
    @apply border;
}

.remove-button:active {
    @apply top-0 !important;
}
</style>
