<script lang="ts" setup>
import { defineProps, ref, defineEmits } from "vue";

const internalSelected = ref(false);

const props = defineProps<{
    selected: boolean;
    text: string;
    type: "radio" | "checkbox";
    readonly?: boolean;
    status: "correct" | "incorrect" | "unanswered";
    toggle: () => void;
    inputRef: any;
}>();

const emit = defineEmits<{
    (event: "click", value: string): void;
}>();
</script>
<template>
    <div>
        <label
            class="inline-flex items-start space-x-3 text-base mb-3 w-full p-3 border border-gray-300 rounded-md"
            :class="{
                'border border-gray-300': !selected,
                'border border-blue-600 bg-blue-100': selected,
                'border border-green-600 bg-green-100': status === 'correct',
                'border border-red-600 bg-red-100': status === 'incorrect',
            }"
        >
            <div class="flex items-center h-6">
                <input
                    class="flex items-center flex-shrink-0 w-5 h-5 mr-2"
                    :type="type"
                    :checked="selected"
                    @change="toggle"
                    ref="inputRef"
                />
                {{ text }}
            </div>
        </label>
    </div>
</template>
