<script setup lang="ts">
import { ref, defineProps } from "vue";
import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
} from "@headlessui/vue";

const props = defineProps<{
    startOpen?: boolean;
}>();

const emits = defineEmits<{
    (event: "onClose"): void;
}>();

const isOpen = ref(props.startOpen || false);

const closeModal = () => {
    isOpen.value = false;
    emits("onClose");
};

const openModal = () => {
    isOpen.value = true;
};

const events = {
    closeModal,
    openModal,
};
</script>

<template>
    <TransitionRoot appear :show="isOpen" as="template">
        <Dialog as="div" @close="closeModal" class="relative z-100">
            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-black bg-opacity-50" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto">
                <div
                    class="flex min-h-full items-center justify-center p-4 text-center"
                >
                    <TransitionChild
                        as="template"
                        enter="duration-300 ease-out"
                        enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95"
                    >
                        <DialogPanel
                            class="text-center prose w-full max-w-xl transform overflow-hidden rounded-md bg-white p-6 md:p-12 text-left align-middle shadow-xl transition-all"
                        >
                            <slot name="panel" :events="events"></slot>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
