<template>
    <div>
        <button class="underline" type="button" @click="showModal = true">
            Report this question
        </button>
        <FeedbackModal
            :question="question"
            :questionType="questionType"
            :active="showModal"
            @close="showModal = false"
        />
    </div>
</template>

<script lang="ts">
import axios from "axios";

export default {
    props: {
        question: String,
        questionType: String,
    },
    data(): any {
        return {
            showModal: false,
        };
    },
};
</script>
