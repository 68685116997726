<template>
    <div
        class="bg-white first:rounded-t-md last:rounded-b-md w-full mb-px last:mb-0 h-20"
    >
        <div class="flex items-center">
            <div
                class="flex items-center flex-shrink-0 border-gray-100 px-4 w-full sm:w-48 h-20"
            >
                <div class="text-xs leading-6">
                    <div v-text="$filters.dateTime(examTime, user.timezone)" />
                </div>
            </div>
            <div class="flex items-center flex-grow border-gray-100 px-4 h-20">
                <h2 class="truncate leading-6">
                    <a
                        v-if="!examInactive"
                        class="cta"
                        :href="'/prep/exams/' + exam.uuid"
                    >
                        {{ exam.name || "Unnamed" }}
                    </a>
                    <div v-else>
                        {{ exam.name || "Unnamed" }}
                    </div>
                </h2>
            </div>
            <div class="flex items-center border-gray-100 p-4 w-42 h-20">
                <PrepCategoryCircles
                    class="justify-start"
                    :categories="exam.categoryList"
                    options="stacked"
                />
            </div>
            <div
                class="flex items-center justify-center border-gray-100 text-sm p-4 w-24 h-20"
            >
                {{ exam.questions_total }}
            </div>
            <template v-if="examInactive">
                <div
                    class="flex items-center justify-center border-gray-100 text-sm font-medium leading-6 px-4 w-24 h-20"
                >
                    <div
                        v-html="
                            $filters.grade(examScore, user.settings.pulse_grade)
                        "
                    />
                </div>
                <div
                    class="flex items-center justify-center border-gray-100 text-sm px-4 w-32 h-20"
                >
                    <div v-html="$filters.elapsed(examDuration)" />
                </div>
                <div class="w-4" />
            </template>
            <template v-else>
                <div class="flex items-center border-gray-100 px-4 w-48 h-20">
                    <div class="flex-grow text-sm font-medium uppercase">
                        <ProgressBar :percent="exam.progress" size="md" />
                    </div>
                </div>
                <div class="flex items-center justify-center p-4 w-20 h-20">
                    <ActionMenu>
                        <ActionMenuAction
                            v-if="exam.status !== 'archived'"
                            type="button"
                            label="Archive"
                            icon="archive"
                            iconColor="text-blue-200"
                            :action="archive"
                        />
                        <ActionMenuAction
                            v-if="exam.status === 'archived'"
                            type="button"
                            label="Restore"
                            icon="restore"
                            iconColor="text-blue-200"
                            :action="restore"
                        />
                    </ActionMenu>
                </div>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import axios from "axios";
import { differenceInSeconds } from "date-fns";
import { mapState } from "vuex";
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        exam: {
            type: Object,
            default: () => {},
        },
    },
    data(): any {
        return {
            labels: {
                learner: "bg-blue-50 text-blue-700",
                educator: "bg-red-50 text-red-700",
                clover: "bg-pear-100 text-pear-700",
            },
        };
    },
    computed: {
        ...mapState(["user"]),
        examInactive() {
            return ["completed", "expired"].includes(this.exam.status);
        },
        examDuration() {
            return differenceInSeconds(
                new Date(this.exam.time_completed),
                new Date(this.exam.time_started)
            );
        },
        examScore() {
            return (
                (this.exam.questions_correct / this.exam.questions_total) * 100
            );
        },
        examTime() {
            return this.exam.time_completed
                ? this.exam.time_completed
                : this.exam.time_started;
        },
        examOwner() {
            return this.exam.blueprint ? this.exam.blueprint.owner : "learner";
        },
        viewable() {
            return this.exam.blueprint ? !this.exam.blueprint.assignment : true;
        },
    },
    methods: {
        archive() {
            axios
                .post("/api/prep/exams/" + this.exam.uuid + "/archive")
                .then(() => window.location.reload());
        },
        restore() {
            axios
                .post("/api/prep/exams/" + this.exam.uuid + "/restore")
                .then(() => window.location.reload());
        },
    },
});
</script>
