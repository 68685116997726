import * as Vue from "vue";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import CoursesPage from "@/components/Admin/Content/courses/CoursesPage.vue";
import LessonsPage from "@/components/Admin/Content/lessons/LessonsPage.vue";
import LessonPage from "@/components/Admin/Content/lessons/LessonPage.vue";
import CoursesTitleBar from "@/components/Admin/Content/courses/CoursesTitleBar.vue";
import LessonTitleBar from "@/components/Admin/Content/lessons/LessonTitleBar.vue";
import LessonsTitleBar from "@/components/Admin/Content/lessons/LessonsTitleBar.vue";
import CoursePage from "@/components/Admin/Content/courses/CoursePage.vue";
import EditCoursePage from "@/components/Admin/Content/courses/EditCoursePage.vue";
import EditModuleLessonOrder from "@/components/Admin/Content/modules/EditModuleLessonOrder.vue";
import AddModule from "@/components/Admin/Content/modules/AddModule.vue";
import EditCategoryPage from "@/components/Admin/Content/taxonomy/EditCategoryPage.vue";
import EditDisciplinesPage from "@/components/Admin/Content/taxonomy/EditDisciplinesPage.vue";

const routes: Readonly<RouteRecordRaw[]> = [
    // Admin Routes
    {
        path: "/content/learn/courses",
        name: "courses",
        children: [
            {
                path: "",
                components: {
                    default: CoursesPage,
                    titleBar: CoursesTitleBar,
                },
            },
            {
                path: ":uuid",
                children: [
                    {
                        path: "",
                        name: "course",
                        components: {
                            default: CoursePage,
                            titleBar: CoursesTitleBar,
                        },
                    },
                    {
                        path: "edit",
                        name: "course-edit",
                        components: {
                            default: EditCoursePage,
                            titleBar: CoursesTitleBar,
                        },
                        props: { default: true },
                    },
                    {
                        path: "add-module",
                        components: {
                            default: AddModule,
                            titleBar: CoursesTitleBar,
                        },
                    },
                ],
            },
        ],
    },
    {
        path: "/content/learn/modules/:uuid/lessons",
        name: "module_lessons",
        components: {
            default: LessonsPage,
            titleBar: LessonsTitleBar,
        },
        props: { default: true },
    },
    {
        path: "/content/learn/modules/:uuid/lesson-order",
        name: "module.edit.lesson-order",
        components: {
            default: EditModuleLessonOrder,
            titleBar: LessonsTitleBar,
        },
        props: { default: true },
    },
    {
        path: "/content/learn/lessons",
        name: "lessons",
        components: {
            default: LessonsPage,
            titleBar: LessonsTitleBar,
        },
    },
    {
        path: "/content/learn/lessons/:uuid",
        name: "lesson",
        components: {
            default: LessonPage,
            titleBar: LessonTitleBar,
        },
        props: {
            default: true,
        },
    },
    {
        path: "/content/learn/categories",
        name: "manage-categories",
        components: {
            default: EditCategoryPage,
            titleBar: LessonTitleBar,
        },
        props: {
            default: true,
        },
    },
    {
        path: "/content/learn/disciplines",
        name: "manage-disciplines",
        components: {
            default: EditDisciplinesPage,
            titleBar: LessonTitleBar,
        },
        props: {
            default: true,
        },
    },
];

export default () => {
    return createRouter({
        history: createWebHistory("admin-portal"),
        routes: routes,
    });
};
