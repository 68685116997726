<script setup lang="ts">
import { defineProps } from "vue";
import { useEditor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import BulletList from "@tiptap/extension-bullet-list";
import { CustomListItem } from "./extensions/CustomListItem";
import {watch} from "vue";

const props = defineProps<{ content: any; greenCheckList?: boolean }>();

const editor = useEditor({
  content: props.content,
  extensions: [
    StarterKit.configure({
      bulletList: false,
      listItem: false,
    }),
    Table.configure({
      HTMLAttributes: {
        class: "not-prose [&>p]:p-0 mt-0 w-full",
      },
    }),
    TableRow.configure({
      HTMLAttributes: {
        class: "even:bg-white",
      },
    }),
    TableHeader.configure({
      HTMLAttributes: {
        class:
          "bg-blue-100 p-2 md:px-4 md:py-2 text-sm md:text-base uppercase font-normal",
      },
    }),
    TableCell.configure({
      HTMLAttributes: {
        class: "p-2 md:px-4 md:py-2 text-sm md:text-base",
      },
    }),
    props.greenCheckList
      ? BulletList.configure({
          HTMLAttributes: {
            class: "not-prose list-image-checkmark space-y-2",
          },
        })
      : BulletList.configure({
          HTMLAttributes: {
            class: "not-prose space-y-2",
          },
        }),
    CustomListItem,
  ],
  editable: false,
});

watch(
  () => props.content,
  (content) => {
    editor.value?.commands.setContent(content);
  }
);
</script>
<template>
  <EditorContent :editor="editor"></EditorContent>
</template>
